import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import LoginForm from "./LoginForm";
import { IntercomProvider } from "../../intercom/intercomProvider";
import { LanguageSelector } from "../../../theme/components/LanguageSelector";
import logo from "../../../images/XFA_woordmerk_gray900.svg";
import stars from "../../../images/stars.png";
import quote1Author from "../../../images/quote1-author.png";
import quote2Author from "../../../images/quote2-author.png";
import HeroImage from "../../../images/policy_dashboard.png";
import "../AuthStateApp.css";
import { useTranslation } from "react-i18next";

interface LoginLayoutProps {
  onSignIn: (user: any) => void;
  onSwitchToSignup: () => void;
  cognitoUser: any;
  setCognitoUser: (user: any) => void;
}

const LoginLayout: React.FC<LoginLayoutProps> = ({
  onSignIn,
  onSwitchToSignup,
  cognitoUser,
  setCognitoUser,
}) => {
  const { t } = useTranslation();

  const quotes = [
    {
      quote: t("login.quote"),
      author: t("login.quoteAuthor"),
      title: t("login.quoteTitle"),
      image: quote1Author,
    },
    {
      quote: t("login.quote2"),
      author: t("login.quoteAuthor2"),
      title: t("login.quoteTitle2"),
      image: quote2Author,
    },
  ];

  const [selectedQuote] = useState(
    () => quotes[Math.floor(Math.random() * quotes.length)],
  );

  return (
    <div id="loginBackground">
      <div id="sidebar">
        <div id="sidebarContent">
          <div id="form">
            <div style={{ textAlign: "center" }}>
              <img
                alt="logo"
                src={logo}
                style={{ height: 50, marginBottom: 50 }}
              />
            </div>
            <IntercomProvider>
              <Routes>
                <Route
                  path="/"
                  element={
                    <LoginForm
                      onSignIn={onSignIn}
                      onSwitchToSignup={onSwitchToSignup}
                      cognitoUser={cognitoUser}
                      setCognitoUser={setCognitoUser}
                    />
                  }
                />
                <Route
                  path="/signin"
                  element={
                    <LoginForm
                      onSignIn={onSignIn}
                      onSwitchToSignup={onSwitchToSignup}
                      cognitoUser={cognitoUser}
                      setCognitoUser={setCognitoUser}
                    />
                  }
                />
                <Route
                  path="/signin/:username"
                  element={
                    <LoginForm
                      onSignIn={onSignIn}
                      onSwitchToSignup={onSwitchToSignup}
                      cognitoUser={cognitoUser}
                      setCognitoUser={setCognitoUser}
                    />
                  }
                />
                <Route
                  path="*"
                  element={
                    <LoginForm
                      onSignIn={onSignIn}
                      onSwitchToSignup={onSwitchToSignup}
                      cognitoUser={cognitoUser}
                      setCognitoUser={setCognitoUser}
                    />
                  }
                />
              </Routes>
            </IntercomProvider>
          </div>
        </div>
        <div id="sidebarFooter">
          <LanguageSelector
            languageNames={{ en: "English", nl: "Nederlands" }}
          />
        </div>
      </div>
      <div
        className="login-right-part"
        style={{ position: "relative", width: "100%", overflow: "hidden" }}
      >
        <div className="topPart">
          <div className="quote">{selectedQuote.quote}</div>
          <div className="quoteContact">
            <div className="flex items-center gap-4">
              <img
                className="w-12 h-12 rounded-full object-cover"
                src={selectedQuote.image}
                alt={selectedQuote.author}
              />
              <div className="quoteText">
                <div className="quotePerson">{selectedQuote.author}</div>
                <div className="quoteTitle">{selectedQuote.title}</div>
              </div>
            </div>
            <img className="quoteStars" alt="stars" src={stars} />
          </div>
        </div>
        <div className="bottomPart">
          <img
            src={HeroImage}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "left top",
            }}
            alt="Hero"
          />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              height: "20%",
              backgroundImage:
                "linear-gradient(to top, rgb(243, 244, 246, 1), rgb(243, 244, 246, 0))",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
