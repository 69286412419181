import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

export const menuStyles: SxProps<Theme> = {
  padding: 0,
  "& .MuiMenu-list": {
    padding: 0,
  },
};

export const menuItemStyles: SxProps<Theme> = {
  backgroundColor: "white",
  borderLeft: "1px solid var(--color-gray-200)",
  borderRight: "1px solid var(--color-gray-200)",
  "& .MuiListItemText-primary": {
    fontSize: "14px",
  },
  padding: "10px 16px",
};

export const topMenuItemStyles: SxProps<Theme> = {
  backgroundColor: "white",
  borderTopLeftRadius: "6px",
  borderTopRightRadius: "6px",
  borderTop: "1px solid var(--color-gray-200)",
  borderLeft: "1px solid var(--color-gray-200)",
  borderRight: "1px solid var(--color-gray-200)",
  "& .MuiListItemText-primary": {
    fontSize: "14px",
  },
  padding: "10px 16px",
};

export const deleteMenuItemStyles: SxProps<Theme> = {
  backgroundColor: "white",
  borderTop: "1px solid var(--color-gray-200)",
  borderBottomLeftRadius: "6px",
  borderBottomRightRadius: "6px",
  border: "1px solid var(--color-gray-200)",
  color: "var(--color-red-700)",
  "& .MuiListItemText-primary": {
    color: "var(--color-red-700)",
    fontSize: "14px",
  },
  padding: "10px 16px",
};

export const lastMenuItemStyles: SxProps<Theme> = {
  backgroundColor: "white",
  borderBottomLeftRadius: "6px",
  borderBottomRightRadius: "6px",
  border: "1px solid var(--color-gray-200)",
  borderTop: "0px",
  "& .MuiListItemText-primary": {
    fontSize: "14px",
  },
  padding: "10px 16px",
};

export const seperatorMenuItemStyles: SxProps<Theme> = {
  backgroundColor: "white",
  border: "1px solid var(--color-gray-200)",
  borderTop: "0px",
  "& .MuiListItemText-primary": {
    fontSize: "14px",
  },
  padding: "10px 16px",
};

export const topAndLastMenuItemStyles: SxProps<Theme> = {
  backgroundColor: "white",
  borderTopLeftRadius: "6px",
  borderTopRightRadius: "6px",
  borderBottomLeftRadius: "6px",
  borderBottomRightRadius: "6px",
  border: "1px solid var(--color-gray-200)",
  borderTop: "0px",
  "& .MuiListItemText-primary": {
    fontSize: "14px",
  },
  padding: "10px 16px",
};
