import { createTheme } from "@mui/material/styles";

import "@fontsource/manrope";
import "@fontsource/manrope/800.css";
import "@fontsource/manrope/700.css";
import "@fontsource/manrope/600.css";
import "@fontsource/manrope/500.css";
import "@fontsource/manrope/400.css";
import "@fontsource/manrope/300.css";
import "@fontsource/manrope/200.css";
import "./colors.css";
import "./custom.css";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    setting: true;
    instructions: true;
    pagetitle: true;
    formtitle: true;
    forminstructions: true;
    formlabel: true;
    formfieldplaceholder: true;
    formcheckoptionlabel: true;
    formsectiontitle: true;
    formlink: true;
    formlinkdescription: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    form: true;
    secondary: true;
  }
}

export const BaseTheme = createTheme({
  typography: {
    fontFamily: "Manrope",
    h4: {},
  },
  palette: {
    primary: {
      main: "#10131A",
    },
    error: {
      main: "#e84250",
    },
    success: {
      main: "#1ed76e",
    },
    info: {
      main: "#265fed",
    },
    background: {
      default: "#FFFFFF",
    },
  },
  components: {
    MuiAlert: {
      defaultProps: { variant: "filled" },
      styleOverrides: {
        icon: {
          marginRight: "8px",
          borderRadius: "100px",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "black",
          color: "white",
          fontFamily: "Manrope",
          borderRadius: "8px",
          fontSize: "12px",
          padding: "12px",
        },
        arrow: {
          color: "black",
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: "#E6E7EB",
          borderLeftWidth: 2,
        },
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          borderColor: "#E6E7EB",
          borderLeftWidth: "2px",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            width: "32px",
            height: "32px",
          },
        },
        text: {
          fontFamily: "Manrope, sans-serif",
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "22px",
          letterSpacing: "0em",
          textAlign: "center",
          fill: "#fff",
          marginTop: "2px",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          padding: "0px !important",
        },
        iconContainer: {
          marginLeft: "-4px !important",
        },
      },
    },
  },
});

export const DashboardTheme = createTheme(BaseTheme, {
  typography: {
    setting: {
      fontFamily: "Manrope",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "20px",
      color: "var(--color-gray-700)",
      display: "block",
    },
    instructions: {
      fontFamily: "Manrope",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      color: "var(--color-gray-500)",
      display: "block",
    },
    formtitle: {
      fontFamily: "Manrope",
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "43.71px",
      color: "var(--color-gray-900)",
      marginBottom: "6px",
    },
    forminstructions: {
      fontFamily: "Manrope",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "20px",
      color: "var(--color-gray-500)",
    },
    formlabel: {
      fontFamily: "Manrope",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      color: "var(--color-gray-700)",
      marginBottom: "4px",
    },
    formfieldplaceholder: {
      fontFamily: "Manrope",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      color: "var(--color-gray-500)",
    },
    formcheckoptionlabel: {
      fontFamily: "Manrope",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      color: "var(--color-gray-700)",
    },
    formsectiontitle: {
      fontFamily: "Manrope",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "28px",
      color: "var(--color-gray-900)",
    },
    formlink: {
      fontFamily: "Manrope",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "19.12px",
      color: "var(--color-gray-900)",
    },
    formlinkdescription: {
      fontFamily: "Manrope",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "19.12px",
      color: "var(--color-gray-500)",
    },
    pagetitle: {
      fontFamily: "Manrope",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "32px",
      color: "var(--color-gray-900)",
    },
  },
  palette: {
    background: {
      default: "var(--color-background-light)",
    },
  },
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "var(--color-gray-100)",
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: "var(--color-gray-50)",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          marginTop: "16px",
          marginBottom: "16px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          paddingTop: "11px",
          paddingBottom: "11px",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          paddingTop: "11px",
          paddingBottom: "11px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        inputMultiline: {
          paddingTop: "0px!important",
          paddingBottom: "0px!important",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          formtitle: "h1",
          forminstructions: "h2",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          sizeLarge: {
            padding: "6px 16px!important",
          },
          textTransform: "none",
        },
        contained: {
          fontFamily: "Manrope",
          fontWeight: 600,
          fontSize: "14px",
          letterSpacing: "0px",
          color: "var(--color-gray-50)",
          borderRadius: "6px",
          sizeLarge: {
            fontSize: "14px",
            lineHeight: "19.12px",
          },
          boxShadow: "none",
          "&:hover": {
            backgroundColor: "var(--color-gray-700)",
            boxShadow: "none",
          },
        },
        secondary: {
          fontFamily: "Manrope",
          fontWeight: 600,
          fontSize: "14px",
          letterSpacing: "0px",
          color: "var(--color-gray-900)",
          backgroundColor: "var(--color-gray-200)",
          borderRadius: "6px",
          "&:hover": {
            backgroundColor: "var(--color-gray-400)",
          },
          sizeLarge: {
            fontSize: "14px",
            lineHeight: "19.12px",
          },
        },
        outlined: {
          fontFamily: "Manrope",
          fontWeight: 600,
          fontSize: "14px",
          letterSpacing: "0px",
          color: "var(--color-gray-900)",
          backgroundColor: "#FFFFFF",
          borderRadius: "6px",
          border: "1px solid var(--color-border)",
          "&:hover": {
            backgroundColor: "var(--color-gray-200)",
            border: "1px solid var(--color-border)",
          },
        },
        text: {
          fontFamily: "Manrope",
          fontWeight: 700,
          color: "var(--color-gray-500)",
          padding: "0px",
          border: "0px",
          sizeLarge: {
            fontSize: "14px",
            lineHeight: "24px",
          },
          "&:hover": {
            backgroundColor: "transparent",
            color: "var(--color-gray-900)",
            textDecoration: "underline",
          },
        },
        sizeLarge: {
          height: "42px",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontFamily: "Manrope",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: 600,
          color: "var(--color-gray-700)",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: "6px",
          boxShadow: "0px 12px 16px -6px #00000014",
        },
      },
    },
    MuiAlert: {
      defaultProps: { variant: "filled" },
      styleOverrides: {
        icon: {
          marginRight: "8px",
        },
        root: {
          borderRadius: "6px",
          paddingTop: "4px",
          paddingBottom: "4px",
        },
      },
    },
  },
});

export const WebFlowTheme = createTheme(BaseTheme, {
  palette: {
    background: {
      default: "#FFFFFF",
    },
  },
});
