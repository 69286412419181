import React from "react";
import StageCard from "./StageCard";
import { Typography } from "@mui/material";
import i18next, { t } from "i18next";
import NavigationService from "../../../utils/NavigationService";
import { Counter, Device } from "../../API/XFA_API";
import "./StageCard.css";
import {
  getDeviceRisk,
  getDeviceStatus,
  getDeviceType,
  getRelativeTime,
} from "../../Users/UserUtils";
import DeviceStatusChip from "../../Users/DeviceStatusChip";
import EmailsChart from "./EmailsChart";
import EnforcementChart from "./EnforcementChart";
import { PercentageChange } from "../../General/PercentageChange";
import { FilterType } from "../../Users/UsersOverview";
import { getAggregatedData, TimePeriod } from "../../../utils/counterUtils";

interface RiskAssessmentCardListProps {
  discoveryActive: boolean;
  awarenessActive: boolean;
  enforcementActive: boolean;
  discoveryDevices: Device[] | undefined;
  counters: Counter[];
}

const StageCardList: React.FC<RiskAssessmentCardListProps> = ({
  discoveryActive,
  awarenessActive,
  enforcementActive,
  discoveryDevices,
  counters,
}) => {
  const [languageKey, setLanguageKey] = React.useState(0);
  const [mailPeriod, setMailPeriod] = React.useState<TimePeriod | undefined>(
    undefined,
  );
  const [warnBlockPeriod, setWarnBlockPeriod] = React.useState<
    TimePeriod | undefined
  >(undefined);
  const chartData = React.useMemo(
    () => getAggregatedData(counters),
    [counters],
  );
  const [mailsSent, setMailsSent] = React.useState<number[]>([]);
  const [devicesWarned, setDevicesWarned] = React.useState<number[]>([]);
  const [devicesBlocked, setDevicesBlocked] = React.useState<number[]>([]);
  const showMailPeriod =
    mailPeriod && (awarenessActive || mailsSent.some((mail) => mail > 0));
  const showWarnBlockPeriod =
    warnBlockPeriod &&
    (enforcementActive ||
      devicesBlocked.some((block) => block > 0) ||
      devicesWarned.some((warn) => warn > 0));

  React.useEffect(() => {
    if (
      chartData[TimePeriod.Monthly].mailsSent.length > 2 &&
      chartData[TimePeriod.Monthly].devicesWarned.length > 2
    ) {
      setMailPeriod(TimePeriod.Monthly);
      setWarnBlockPeriod(TimePeriod.Monthly);
    } else if (
      chartData[TimePeriod.Weekly].mailsSent.length >= 3 &&
      chartData[TimePeriod.Weekly].devicesWarned.length >= 3
    ) {
      setMailPeriod(TimePeriod.Weekly);
      setWarnBlockPeriod(TimePeriod.Weekly);
    } else {
      setMailPeriod(TimePeriod.Daily);
      setWarnBlockPeriod(TimePeriod.Daily);
    }
  }, [chartData]);

  React.useEffect(() => {
    if (mailPeriod && warnBlockPeriod) {
      setMailsSent(chartData[mailPeriod].mailsSent);
      setDevicesWarned(chartData[warnBlockPeriod].devicesWarned);
      setDevicesBlocked(chartData[warnBlockPeriod].devicesBlocked);
    }
  }, [chartData, mailPeriod, warnBlockPeriod]);

  React.useEffect(() => {
    const handleLanguageChanged = () => setLanguageKey((prev) => prev + 1);
    i18next.on("languageChanged", handleLanguageChanged);

    return () => {
      i18next.off("languageChanged", handleLanguageChanged);
    };
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "8px",
        flexDirection: "row",
        marginTop: "16px",
      }}
    >
      <StageCard
        key={`discovery-${languageKey}`}
        title={t("navigation.discovery")}
        active={discoveryActive}
        description={t("overview.details.discoveredThisMonth")}
        actionText={
          !discoveryActive ? t("overview.actions.enableDiscovery") : undefined
        }
        actionTime={
          !discoveryActive ? t("overview.actions.setupTime5") : undefined
        }
        onActionClick={() => NavigationService.navigateToDiscoveryOverview()}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Typography variant="h4">{discoveryDevices?.length ?? 0} </Typography>
          {discoveryActive && (
            <>
              <div className="stage-card-discovery-subtitle">
                <Typography variant="body2" style={{ marginTop: "16px" }}>
                  {t("overview.details.recentDiscovered")}{" "}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    marginTop: "16px",
                    fontWeight: "700",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    NavigationService.navigateToDevices(FilterType.Discovered)
                  }
                >
                  {t("overview.details.showAll")}{" "}
                </Typography>
              </div>
              {discoveryDevices?.slice(0, 4).map((device) => (
                <div key={device.device_id} className="discovery-device">
                  <div className="discovery-device-content">
                    <div className="left-content">
                      <p className="discovery-device-title">{device.email}</p>
                      <p className="discovery-device-description">
                        {getRelativeTime(device.timestamp_submitted * 1000, t)}
                      </p>
                    </div>
                    <div className="right-content">
                      <DeviceStatusChip
                        status={getDeviceStatus(device)}
                        risk={getDeviceRisk(device, undefined)}
                        label={
                          device.computer_name?.name ||
                          device.model ||
                          getDeviceType(device, t)
                        }
                        managed={device.managed}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </StageCard>
      <StageCard
        key={`awareness-${languageKey}`}
        title={t("navigation.awareness")}
        active={awarenessActive}
        description={t("overview.details.mailsSent")}
        actionText={
          !awarenessActive
            ? t("overview.actions.enableAwarenessEmails")
            : undefined
        }
        actionTime={
          !awarenessActive ? t("overview.actions.setupTime1") : undefined
        }
        onActionClick={() => NavigationService.navigateToAwarenessOverview()}
        period={mailPeriod}
        setPeriod={setMailPeriod}
        showPeriod={showMailPeriod}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "start",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyItems: "start",
            }}
          >
            <Typography variant="h4">
              {mailsSent[mailsSent.length - 1] ?? 0}
            </Typography>
          </div>
          {showMailPeriod && (
            <EmailsChart mailsSent={mailsSent} period={mailPeriod} />
          )}
        </div>
      </StageCard>
      <StageCard
        key={`applications-${languageKey}`}
        title={t("navigation.applications")}
        active={enforcementActive}
        description={t("overview.details.devicesWarned")}
        secondDescription={t("overview.details.devicesBlocked")}
        actionText={
          !enforcementActive
            ? t("overview.actions.startEnforcingXfa")
            : undefined
        }
        actionTime={
          !enforcementActive ? t("overview.actions.setupTime10") : undefined
        }
        onActionClick={() => NavigationService.navigateToApplications()}
        period={warnBlockPeriod}
        setPeriod={setWarnBlockPeriod}
        showPeriod={showWarnBlockPeriod}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "start",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "50%" }}
            >
              <Typography variant="h4">
                {devicesWarned[devicesWarned.length - 1] ?? 0}
              </Typography>
              <div
                style={{
                  marginLeft: "8px",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                <PercentageChange
                  valueAfter={devicesWarned[devicesWarned.length - 1]}
                  valueBefore={devicesWarned[devicesWarned.length - 2]}
                  reverse={true}
                />
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "50%" }}
            >
              <Typography variant="h4">
                {devicesBlocked[devicesBlocked.length - 1] ?? 0}
              </Typography>
              <div
                style={{
                  marginLeft: "8px",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                <PercentageChange
                  valueAfter={devicesBlocked[devicesBlocked.length - 1]}
                  valueBefore={devicesBlocked[devicesBlocked.length - 2]}
                  reverse={true}
                />
              </div>
            </div>
          </div>
          {showWarnBlockPeriod && (
            <EnforcementChart
              devicesBlocked={devicesBlocked}
              devicesWarned={devicesWarned}
              period={warnBlockPeriod}
            />
          )}
        </div>
      </StageCard>
    </div>
  );
};

export default StageCardList;
