import { Counter } from "../components/API/XFA_API";

export enum TimePeriod {
  Daily = "daily",
  Weekly = "weekly",
  Monthly = "monthly",
}

export interface CounterData {
  mailsSent: number[];
  devicesWarned: number[];
  devicesBlocked: number[];
}

export interface AggregatedData {
  daily: CounterData;
  weekly: CounterData;
  monthly: CounterData;
}

export function getAggregatedData(counters: Counter[]): AggregatedData {
  const dailyCounts: {
    [key: string]: {
      mailsSent: number;
      devicesWarned: number;
      devicesBlocked: number;
    };
  } = {};

  const weeklyCounts: {
    [key: string]: {
      mailsSent: number;
      devicesWarned: number;
      devicesBlocked: number;
    };
  } = {};

  const monthlyCounts: {
    [key: string]: {
      mailsSent: number;
      devicesWarned: number;
      devicesBlocked: number;
    };
  } = {};

  // Mock for testing purposes
  // const today = new Date();
  // const mockCounters: Counter[] = Array.from({ length: 145 }, (_, i) => {
  //   const date = new Date(today);
  //   date.setDate(today.getDate() - (144 - i));
  //   const dateStr = date.toISOString().slice(0, 10).replace(/-/g, "");
  //   return {
  //     date: dateStr,
  //     verification_email: Math.floor(Math.random() * 15) + 5,
  //     risk_email: Math.floor(Math.random() * 10) + 3,
  //     warned: Math.floor(Math.random() * 50),
  //     blocked: Math.floor(Math.random() * 40),
  //     organization_id: "",
  //     type: "",
  //   };
  // });

  // Check if the last date is today
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const todayStr = `${year}${month}${day}`;

  if (
    counters.length === 0 ||
    counters[counters.length - 1].date !== todayStr
  ) {
    counters.push({
      date: todayStr,
      verification_email: 0,
      risk_email: 0,
      warned: 0,
      blocked: 0,
      organization_id: "",
      type: "",
    });
  }

  counters.forEach((counter) => {
    const dateStr = counter.date;
    const year = parseInt(dateStr.slice(0, 4), 10);
    const month = parseInt(dateStr.slice(4, 6), 10) - 1;
    const day = parseInt(dateStr.slice(6, 8), 10);
    const date = new Date(year, month, day);

    // Daily
    const dayKey = date.toISOString().split("T")[0];
    if (!dailyCounts[dayKey]) {
      dailyCounts[dayKey] = {
        mailsSent: 0,
        devicesWarned: 0,
        devicesBlocked: 0,
      };
    }
    dailyCounts[dayKey].mailsSent +=
      counter.verification_email + counter.risk_email;
    dailyCounts[dayKey].devicesWarned += counter.warned;
    dailyCounts[dayKey].devicesBlocked += counter.blocked;

    // Weekly
    const week = `${date.getFullYear()}-W${getWeekNumber(date)}`;
    if (!weeklyCounts[week]) {
      weeklyCounts[week] = {
        mailsSent: 0,
        devicesWarned: 0,
        devicesBlocked: 0,
      };
    }
    weeklyCounts[week].mailsSent +=
      counter.verification_email + counter.risk_email;
    weeklyCounts[week].devicesWarned += counter.warned;
    weeklyCounts[week].devicesBlocked += counter.blocked;

    // Monthly
    const monthKey = `${date.getFullYear()}-${date.getMonth() + 1}`;
    if (!monthlyCounts[monthKey]) {
      monthlyCounts[monthKey] = {
        mailsSent: 0,
        devicesWarned: 0,
        devicesBlocked: 0,
      };
    }
    monthlyCounts[monthKey].mailsSent +=
      counter.verification_email + counter.risk_email;
    monthlyCounts[monthKey].devicesWarned += counter.warned;
    monthlyCounts[monthKey].devicesBlocked += counter.blocked;
  });

  const sortedDays = Object.keys(dailyCounts).sort();
  const sortedWeeks = Object.keys(weeklyCounts).sort();
  const sortedMonths = Object.keys(monthlyCounts).sort();

  return {
    daily: {
      mailsSent: sortedDays.slice(-5).map((day) => dailyCounts[day].mailsSent),
      devicesWarned: sortedDays
        .slice(-5)
        .map((day) => dailyCounts[day].devicesWarned),
      devicesBlocked: sortedDays
        .slice(-5)
        .map((day) => dailyCounts[day].devicesBlocked),
    },
    weekly: {
      mailsSent: sortedWeeks
        .slice(-5)
        .map((week) => weeklyCounts[week].mailsSent),
      devicesWarned: sortedWeeks
        .slice(-5)
        .map((week) => weeklyCounts[week].devicesWarned),
      devicesBlocked: sortedWeeks
        .slice(-5)
        .map((week) => weeklyCounts[week].devicesBlocked),
    },
    monthly: {
      mailsSent: sortedMonths
        .slice(-5)
        .map((month) => monthlyCounts[month].mailsSent),
      devicesWarned: sortedMonths
        .slice(-5)
        .map((month) => monthlyCounts[month].devicesWarned),
      devicesBlocked: sortedMonths
        .slice(-5)
        .map((month) => monthlyCounts[month].devicesBlocked),
    },
  };
}

export function getWeekNumber(d: Date): number {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  const weekNo = Math.ceil(
    ((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7,
  );
  return weekNo;
}

export const getLastPeriods = (
  count: number,
  period: TimePeriod,
  locale: string,
): string[] => {
  const now = new Date();
  return Array.from({ length: count }, (_, i) => {
    let date: Date;
    switch (period) {
      case TimePeriod.Daily:
        date = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - (count - 1 - i),
        );

        return locale.startsWith("en")
          ? `${date.toLocaleString("default", { month: "short" })} ${date.toLocaleDateString("default", { day: "numeric" })}`
          : `${date.toLocaleDateString("default", { day: "numeric" })} ${date.toLocaleString("default", { month: "short" })}`;
      case TimePeriod.Weekly:
        date = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - (count - 1 - i) * 7,
        );
        return `Week ${getWeekNumber(date)}`;
      case TimePeriod.Monthly:
      default:
        date = new Date(now.getFullYear(), now.getMonth() - (count - 1 - i));
        return date.toLocaleString("default", { month: "short" });
    }
  });
};
