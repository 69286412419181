import React, { useEffect, useState, useRef } from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Button,
  Tooltip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import "./DropdownMenu.css";
import {
  menuStyles,
  topMenuItemStyles,
  menuItemStyles,
  deleteMenuItemStyles,
  topAndLastMenuItemStyles,
  lastMenuItemStyles,
} from "./DropdownMenuStyles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export interface MenuAction {
  label: string;
  icon: React.ReactNode;
  onClick?: (policyID: string) => void;
  delete?: boolean;
  value?: string;
  disabledTooltip?: string;
  disabled?: boolean;
}

interface DropdownMenuProps {
  actions: MenuAction[];
  mode: "dropdown" | "select";
  defaultSelected?: string;
  disabled?: boolean;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  actions,
  mode,
  defaultSelected,
  disabled,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    defaultSelected,
  );
  const [buttonWidth, setButtonWidth] = useState<number | undefined>(undefined);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (action: MenuAction) => {
    if (mode === "select") {
      setSelectedOption(action.label);
    }
    if (!action.disabled && action.onClick) {
      action.onClick(action.value ?? "");
    }
    handleClose();
  };

  useEffect(() => {
    if (selectedOption === undefined) {
      setSelectedOption(actions[0].label);
    }
  }, []);

  useEffect(() => {
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
  }, [buttonRef.current]);

  return (
    <>
      {mode === "dropdown" ? (
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          disabled={disabled}
        >
          <MoreVertIcon />
        </IconButton>
      ) : (
        <Button
          ref={buttonRef}
          aria-label="select"
          aria-controls="long-menu"
          aria-haspopup="true"
          className="dropdown-button"
          onClick={handleClick}
          variant="outlined"
          disabled={disabled}
          endIcon={
            anchorEl !== null ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )
          }
        >
          {disabled
            ? t("policies.customPolicy")
            : t(selectedOption || actions[0].label)}
        </Button>
      )}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          ...menuStyles,
          "& .MuiMenu-list": {
            minWidth: buttonWidth,
            paddingTop: "0px",
            marginTop: "8px",
          },
          "& .MuiMenu-paper": {
            borderRadius: "6px",
            boxShadow: "0px 12px 16px -6px #00000014",
            backgroundColor: "transparent",
          },
        }}
        className="resend-invite"
      >
        {actions.map((action, index) => {
          const isLast = index === actions.length - 1;

          const menuItem = (
            <MenuItem
              onClick={() => handleSelect(action)}
              disabled={action.disabled}
              sx={{
                ...(actions.length === 1
                  ? topAndLastMenuItemStyles
                  : index === 0
                    ? topMenuItemStyles
                    : action.delete
                      ? deleteMenuItemStyles
                      : isLast
                        ? lastMenuItemStyles
                        : menuItemStyles),
                "&:hover": {
                  backgroundColor: "var(--color-gray-100)",
                },
              }}
            >
              {action.icon && (
                <ListItemIcon className="dropdown-list-item-icon">
                  {action.icon}
                </ListItemIcon>
              )}
              <ListItemText primary={t(action.label)} />
            </MenuItem>
          );

          return action.disabled ? (
            <Tooltip
              key={index}
              title={action.disabledTooltip || ""}
              arrow
              placement={"bottom"}
            >
              <span>{menuItem}</span>
            </Tooltip>
          ) : (
            <div key={index}>{menuItem}</div>
          );
        })}
      </Menu>
    </>
  );
};

export default DropdownMenu;
