import { useState, useEffect, useCallback } from "react";
import XFA_API, { Counter, Role } from "../../API/XFA_API";
import { useTranslation } from "react-i18next";

export const useCounters = (role: Role, isBlocked: boolean) => {
  const [counters, setCounters] = useState<Counter[] | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const { t } = useTranslation();

  const fetchCounters = useCallback(async () => {
    if (isBlocked) return;
    setLoading(true);
    try {
      const fetchCounters = await XFA_API.getCounters(
        role.organization.organization_id,
      );
      setCounters(fetchCounters);
    } catch (error) {
      setError(t("applications.error"));
    } finally {
      setLoading(false);
    }
  }, [isBlocked, role.organization.organization_id, t]);

  useEffect(() => {
    fetchCounters();
  }, [fetchCounters]);

  return { counters, loading, error, refresh: fetchCounters };
};
