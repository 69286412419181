import { useTranslation } from "react-i18next";
import { EnabledPolicy, Policies, VersionPolicy } from "../../API/XFA_API";

export const usePolicySettingsConfig = (
  policy: Policies,
  setPolicy: (policy: Policies) => void,
) => {
  const { t } = useTranslation();
  const days = 24 * 60 * 60;

  const getValidationAction = (policy: VersionPolicy | undefined): string =>
    policy === undefined
      ? "do_nothing"
      : policy.BlockTime
        ? "warn_and_block"
        : "warn_person";

  const getValidationActionForEnabledPolicy = (
    policy: EnabledPolicy | undefined,
  ): string =>
    policy === undefined
      ? "do_nothing"
      : policy.block
        ? "warn_and_block"
        : "warn_person";

  const setValidationAction = (
    action: string,
    currentPolicy: Policies,
    policyKey: keyof Policies,
    setPolicy: (policy: Policies) => void,
  ) => {
    const updatedPolicy =
      action === "do_nothing"
        ? undefined
        : {
            ...currentPolicy,
            BlockTime: action === "warn_and_block" ? 30 * days : undefined,
            WarnTime:
              action === "warn_person" || action === "warn_and_block"
                ? 0
                : undefined,
          };
    setPolicy({ ...currentPolicy, [policyKey]: updatedPolicy });
  };

  const setValidationActionForEnabledPolicy = (
    action: string,
    currentPolicy: Policies,
    policyKey: keyof Policies,
    setPolicy: (policy: Policies) => void,
  ) => {
    const updatedPolicy =
      action === "do_nothing"
        ? undefined
        : {
            ...currentPolicy,
            warn: action === "warn_and_block" || action === "warn_person",
            block: action === "warn_and_block",
          };
    setPolicy({ ...currentPolicy, [policyKey]: updatedPolicy });
  };

  return [
    // Skip
    {
      key: "skip",
      type: "PolicyDropdown",
      toggleTitle: t("applications.policies.skip"),
      toggleLabel: t("applications.policies.skipExplanation"),
      isVerificationEnabled: policy.skip ?? false,
      onVerificationToggle: (enabled: boolean) =>
        setPolicy({
          ...policy,
          skip: enabled,
        }),
      showRiskPeriodInput: false,
      showValidationAction: false,
      showAdditionalSettings: false,
    },
    // Unsupported
    {
      key: "unsupported",
      type: "PolicyDropdown",
      toggleTitle: t("applications.policies.unsupported"),
      toggleLabel: t("applications.policies.unsupportedExplanation"),
      isVerificationEnabled: policy.unsupported ?? false,
      onVerificationToggle: (enabled: boolean) =>
        setPolicy({
          ...policy,
          unsupported: enabled,
        }),
      showRiskPeriodInput: false,
      showValidationAction: false,
      showAdditionalSettings: false,
    },
    // Operating System
    {
      key: "os",
      type: "PolicyDropdown",
      toggleTitle: t("applications.policies.os"),
      toggleLabel: t("applications.policies.osExplanation"),
      description: t("discovery.OSUpToDate.tooltip"),
      isVerificationEnabled: !!policy.os,
      onVerificationToggle: (enabled: boolean) =>
        setPolicy({
          ...policy,
          os: enabled
            ? {
                ...policy.os,
                WarnTime: 0,
              }
            : undefined,
        }),
      validationAction: getValidationAction(policy.os),
      onValidationActionChange: (action: string) =>
        setValidationAction(action, policy, "os", setPolicy),
      warnActionPeriod: policy.os?.WarnTime ?? 0,
      onWarnActionPeriodChange: (period: number) =>
        setPolicy({
          ...policy,
          os: {
            ...policy.os,
            WarnTime: period,
          },
        }),
      blockActionPeriod: policy.os?.BlockTime ?? 30 * days,
      onBlockActionPeriodChange: (period: number) =>
        setPolicy({
          ...policy,
          os: {
            ...policy.os,
            BlockTime: period,
          },
        }),
      settings: [
        {
          title: t("applications.policies.majorVersionsAllow"),
          description: t("applications.policies.majorVersionsAllowExplanation"),
          allowEnabled:
            policy.os?.older_supported_major_versions?.dont_block === true,
          onAllowChange: (enabled: boolean) =>
            setPolicy({
              ...policy,
              os: {
                ...policy.os,
                older_supported_major_versions: {
                  ...policy.os?.older_supported_major_versions,
                  dont_block: enabled,
                  dont_warn:
                    policy.os?.older_supported_major_versions?.dont_warn,
                },
              },
            }),
          warningEnabled:
            policy.os?.older_supported_major_versions?.dont_warn !== true,
          onWarningChange: (enabled: boolean) => {
            setPolicy({
              ...policy,
              os: {
                ...policy.os,
                older_supported_major_versions: {
                  ...policy.os?.older_supported_major_versions,
                  dont_block:
                    policy.os?.older_supported_major_versions?.dont_block,
                  dont_warn: !enabled,
                },
              },
            });
          },
        },
        {
          title: t("applications.policies.betaVersionsAllow"),
          description: t("applications.policies.betaVersionsAllowExplanation"),
          allowEnabled: policy.os?.beta_versions?.dont_block === true,
          onAllowChange: (enabled: boolean) =>
            setPolicy({
              ...policy,
              os: {
                ...policy.os,
                beta_versions: {
                  ...policy.os?.beta_versions,
                  dont_block: enabled,
                  dont_warn: policy.os?.beta_versions?.dont_warn,
                },
              },
            }),
          warningEnabled: policy.os?.beta_versions?.dont_warn !== true,
          onWarningChange: (enabled: boolean) =>
            setPolicy({
              ...policy,
              os: {
                ...policy.os,
                beta_versions: {
                  ...policy.os?.beta_versions,
                  dont_warn: !enabled,
                  dont_block: policy.os?.beta_versions?.dont_block,
                },
              },
            }),
        },
      ],
      showRiskPeriodInput: false,
      showValidationAction: true,
      showAdditionalSettings: true,
    },
    // Auto Update (Operating System)
    {
      key: "os_autoupdate",
      type: "PolicyDropdown",
      toggleTitle: t("applications.policies.osAutoUpdate"),
      toggleLabel: t("applications.policies.osAutoUpdateExplanation"),
      description: t("discovery.OSAutoUpdate.tooltip"),
      isVerificationEnabled: !!policy.os_autoupdate,
      onVerificationToggle: (enabled: boolean) =>
        setPolicy({
          ...policy,
          os_autoupdate: enabled ? {
            ...policy.os_autoupdate,
            warn: true,
            block: false
          } : undefined,
        }),
      validationAction: getValidationActionForEnabledPolicy(
        policy.os_autoupdate,
      ),
      onValidationActionChange: (action: string) =>
        setValidationActionForEnabledPolicy(
          action,
          policy,
          "os_autoupdate",
          setPolicy,
        ),
      showRiskPeriodInput: false,
      showValidationAction: true,
      showAdditionalSettings: false,
    },
    // Browser
    {
      key: "browser",
      type: "PolicyDropdown",
      toggleTitle: t("applications.policies.browser"),
      toggleLabel: t("applications.policies.browserExplanation"),
      description: t("discovery.BrowserUpToDate.tooltip"),
      isVerificationEnabled: !!policy.browser,
      onVerificationToggle: (enabled: boolean) =>
        setPolicy({
          ...policy,
          browser: enabled
            ? {
                ...policy.browser,
                WarnTime: 0,
                older_supported_major_versions: {
                  dont_warn: false,
                  dont_block: false,
                },
                beta_versions: { dont_warn: false, dont_block: false },
              }
            : undefined,
        }),
      riskPeriod: undefined,
      onRiskPeriodChange: undefined,
      validationAction: getValidationAction(policy.browser),
      onValidationActionChange: (action: string) =>
        setValidationAction(action, policy, "browser", setPolicy),
      warnActionPeriod: policy.browser?.WarnTime ?? 0,
      onWarnActionPeriodChange: (period: number) =>
        setPolicy({
          ...policy,
          browser: {
            ...policy.browser,
            WarnTime: period,
          },
        }),
      blockActionPeriod: policy.browser?.BlockTime ?? 30 * days,
      onBlockActionPeriodChange: (period: number) =>
        setPolicy({
          ...policy,
          browser: {
            ...policy.browser,
            BlockTime: period,
          },
        }),
      settings: [
        {
          title: t("applications.policies.majorVersionsAllow"),
          description: t("applications.policies.majorVersionsAllowExplanation"),
          allowEnabled:
            policy.browser?.older_supported_major_versions?.dont_block === true,
          onAllowChange: (enabled: boolean) => {
            setPolicy({
              ...policy,
              browser: {
                ...policy.browser,
                older_supported_major_versions: {
                  ...policy.browser?.older_supported_major_versions,
                  dont_warn:
                    policy.browser?.older_supported_major_versions?.dont_warn,
                  dont_block: enabled,
                },
              },
            });
          },
          warningEnabled:
            policy.browser?.older_supported_major_versions?.dont_warn !== true,
          onWarningChange: (enabled: boolean) =>
            setPolicy({
              ...policy,
              browser: {
                ...policy.browser,
                older_supported_major_versions: {
                  ...policy.browser?.older_supported_major_versions,
                  dont_warn: !enabled,
                  dont_block:
                    policy.browser?.older_supported_major_versions?.dont_block,
                },
              },
            }),
        },
        {
          title: t("applications.policies.betaVersionsAllow"),
          description: t("applications.policies.betaVersionsAllowExplanation"),
          allowEnabled: policy.browser?.beta_versions?.dont_block === true,
          onAllowChange: (enabled: boolean) =>
            setPolicy({
              ...policy,
              browser: {
                ...policy.browser,
                beta_versions: {
                  ...policy.browser?.beta_versions,
                  dont_block: enabled,
                  dont_warn: policy.browser?.beta_versions?.dont_warn,
                },
              },
            }),
          warningEnabled: policy.browser?.beta_versions?.dont_warn !== true,
          onWarningChange: (enabled: boolean) =>
            setPolicy({
              ...policy,
              browser: {
                ...policy.browser,
                beta_versions: {
                  ...policy.browser?.beta_versions,
                  dont_warn: !enabled,
                  dont_block: policy.browser?.beta_versions?.dont_block,
                },
              },
            }),
        },
      ],
      showRiskPeriodInput: false,
      showValidationAction: true,
      showAdditionalSettings: true,
    },
    // Disk Encryption
    {
      key: "disk_encryption",
      type: "PolicyDropdown",
      toggleTitle: t("applications.policies.diskEncryption"),
      toggleLabel: t("applications.policies.diskEncryptionExplanation"),
      description: t("discovery.DiskEncryption.tooltip"),
      isVerificationEnabled: !!policy.disk_encryption,
      onVerificationToggle: (enabled: boolean) =>
        setPolicy({
          ...policy,
          disk_encryption: enabled ? {
            ...policy.disk_encryption,
            warn: true, block: false } : undefined,
        }),
      validationAction: getValidationActionForEnabledPolicy(
        policy.disk_encryption,
      ),
      onValidationActionChange: (action: string) =>
        setValidationActionForEnabledPolicy(
          action,
          policy,
          "disk_encryption",
          setPolicy,
        ),
      showRiskPeriodInput: false,
      showValidationAction: true,
      showAdditionalSettings: false,
    },
    // Screen Lock
    {
      key: "screen_lock",
      type: "PolicyDropdown",
      toggleTitle: t("applications.policies.screenlock"),
      toggleLabel: t("applications.policies.screenlockExplanation"),
      description: t("discovery.Biometrics.tooltip"),
      isVerificationEnabled: !!policy.screen_lock,
      onVerificationToggle: (enabled: boolean) =>
        setPolicy({
          ...policy,
          screen_lock: enabled ? {
            ...policy.screen_lock,
            warn: true, block: false } : undefined,
        }),
      validationAction: getValidationActionForEnabledPolicy(policy.screen_lock),
      onValidationActionChange: (action: string) =>
        setValidationActionForEnabledPolicy(
          action,
          policy,
          "screen_lock",
          setPolicy,
        ),
      showRiskPeriodInput: false,
      showValidationAction: true,
      showAdditionalSettings: false,
    },
    // Antivirus
    {
      key: "antivirus",
      type: "PolicyDropdown",
      toggleTitle: t("applications.policies.antivirus"),
      toggleLabel: t("applications.policies.antivirusExplanation"),
      description: t("discovery.Antivirus.tooltip"),
      isVerificationEnabled: !!policy.antivirus,
      onVerificationToggle: (enabled: boolean) =>
        setPolicy({
          ...policy,
          antivirus: enabled ? {
            ...policy.antivirus,
            warn: true, block: false } : undefined,
        }),
      validationAction: getValidationActionForEnabledPolicy(policy.antivirus),
      onValidationActionChange: (action: string) =>
        setValidationActionForEnabledPolicy(
          action,
          policy,
          "antivirus",
          setPolicy,
        ),
      showRiskPeriodInput: false,
      showValidationAction: true,
      showAdditionalSettings: false,
    },
    // Password Manager
    {
      key: "password_manager",
      type: "PolicyDropdown",
      toggleTitle: t("applications.policies.passwordmanager"),
      toggleLabel: t("applications.policies.passwordmanagerExplanation"),
      description: t("discovery.PasswordManager.tooltip"),
      isVerificationEnabled: !!policy.password_manager,
      onVerificationToggle: (enabled: boolean) =>
        setPolicy({
          ...policy,
          password_manager: enabled ? {
            ...policy.password_manager,
            warn: true, block: false } : undefined,
        }),
      validationAction: getValidationActionForEnabledPolicy(
        policy.password_manager,
      ),
      onValidationActionChange: (action: string) =>
        setValidationActionForEnabledPolicy(
          action,
          policy,
          "password_manager",
          setPolicy,
        ),
      showRiskPeriodInput: false,
      showValidationAction: true,
      showAdditionalSettings: false,
    },
    // Biometrics
    {
      key: "biometrics",
      type: "PolicyDropdown",
      toggleTitle: t("applications.policies.biometrics"),
      toggleLabel: t("applications.policies.biometricsExplanation"),
      description: t("discovery.Biometrics.tooltip"),
      isVerificationEnabled: !!policy.biometrics,
      onVerificationToggle: (enabled: boolean) =>
        setPolicy({
          ...policy,
          biometrics: enabled ? {
            ...policy.biometrics,
            warn: true, block: false } : undefined,
        }),
      validationAction: getValidationActionForEnabledPolicy(policy.biometrics),
      onValidationActionChange: (action: string) =>
        setValidationActionForEnabledPolicy(
          action,
          policy,
          "biometrics",
          setPolicy,
        ),
      showRiskPeriodInput: false,
      showValidationAction: true,
      showAdditionalSettings: false,
    },
  ];
};
