import React from "react";
import { Box, Typography, Container, FormControl, Link } from "@mui/material";
import CopyIdComponent from "../Applications/NewOrEditPage/CopyIdComponent";
import { useTranslation } from "react-i18next";
import logo from "../../images/XFA_woordmerk_gray900.svg";

import TagManager from "react-gtm-module";

interface DiscoveryReportFinishProps {}

const DiscoveryReportFinish: React.FC<DiscoveryReportFinishProps> = () => {
  const { t } = useTranslation();
  const didUseGoogle =
    localStorage.getItem("didUseGoogle") !== undefined &&
    localStorage.getItem("didUseGoogle") === "true";

  React.useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-WRMF59TQ",
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <Container
      maxWidth="sm"
      style={{ display: "flex", alignItems: "center", minHeight: "100vh" }}
    >
      <Box textAlign="start" mt={5} width="100%">
        <div style={{ textAlign: "center", width: "100%" }}>
          <img
            alt="logo"
            src={logo}
            style={{
              height: 30,
              marginBottom: 50,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </div>
        <Typography variant="h4" style={{ textAlign: "center" }} gutterBottom>
          {t("login.discovery.freeReportFinishedTitle")}
        </Typography>
        <Typography
          variant="body1"
          style={{ marginTop: 16, marginBottom: 16, textAlign: "center" }}
        >
          {t("login.discovery.freeReportFinishedDescription")}
        </Typography>
        {didUseGoogle === true && (
          <>
            <Typography variant="body1" style={{ marginBottom: 16 }}>
              {t("login.discovery.freeReportFinishedGoogle")}
            </Typography>
            <FormControl fullWidth={true} style={{ textAlign: "start" }}>
              <Typography variant="instructions">
                {t("applications.google.step2content")}{" "}
                {t("applications.google.step2instruction1")}{" "}
                <Link
                  href="https://admin.google.com/ac/owl/domainwidedelegation"
                  target="_blank"
                >
                  {t("applications.google.step2link")}
                </Link>
                {t("applications.google.step2instruction2")}
              </Typography>
              <div style={{ marginBottom: 16 }}></div>
              <CopyIdComponent
                title={t("applications.google.clientId")}
                value={"107563464460134163966"}
                disabled={false}
              />
              <CopyIdComponent
                title={t("applications.google.scope")}
                value={"https://www.googleapis.com/auth/cloud-identity.devices"}
                disabled={false}
              />
              <Typography variant="instructions">
                {t("applications.google.step2instruction3")}
              </Typography>
            </FormControl>
          </>
        )}
        <Typography
          variant="body1"
          style={{ marginTop: 16, textAlign: "center" }}
        >
          {t("login.discovery.freeReportFinishedDescription2")}
        </Typography>
        <Typography
          variant="body1"
          style={{ marginTop: 32, textAlign: "center" }}
        >
          {t("login.discovery.freeReportFinishedContact")}{" "}
          <Link href="mailto:marketing@xfa.tech">marketing@xfa.tech</Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default DiscoveryReportFinish;
