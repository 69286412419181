import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../Applications/Applications.css";
import { Device, Policies, Role } from "../API/XFA_API";
import { Button, Grid, Tab, Tabs } from "@mui/material";

import DiscoveryHighlight from "./DiscoveryHighlight";
import "./Discovery.css";
import { a11yProps, TabPanel } from "../General/TabUtils";
import NavigationService from "../../utils/NavigationService";

interface DiscoveryHighlightsProps {
  role: Role;
  selectedPolicy: Policies;
  devices: Device[];
}

const DiscoveryHighlights: React.FC<DiscoveryHighlightsProps> = (
  props: DiscoveryHighlightsProps,
) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const securityChecks = [
    {
      propertyName: "os_uptodate",
      tooltip: t("discovery.OSUpToDate.tooltip"),
      tooltipExtra: undefined,
      enforced: props.selectedPolicy.os,
    },
    {
      propertyName: "os_autoupdate",
      tooltip: t("discovery.OSAutoUpdate.tooltip"),
      tooltipExtra: undefined,
      enforced: props.selectedPolicy.os_autoupdate,
    },
    {
      propertyName: "browser_uptodate",
      tooltip: t("discovery.BrowserUpToDate.tooltip"),
      tooltipExtra: t("discovery.BrowserUpToDate.tooltip_extra"),
      enforced: props.selectedPolicy.browser,
    },
    {
      propertyName: "diskencryption_active",
      tooltip: t("discovery.DiskEncryption.tooltip"),
      tooltipExtra: undefined,
      enforced: props.selectedPolicy.disk_encryption,
    },
    {
      propertyName: "authentication_active",
      tooltip: t("discovery.Authentication.tooltip"),
      tooltipExtra: undefined,
      enforced: props.selectedPolicy.screen_lock,
    },

    {
      propertyName: "antivirus_enabled",
      tooltip: t("discovery.Antivirus.tooltip"),
      tooltipExtra: t("discovery.Antivirus.tooltip_extra"),
      enforced: props.selectedPolicy.antivirus,
    },
    {
      propertyName: "passwordmanager",
      tooltip: t("discovery.PasswordManager.tooltip"),
      tooltipExtra: t("discovery.PasswordManager.tooltip_extra"),
      enforced: props.selectedPolicy.password_manager,
    },
    {
      propertyName: "biometrics",
      tooltip: t("discovery.Biometrics.tooltip"),
      tooltipExtra: t("discovery.Biometrics.tooltip_extra"),
      enforced: props.selectedPolicy.biometrics,
    }
  ];

  const enforcedChecks = securityChecks.filter(
    (check) => check.enforced && Object.keys(check.enforced).length > 0,
  );
  const notEnforcedChecks = securityChecks.filter(
    (check) => !check.enforced || Object.keys(check.enforced).length === 0,
  );

  return (
    <div className="w-full" data-cy="applications">
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="settings tabs"
        TabIndicatorProps={{
          style: {
            backgroundColor: "#393E45",
          },
        }}
        sx={{
          borderBottom: "1px solid #EAECF0",
          "& .MuiTab-root": {
            fontFamily: "Manrope",
            fontSize: "14px",
            lineHeight: "20px",
            textTransform: "none",
          },
          "& .Mui-selected": {
            color: "#393E45",
            fontWeight: 700,
          },
          "& .MuiTab-root:not(.Mui-selected)": {
            color: "#6D717A",
            fontWeight: 600,
          },
        }}
      >
        <Tab
          label={`${t("statistics.enforcedChecks")} (${enforcedChecks.length})`}
          {...a11yProps(0)}
        />
        <Tab
          label={`${t("statistics.notEnforcedChecks")} (${notEnforcedChecks.length})`}
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel value={tabValue} index={tabValue}>
        <div className="overview device-checks">
          {tabValue === 0 &&
            enforcedChecks.map((check, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <DiscoveryHighlight
                  devices={props.devices}
                  propertyName={check.propertyName as keyof Device}
                  tooltip={check.tooltip}
                  tooltipExtra={check.tooltipExtra}
                />
              </Grid>
            ))}
          {tabValue === 1 &&
            notEnforcedChecks.map((check, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <DiscoveryHighlight
                  devices={props.devices}
                  propertyName={check.propertyName as keyof Device}
                  tooltip={check.tooltip}
                  tooltipExtra={check.tooltipExtra}
                />
              </Grid>
            ))}
          <Button
            variant="outlined"
            className="healthButton"
            style={{
              borderColor: "#e0e0e0",
              color: "var(--color-gray-500)",
              fontSize: "12px",
              fontWeight: "600",
              letterSpacing: "0.09em",
              height: "134px",
              textTransform: "uppercase",
            }}
            onClick={() => {
              NavigationService.navigateToPolicy(
                props.selectedPolicy.policy_id,
              );
            }}
          >
            {t("statistics.editPolicy")}
          </Button>
        </div>
      </TabPanel>
    </div>
  );
};

export default DiscoveryHighlights;
