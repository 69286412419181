import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Divider,
  CircularProgress,
  Typography,
  Alert,
  Tabs,
  Tab,
  Button,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Role, Transaction, Device, UserInformation } from "../API/XFA_API";
import { useTransactions } from "./hooks/useTransactions";
import { a11yProps, TabPanel } from "../General/TabUtils";
import "./ActivityPage.css";
import BreadCrumbs from "../General/BreadCrumbs";
import ActivityList from "./ActivityList";

interface ActivityPageProps {
  role: Role;
}

interface ExtendedUserInformation extends UserInformation {
  email: string;
}

interface ExtendedDevice extends Device {
  user: ExtendedUserInformation;
}

const ActivityPage: React.FC<ActivityPageProps> = ({ role }) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const { transactions, devices, loading, error, refresh } =
    useTransactions(role);
  const breadcrumbLinks = [
    { label: t("navigation.activity"), href: "/activity" },
  ];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const getFilteredTransactions = (
    transactions: Transaction[] | undefined,
    type: "all" | "blocked" | "granted",
  ) => {
    if (!transactions) return [];

    switch (type) {
      case "blocked":
        return transactions.filter(
          (transaction) =>
            transaction.status === "COMPLETED" &&
            transaction.decisions &&
            Object.values(transaction.decisions).some(
              (decision) => decision?.status === "BLOCKING",
            ),
        );
      case "granted":
        return transactions.filter(
          (transaction) => transaction.status === "GRANTED",
        );
      default:
        return transactions;
    }
  };

  const renderTransactionList = (type: "all" | "blocked" | "granted") => {
    if (loading) {
      return (
        <div className="flex justify-center py-4">
          <CircularProgress size={30} />
        </div>
      );
    }

    if (error) {
      return (
        <Alert severity="error" className="my-4">
          {error}
        </Alert>
      );
    }

    const filteredTransactions = getFilteredTransactions(transactions, type);

    if (!filteredTransactions.length) {
      return (
        <div className="text-center py-8 text-gray-500">
          {t("activity.noActivity")}
        </div>
      );
    }

    return (
      <ActivityList
        refresh={refresh}
        transactions={filteredTransactions}
        devices={devices || []}
      />
    );
  };

  return (
    <div>
      <BreadCrumbs links={breadcrumbLinks} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="pagetitle" color="primary">
          {t("navigation.activity")}
        </Typography>

        <div
          className="flex items-center justify-between"
          style={{ marginTop: "16px" }}
        >
          <div className="flex-1">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="activity tabs"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#393E45",
                },
              }}
              sx={{
                "& .MuiTab-root": {
                  fontFamily: "Manrope",
                  fontSize: "14px",
                  lineHeight: "20px",
                  textTransform: "none",
                },
                "& .Mui-selected": {
                  color: "#393E45",
                  fontWeight: 700,
                },
                "& .MuiTab-root:not(.Mui-selected)": {
                  color: "#6D717A",
                  fontWeight: 600,
                },
              }}
            >
              <Tab label={t("activity.tabs.all")} {...a11yProps(0)} />
              <Tab label={t("activity.tabs.blocked")} {...a11yProps(1)} />
              <Tab label={t("activity.tabs.granted")} {...a11yProps(2)} />
            </Tabs>
          </div>
          <Button
            variant="outlined"
            onClick={refresh}
            startIcon={<RefreshIcon />}
            sx={{
              marginLeft: 2,
              height: 36,
            }}
          >
            {t("activity.refresh")}
          </Button>
        </div>
        <Divider style={{ marginTop: "-1px" }} />

        <TabPanel value={tabValue} index={0}>
          {renderTransactionList("all")}
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          {renderTransactionList("blocked")}
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          {renderTransactionList("granted")}
        </TabPanel>
      </div>
    </div>
  );
};

export default ActivityPage;
