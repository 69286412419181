import React from "react";
import DiscoveryOnboarding from "../DiscoveryOnboarding/DiscoveryOnboarding";
import {
  RedirectEndpointType,
  askForGooglePermissions,
  askForMicrosoftPermissions,
} from "../../utils/DiscoveryService";
import { Route, Routes } from "react-router-dom";
import { Role } from "../API/XFA_API";
import ConnectGoogle from "../Applications/NewOrEditPage/Google/ConnectGoogle";
import ConnectMicrosoft from "../Applications/NewOrEditPage/Microsoft/ConnectMicrosoft";
import DiscoveryReportFinish from "./DiscoveryReportFinish";
import { API } from "aws-amplify";
import { getApiConfig } from "../../utils/apiConfig";

interface FreeDiscoveryReportProps {
  role?: Role;
}

const FreeDiscoveryReport = (props: FreeDiscoveryReportProps) => {
  API.configure(getApiConfig());

  return (
    <Routes>
      <Route path="" element={<Overview {...props} />} />
      <Route path="connect-google" element={<ConnectGoogle />} />
      <Route path="connect-microsoft" element={<ConnectMicrosoft />} />
      <Route path="finish" element={<DiscoveryReportFinish />} />
    </Routes>
  );
};
interface OverviewProps {}

const Overview: React.FC<OverviewProps> = (props: OverviewProps) => {
  return (
    <DiscoveryOnboarding
      onGoogleDiscovery={() => {
        askForGooglePermissions(RedirectEndpointType.FREE_REPORT);
      }}
      onMicrosoftDiscovery={() => {
        askForMicrosoftPermissions(RedirectEndpointType.FREE_REPORT);
      }}
      freeReport={true}
    />
  );
};

export default FreeDiscoveryReport;
