import React, { CSSProperties } from "react";
import { TableCell } from "@mui/material";
import DeviceStatusChip from "./DeviceStatusChip";
import { Device, Policies } from "../API/XFA_API";
import { getDeviceRisk, getDeviceStatus, getDeviceType } from "./UserUtils";
import { useTranslation } from "react-i18next";

const DeviceStatusCell = ({
  devices,
  onClick,
  style,
  policy,
}: {
  devices: Device[];
  onClick?: () => void;
  style: CSSProperties;
  policy?: Policies | undefined;
}) => {
  const { t } = useTranslation();

  return (
    <TableCell component="th" scope="row" onClick={onClick} style={style}>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {devices.length > 0 && (
          <>
            {devices.map((device: Device) => (
              <DeviceStatusChip
                key={device.device_id}
                status={getDeviceStatus(device)}
                risk={getDeviceRisk(device, policy)}
                label={
                  device.computer_name?.name ||
                  device.model ||
                  getDeviceType(device, t)
                }
                managed={device.managed}
              />
            ))}
          </>
        )}
      </div>
    </TableCell>
  );
};

export default DeviceStatusCell;
