import React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { useTranslation } from "react-i18next";
import { getLastPeriods, TimePeriod } from "../../../utils/counterUtils";

interface EmailsChartProps {
  mailsSent: number[];
  period: TimePeriod;
}

const EmailsChart: React.FC<EmailsChartProps> = ({ mailsSent, period }) => {
  const { t, i18n } = useTranslation();

  const count = {
    daily: Math.min(5, mailsSent.length),
    weekly: Math.min(5, mailsSent.length),
    monthly: Math.min(5, mailsSent.length),
  }[period];

  const periods = getLastPeriods(count, period, i18n.language);
  const average =
    mailsSent.reduce((sum, value) => sum + value, 0) / mailsSent.length;

  const rawMin = Math.max(0, Math.min(...mailsSent) - 10);
  const rawMax = Math.max(...mailsSent) + 5;

  const step = Math.ceil((rawMax - rawMin) / 4 / 5) * 5;
  const minValue = Math.floor(rawMin / step) * step;
  const maxValue = Math.ceil(rawMax / step) * step;

  const data = mailsSent.map((value, index) => ({
    month: periods[index],
    mails: value,
    average,
  }));

  return (
    <div style={{ width: "100%", height: "330px", marginTop: "24px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{ top: 10, right: 20, left: 20, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 0" vertical={false} />
          <XAxis
            dataKey="month"
            tick={{ fill: "#9BA1AB" }}
            tickMargin={10}
            axisLine={false}
            tickLine={{ transform: "translate(0, -6)", fill: "#9BA1AB" }}
            padding={{ left: 10, right: 10 }}
          />
          <YAxis
            domain={[minValue, maxValue]}
            tick={{ fill: "#9BA1AB" }}
            axisLine={false}
            width={16}
            tickLine={false}
            ticks={Array.from({ length: 5 }, (_, i) => minValue + i * step)}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: "#fff",
              borderColor: "#ddd",
              color: "#9BA1AB",
            }}
            itemStyle={{ color: "#9BA1AB" }}
            labelStyle={{ color: "#9BA1AB" }}
          />
          <Legend
            align="right"
            verticalAlign="bottom"
            wrapperStyle={{
              paddingTop: "10px",
              color: "#9BA1AB",
            }}
            iconType="plainline"
          />
          <ReferenceLine
            y={average}
            stroke="#000"
            strokeDasharray="2 4"
            strokeWidth={1}
            name="Average"
            label={{
              value: `${average.toFixed(0)}`,
              fill: "#9BA1AB",
              position: "right",
              fontSize: 12,
            }}
          />
          <Line
            type="linear"
            dataKey="mails"
            stroke="#1D4ED8"
            strokeWidth={2}
            name={t("overview.details.mailsSent")}
            fontSize={14}
            dot={false}
          />
          <Line
            type="linear"
            dataKey="average"
            stroke="#000"
            strokeDasharray="3 3"
            name={t("overview.details.average")}
            dot={false}
            legendType="plainline"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default EmailsChart;
