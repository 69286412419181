import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { ActionButtonProps } from "../types/types";
import CheckIcon from "@mui/icons-material/Check";

export const ActionButton: React.FC<ActionButtonProps> = ({
  isBlocking,
  isGranted,
  loading,
  onAllowAccess,
}) => {
  const { t } = useTranslation();

  if (!isBlocking && !isGranted) return null;

  return (
    <Button
      variant="secondary"
      onClick={onAllowAccess}
      disabled={loading || isGranted}
      sx={{
        padding: "4px 8px",
      }}
    >
      {loading ? (
        <span className="flex items-center gap-2">
          <span className="loader"></span>
          {t("activity.allowAccess")}
        </span>
      ) : isGranted ? (
        <span className="flex items-center gap-2">
          <CheckIcon fontSize="small" />
          {t("activity.accessGranted")}
        </span>
      ) : (
        t("activity.allowAccess")
      )}
    </Button>
  );
};
