import React from "react";
import { useTranslation } from "react-i18next";
import "./Applications.css";
import XFA_API, { Application, Policies, Role } from "../API/XFA_API";
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NewOrEditApplication from "./NewOrEditPage/NewOrEditApplication";
import { Route, Routes } from "react-router-dom";
import EmptyState from "../General/EmptyState";
import { trackEvent } from "../intercom/intercomProvider";
import ApplicationCard from "./ApplicationCard";
import ConnectGoogle from "./NewOrEditPage/Google/ConnectGoogle";
import ConnectMicrosoft from "./NewOrEditPage/Microsoft/ConnectMicrosoft";
import ConfirmationDialog from "../Dialogs/ConfirmationDialog";
import NavigationService from "../../utils/NavigationService";
import BreadCrumbs from "../General/BreadCrumbs";
import { isDiscoveryApplication } from "../../utils";

interface ApplicationsProps {
  role: Role;
}

const Applications: React.FC<ApplicationsProps> = (
  props: ApplicationsProps,
) => {
  return (
    <Routes>
      <Route path="" element={<Overview {...props} />} />
      <Route
        path="new"
        element={
          <NewOrEditApplication
            role={props.role}
            organizationId={props.role.organization.organization_id}
          />
        }
      />
      <Route
        path="connect-google"
        element={
          <ConnectGoogle
            organizationId={props.role.organization.organization_id}
          />
        }
      />
      <Route
        path="connect-microsoft"
        element={
          <ConnectMicrosoft
            organizationId={props.role.organization.organization_id}
          />
        }
      />
      <Route
        path=":applicationId"
        element={
          <NewOrEditApplication
            role={props.role}
            organizationId={props.role.organization.organization_id}
          />
        }
      />
    </Routes>
  );
};

interface OverviewProps {
  role: Role;
}

const Overview: React.FC<OverviewProps> = (props: OverviewProps) => {
  const { t } = useTranslation();
  const [error, setError] = React.useState<string>("");
  const [dashboardApplicationId, setDashboardApplicationId] = React.useState<
    string | undefined
  >(undefined);
  const [applications, setApplications] = React.useState<
    Application[] | undefined
  >(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [deleteInProgress, setDeleteInProgress] =
    React.useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = React.useState<
    Application | undefined
  >(undefined);
  const breadcrumbLinks = [
    { label: t("navigation.applications"), href: "/applications" },
  ];
  const [policies, setPolicies] = React.useState<Policies[]>([]);

  const refreshApplications = React.useCallback(async () => {
    setLoading(true);
    try {
      const policies = await XFA_API.getPolicies(
        props.role.organization.organization_id,
      );
      setPolicies(policies);
      const apps = await XFA_API.getApplications(
        props.role.organization.organization_id,
      );
      const dashboardApplication = apps.find(
        (app) => app.UniquePurpose === "DashboardDemo",
      );
      if (dashboardApplication) {
        const dashboardApplicationId = dashboardApplication.ApplicationID;
        if (dashboardApplicationId !== undefined) {
          setDashboardApplicationId(dashboardApplicationId);
        }
      }
      setLoading(false);
      const filteredApplications = apps.filter(
        (app) => !isDiscoveryApplication(app),
      );
      setApplications(filteredApplications);
    } catch (error: any) {
      setLoading(false);
      setError(t("applications.error"));
    }
  }, [t, props.role.organization.organization_id]);

  React.useEffect(() => {
    refreshApplications();
  }, [refreshApplications]);

  return (
    <div>
      <BreadCrumbs links={breadcrumbLinks} />
      <div className="flex">
        <Typography variant="pagetitle" color="primary">
          {t("navigation.applications")}
        </Typography>
        <div style={{ marginLeft: "auto", display: "flex", gap: 4 }}>
          {dashboardApplicationId !== undefined && (
            <Button
              variant="secondary"
              onClick={() => {
                trackEvent("Experience XFA");
                const url = new URL(process.env.REACT_APP_TOKEN_ENDPOINT!);
                url.searchParams.set("redirect_url", window.location.href);
                url.searchParams.set("email", props.role.email);
                url.searchParams.set("application_id", dashboardApplicationId);
                NavigationService.navigateToExternal(url.toString());
              }}
            >
              {t("applications.experience")}
            </Button>
          )}
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              trackEvent("Creating new application");
              NavigationService.navigateToNewApplication();
            }}
          >
            {t("applications.new")}
          </Button>
        </div>
      </div>
      <Divider style={{ marginTop: 16 }} />
      <div className="w-full" data-cy="applications">
        {error && (
          <div style={{ marginBottom: 15 }}>
            <Alert severity="error">{error}</Alert>
          </div>
        )}
        {applications && applications.length === 0 ? (
          <EmptyState
            context="applications"
            onClick={() => {
              NavigationService.navigateToNewApplication();
            }}
          />
        ) : loading ? (
          <div className="center">
            <CircularProgress />{" "}
          </div>
        ) : (
          <Grid
            container
            className="overview"
            spacing={1}
            style={{ marginTop: 8 }}
          >
            {applications &&
              applications
                .sort((a, b) => {
                  if (
                    a.UniquePurpose !== undefined &&
                    b.UniquePurpose === undefined
                  ) {
                    return -1;
                  }
                  if (
                    a.UniquePurpose === undefined &&
                    b.UniquePurpose !== undefined
                  ) {
                    return 1;
                  }

                  return a.Name.localeCompare(b.Name);
                })
                .map((application: Application) => {
                  const description =
                    application.Type === "MicrosoftEAM"
                      ? "Microsoft EAM"
                      : application.Type === "Okta"
                        ? "Okta"
                        : application.Type === "Onelogin"
                          ? "Onelogin"
                          : application.OAuthConfiguration !== undefined
                            ? "OAuth"
                            : application.SAMLConfiguration !== undefined
                              ? "SAML"
                              : (application.Type ?? t("applications.custom"));
                  const selectedPolicy = policies.find(
                    (policy) => policy.policy_id === application.PolicyID,
                  );
                  const skipped = selectedPolicy
                    ? (selectedPolicy.skip ?? false)
                    : (application.Policies?.skip ?? false);
                  return (
                    <Grid item key={application.ApplicationID}>
                      <ApplicationCard
                        enabled={application.Enabled}
                        default={application.UniquePurpose !== undefined}
                        title={application.Name}
                        logo={application.IconUrl}
                        description={description}
                        skipped={skipped}
                        filtered={
                          application.FilterDesktop ||
                          application.FilterMobile ||
                          (application.FilteredEmail?.length ?? 0) > 0
                        }
                        showStatus={true}
                        setEnabled={undefined}
                        onManage={() => {
                          NavigationService.navigateToApplication(
                            application.ApplicationID,
                          );
                        }}
                        onDelete={
                          application.UniquePurpose === undefined
                            ? () => setConfirmDelete(application)
                            : undefined
                        }
                      />
                    </Grid>
                  );
                })}
          </Grid>
        )}
      </div>
      {/* Confirm delete dialog */}
      {confirmDelete !== undefined && (
        <ConfirmationDialog
          title={t("applications.delete.confirmTitle") + confirmDelete?.Name}
          cancelText={t("applications.delete.cancel")}
          confirmText={t("applications.delete.confirm")}
          inProgress={deleteInProgress}
          onCancel={() => {
            setConfirmDelete(undefined);
          }}
          onConfirm={() => {
            setDeleteInProgress(true);
            XFA_API.deleteApplication(confirmDelete!).then(() => {
              setConfirmDelete(undefined);
              refreshApplications().then((_) => setDeleteInProgress(false));
            });
          }}
        />
      )}
    </div>
  );
};
export default Applications;
