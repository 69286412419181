import React from "react";
import XFA_API from "../../../API/XFA_API";
import NavigationService from "../../../../utils/NavigationService";
import {
  RedirectEndpointType,
  getGoogleRedirectUri,
} from "../../../../utils/DiscoveryService";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ConnectGoogleProps {
  organizationId?: string;
}

const ConnectGoogle: React.FC<ConnectGoogleProps> = (
  props: ConnectGoogleProps,
) => {
  const { t } = useTranslation();
  React.useEffect(() => {
    const code = new URLSearchParams(window.location.search).get("code");
    const applicationId = new URLSearchParams(window.location.search).get(
      "state",
    );
    const isFreeDiscoveryReport = window.location.pathname.startsWith(
      "/free-discovery-report",
    );
    const freeReportEmail = localStorage.getItem("freeReportEmail");

    if (code && applicationId && props.organizationId) {
      //clear the code to prevent re-creation
      window.history.replaceState({}, document.title, window.location.pathname);

      XFA_API.connectApplication(
        props.organizationId,
        applicationId,
        code,
        getGoogleRedirectUri(RedirectEndpointType.DEFAULT),
      )
        .then((connectionError) => {
          NavigationService.navigateToDiscovery(applicationId, connectionError);
        })
        .catch((error) => {
          NavigationService.navigateToDiscovery(applicationId, error);
        });
    } else if (code && isFreeDiscoveryReport) {
      XFA_API.getFreeReport(
        code,
        getGoogleRedirectUri(RedirectEndpointType.FREE_REPORT),
        "Google",
        freeReportEmail ?? undefined,
      )
        .then((connectionError) => {
          localStorage.setItem("didUseGoogle", "true");
          if (
            connectionError?.GoogleCloudIdentityAPIError &&
            !connectionError.GoogleCloudIdentityAPIError?.includes(
              "unable to retrieve devices",
            )
          ) {
            localStorage.setItem(
              "connectionError",
              connectionError.GoogleCloudIdentityAPIError?.toString() ?? "",
            );
            NavigationService.navigateToFreeReport();
          } else {
            localStorage.removeItem("freeReportEmail");
            NavigationService.navigateToReportFinish();
          }
        })
        .catch((error) => {
          localStorage.setItem("didUseGoogle", "true");
          localStorage.setItem("error", error);
          NavigationService.navigateToFreeReport();
        });
    }
  }, []);

  return (
    <div style={{ marginTop: "40vh", textAlign: "center" }}>
      {t("discovery.connectingGoogle")}
      <CircularProgress size={16}></CircularProgress>
    </div>
  );
};

export default ConnectGoogle;
