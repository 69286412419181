import React from "react";
import AppleIcon from "../../../icons/apple-icon.svg";
import WindowsIcon from "../../../icons/microsoft-icon.svg";
import AndroidIcon from "../../../icons/android-icon.svg";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import LinuxIcon from "../../../icons/linux-icon.svg";

interface DeviceIconProps {
  deviceName: string;
}

export const DeviceIcon: React.FC<DeviceIconProps> = ({ deviceName }) => {
  const lowerDeviceName = deviceName.toLowerCase();
  const iconStyle = {
    width: "16px",
    height: "16px",
    marginTop: "-2px",
    marginRight: "4px",
    marginLeft: "4px",
  };

  if (
    lowerDeviceName.includes("mac") ||
    lowerDeviceName.includes("iphone") ||
    lowerDeviceName.includes("ipad") ||
    lowerDeviceName.includes("ios")
  ) {
    return <img src={AppleIcon} style={iconStyle} alt="Apple" />;
  }
  if (lowerDeviceName.includes("windows") || lowerDeviceName.includes("pc")) {
    return <img src={WindowsIcon} style={iconStyle} alt="Windows" />;
  }
  if (lowerDeviceName.includes("android")) {
    return <img src={AndroidIcon} style={iconStyle} alt="Android" />;
  }
  if (lowerDeviceName.includes("linux")) {
    return <img src={LinuxIcon} style={iconStyle} alt="Linux" />;
  }
  return <DevicesOtherIcon style={iconStyle} />;
};
