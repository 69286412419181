import { API, Auth } from "aws-amplify";
import { calculateDaysSinceFiveMonthsAgoStart } from "../../utils";
import { getRecentDevices } from "../Users/UserUtils";

export interface Shadow {
  email: string;
  devices: Device[];
}

export interface Device {
  device_id: string;
  timestamp_submitted: number;
  os_name?: string;
  os_version?: string;
  os_flavour?: string;
  os_autoupdate?: boolean;
  os_uptodate?: boolean;
  user?: UserInformation;
  computer_name?: ComputerName;
  android_security_level?: string;
  manufacturer?: string;
  model?: string;
  browser_name?: string;
  browser_last_used?: string;
  browser_version?: string;
  browser_last_updated?: string;
  browser_uptodate?: boolean;
  chrome_version?: string;
  chrome_uptodate?: boolean;
  chrome_safebrowsing_enabled?: boolean;
  chrome_safebrowsing_evaluated_by_policy?: boolean;
  chrome_safebrowsing_enhanced?: boolean;
  chrome_safebrowsing_enhanced_evaluated_by_policy?: boolean;
  chrome_do_not_track?: boolean;
  chrome_do_not_track_evaluated_by_policy?: boolean;
  firefox_version?: string;
  firefox_uptodate?: boolean;
  firefox_https_only?: boolean;
  firefox_https_only_evaluated_by_policy?: boolean;
  firefox_dns_over_https?: boolean;
  firefox_dns_over_https_evaluated_by_policy?: boolean;
  firefox_enhanced_tracking_protection?: boolean;
  firefox_enhanced_tracking_protection_evaluated_by_policy?: boolean;
  firefox_enhanced_tracking_protection_strict?: boolean;
  firefox_enhanced_tracking_protection_strict_evaluated_by_policy?: boolean;
  edge_version?: string;
  edge_uptodate?: boolean;
  safari_version?: string;
  safari_uptodate?: boolean;
  antivirus_enabled?: boolean;
  firewall_enabled?: boolean;
  firewall_evaluated_by_policy?: boolean;
  findmydevice_enabled?: boolean;
  findmydevice_evaluated_by_policy?: boolean;
  vanta_client_running?: boolean;
  vanta_client_running_evaluated_by_policy?: boolean;
  intune_enrolled?: boolean;
  intune_tenant_ids?: string[];
  intune_evaluated_by_policy?: boolean;
  time_since_boot_in_days?: number;
  time_since_boot_evaluated_by_policy?: boolean;
  microsoft_word_version?: string;
  microsoft_word_uptodate?: boolean;
  microsoft_word_evaluated_by_policy?: boolean;
  microsoft_excel_version?: string;
  microsoft_excel_uptodate?: boolean;
  microsoft_excel_evaluated_by_policy?: boolean;
  microsoft_powerpoint_version?: string;
  microsoft_powerpoint_uptodate?: boolean;
  microsoft_powerpoint_evaluated_by_policy?: boolean;
  microsoft_outlook_version?: string;
  microsoft_outlook_uptodate?: boolean;
  microsoft_outlook_evaluated_by_policy?: boolean;
  microsoft_teams_version?: string;
  microsoft_teams_uptodate?: boolean;
  microsoft_teams_evaluated_by_policy?: boolean;
  slack_version?: string;
  slack_uptodate?: boolean;
  slack_evaluated_by_policy?: boolean;
  zoom_version?: string;
  zoom_uptodate?: boolean;
  zoom_evaluated_by_policy?: boolean;
  skype_version?: string;
  skype_uptodate?: boolean;
  skype_evaluated_by_policy?: boolean;
  adobe_acrobat_reader_version?: string;
  adobe_acrobat_reader_uptodate?: boolean;
  adobe_acrobat_reader_evaluated_by_policy?: boolean;
  adobe_acrobat_standard_or_pro_version?: string;
  adobe_acrobat_standard_or_pro_uptodate?: boolean;
  adobe_acrobat_standard_or_pro_evaluated_by_policy?: boolean;
  foxit_reader_version?: string;
  foxit_reader_uptodate?: boolean;
  foxit_reader_evaluated_by_policy?: boolean;
  sumatra_pdf_version?: string;
  sumatra_pdf_uptodate?: boolean;
  sumatra_pdf_evaluated_by_policy?: boolean;
  nitro_pdf_reader_version?: string;
  nitro_pdf_reader_uptodate?: boolean;
  nitro_pdf_reader_evaluated_by_policy?: boolean;
  jailbroken?: boolean;
  jailbroken_evaluated_by_policy?: boolean;
  rooted?: boolean;
  rooted_evaluated_by_policy?: boolean;
  developer_mode?: boolean;
  developer_mode_evaluated_by_policy?: boolean;
  secure_boot_enabled?: boolean;
  secure_boot_enabled_evaluated_by_policy?: boolean;
  remote_login_enabled?: boolean;
  remote_login_enabled_evaluated_by_policy?: boolean;
  remote_management_enabled?: boolean;
  remote_management_enabled_evaluated_by_policy?: boolean;
  screen_sharing_enabled?: boolean;
  screen_sharing_enabled_evaluated_by_policy?: boolean;
  remote_scripting_enabled?: boolean;
  remote_scripting_enabled_evaluated_by_policy?: boolean;
  remote_login?: boolean;
  remote_login_evaluated_by_policy?: boolean;
  remote_management?: boolean;
  remote_management_evaluated_by_policy?: boolean;
  screen_sharing?: boolean;
  screen_sharing_evaluated_by_policy?: boolean;
  remote_scripting?: boolean;
  remote_scripting_evaluated_by_policy?: boolean;
  integrity_protection_enabled?: boolean;
  integrity_protection_enabled_evaluated_by_policy?: boolean;
  passwordmanager?: PasswordManager;
  diskencryption_supported?: boolean;
  diskencryption_active?: boolean;
  nativeclient_installed?: boolean;
  authentication_active?: boolean;
  biometrics?: string;
  managed?: boolean;
  xfa_version?: string;
  merged_device_ids?: string[];
  merged_into_device_id?: string;
  email: string;
  connect?: boolean;
  skip?: boolean;
  unsupported?: boolean;
  discovered?: boolean;
  google_mobile_device_ids?: string[];
  google_cloud_identity_device_ids?: string[];
  microsoft_device_ids?: string[];
  okta_discovered?: boolean;
  timestamp_last_notified: number;
  os_cves?: CVE[];
  browser_cves?: CVE[];
  chrome_cves?: CVE[];
  firefox_cves?: CVE[];
  edge_cves?: CVE[];
  safari_cves?: CVE[];
}

export type VulnerabilityCategory = "Data Breach" | "Phishing" | "Ransomware";

export interface CvssData {
  accessComplexity?: string;
  accessVector?: string;
  authentication?: string;
  availabilityImpact?: string;
  baseScore?: number;
  confidentialityImpact?: string;
  integrityImpact?: string;
  vectorString?: string;
  exploitabilityScore?: number;
  impactScore?: number;
  privilegesRequired?: string;
  scope?: string;
  userInteraction?: string;
}

export interface CvssMetricV2 {
  acInsufInfo?: boolean;
  baseSeverity?: string;
  cvssData?: CvssData;
  exploitabilityScore?: number;
  impactScore?: number;
  obtainAllPrivilege?: boolean;
  obtainOtherPrivilege?: boolean;
  obtainUserPrivilege?: boolean;
  source?: string;
  type?: string;
  userInteractionRequired?: boolean;
}

export interface CvssMetricV30 {
  cvssData?: CvssData;
  exploitabilityScore?: number;
  impactScore?: number;
  source?: string;
  type?: string;
}

export interface CvssMetricV31 {
  cvssData?: CvssData;
  exploitabilityScore?: number;
  impactScore?: number;
  source?: string;
  type?: string;
}

export interface CvssMetricV40 {
  cvssData?: CvssData;
  source?: string;
  type?: string;
}

export interface Metrics {
  cvssMetricV2?: CvssMetricV2[];
  cvssMetricV30?: CvssMetricV30[];
  cvssMetricV31?: CvssMetricV31[];
  cvssMetricV40?: CvssMetricV40[];
}

export interface ConfigurationCpeMatch {
  criteria?: string;
  matchCriteriaId?: string;
  versionEndExcluding?: string;
  versionEndIncluding?: string;
  versionStartExcluding?: string;
  versionStartIncluding?: string;
  vulnerable?: boolean;
}

export interface ConfigurationNode {
  cpeMatch?: ConfigurationCpeMatch[];
  negate?: boolean;
  operator?: string;
}

export interface Configuration {
  negate?: boolean;
  nodes?: ConfigurationNode[];
  operator?: string;
}

export interface CveTag {
  sourceIdentifier?: string;
  tags?: string[];
}

export interface Description {
  lang?: string;
  value?: string;
}

export interface Reference {
  source?: string;
  tags?: string[];
  url?: string;
}

export interface VendorComment {
  comment?: string;
  lastModified?: string | Date;
  organization?: string;
}

export interface WeaknessDescription {
  lang?: string;
  value?: string;
}

export interface Weakness {
  description?: WeaknessDescription[];
  source?: string;
  type?: string;
}

export interface Vulnerability {
  [key: string]: unknown;
}

export interface CVE {
  categories?: VulnerabilityCategory[];
  cisaActionDue?: string;
  cisaExploitAdd?: string;
  cisaRequiredAction?: string;
  cisaVulnerabilityName?: string;
  configurations?: Configuration[];
  cveTags?: CveTag[];
  descriptions?: Description[];
  evaluatorComment?: string;
  evaluatorImpact?: string;
  evaluatorSolution?: string;
  id?: string;
  lastModified?: string | Date;
  metrics?: Metrics;
  published?: string | Date;
  references?: Reference[];
  sourceIdentifier?: string;
  vendorComments?: VendorComment[];
  vulnStatus?: string;
  weaknesses?: Weakness[];
  kev?: Vulnerability;
  softwareName?: string;
  tag?: string;
}

export interface ComputerName {
  name: string;
}

export interface UserInformation {
  username?: string;
  full_name?: string;
}

export interface PasswordManager {
  lastpass?: boolean;
  onepassword?: boolean;
  dashlane?: boolean;
  bitwarden?: boolean;
  keepass?: boolean;
  nordpass?: boolean;
  protonpass?: boolean;
  icloud_passwords?: boolean;
  dropbox_passwords?: boolean;
  passbolt?: boolean;
  enpass?: boolean;
  avast_passwords?: boolean;
  avira_passwords?: boolean;
}

export interface Role {
  email: string;
  roleId: string;
  roleType: string;
  organization: Organization;
  intercom_hmac: string;
  blocked_access: boolean;
}

export interface Organization {
  organization_id: string;
  name: string;
  address: string;
  zip_code: string;
  city: string;
  country: string;
  vat: string;
  billing_email: string;
  alpha_features?: boolean;
  beta_features?: boolean;
  logo_url?: string;
  notify_no_managed_devices?: boolean;
  notify_no_desktop_devices?: boolean;
  notify_no_mobile_devices?: boolean;
  notify_discovered_devices?: boolean;
  notify_discovered_devices_frequency_in_days?: number;
  notify_unsafe_devices?: boolean;
  notify_unsafe_devices_frequency_in_days?: number;
  notify_only_groups?: ExternalGroup[];
  default_policy_id?: string;
}

export interface FullOrganization {
  organization_id: string;
  name: string;
  address: string;
  zip_code: string;
  city: string;
  country: string;
  vat: string;
  billing_email: string;
  deleted?: boolean;
  active_users: ActiveUsers;
  settings: BillingSettings;
  invoice: Invoice;
  logo_url?: string;
  notify_no_managed_devices?: boolean;
  notify_no_desktop_devices?: boolean;
  notify_no_mobile_devices?: boolean;
  notify_discovered_devices?: boolean;
  notify_discovered_devices_frequency_in_days?: number;
  notify_unsafe_devices?: boolean;
  notify_unsafe_devices_frequency_in_days?: number;
  notify_only_groups?: ExternalGroup[];
  default_policy_id?: string;
  currentMRR: number;
  contractedMRR: number;
  potentialMRR: number;
  currentMRRLastMonth: number;
  contractedMRRLastMonth: number;
  potentialMRRLastMonth: number;
  timestamp: number;
}

export interface ActiveUsers {
  current_insight: number;
  last_insight: number;
  current_connect: number;
  last_connect: number;
  current_user_count: number;
  last_user_count: number;
}

export interface Invitation {
  timestamp: string;
}

export interface User {
  organization_id: string;
  email: string;
  invitations?: Invitation[];
  devices?: Device[];
  role: string;
}

export interface DashboardUser {
  organization_id: string;
  email: string;
  timestamp: string;
  notifications_weekly_report: boolean;
  notifications_email_reporting: boolean;
  notifications_email_marketing: boolean;
  notifications_email_product_updates: boolean;
  notifications_email_product_assistance: boolean;
  notifications_admin_preview_device_risk: boolean;
}

export interface Invoice {
  Number: number;
  OrganizationID: string;
  Date: string;
  Users: number;
  ConnectUsers: number;
  Amount: number;
  Status: string;
  Timestamp: number;
}

export interface Application {
  Enabled?: boolean;
  ApplicationID: string;
  PolicyID?: string;
  Name: string;
  IconUrl?: string;
  OrganizationID: string;
  Policies: Policies;
  TestKey?: string;
  AllowedApplicationDomainRegexes?: string[];
  PublicCerts?: string[];
  SAMLConfiguration?: SAMLConfiguration;
  OAuthConfiguration?: OAuthConfiguration;
  RedirectURL?: string;
  ClientSecret?: string;
  SSOUrl?: string;
  Issuer?: string;
  SAMLMetadataUrl: string;
  FilteredEmail?: string[];
  FilterMobile?: boolean;
  FilterDesktop?: boolean;
  Type?: string;
  UniquePurpose?: string;
  GoogleConnection?: GoogleConnection;
  MicrosoftConnection?: MicrosoftConnection;
  OktaConnection?: OktaConnection;
  EnableMfa?: boolean;
  EnableMfaAutoApproveFirstDevice?: boolean;
  AlwaysVerifyMfa?: boolean;
}

export interface ApplicationCreationRequest extends Application {
  Code?: string;
}

export interface Policies {
  name?: string;
  policy_id: string;
  organization_id: string;
  installed?: boolean;
  unsupported?: boolean;
  skip?: boolean;
  skip_mobile?: boolean;
  skip_desktop?: boolean;
  os?: VersionPolicy;
  os_autoupdate?: EnabledPolicy;
  browser?: VersionPolicy;
  disk_encryption?: EnabledPolicy;
  screen_lock?: EnabledPolicy;
  antivirus?: EnabledPolicy;
  password_manager?: EnabledPolicy;
  biometrics?: EnabledPolicy;
}

export interface VersionPolicy {
  WarnTime?: number;
  BlockTime?: number;
  older_supported_major_versions?: DisabledPolicy;
  beta_versions?: DisabledPolicy;
}

export interface EnabledPolicy {
  warn?: boolean;
  block?: boolean;
}

export interface DisabledPolicy {
  dont_warn?: boolean;
  dont_block?: boolean;
}

export class DefaultPolicies implements Policies {
  policy_id: string = "";
  organization_id: string = "";
  skip: boolean = true;
  unsupported: boolean = true;
  installed: boolean = true;
  os: VersionPolicy = {
    WarnTime: 0,
  };
  browser: VersionPolicy = {
    WarnTime: 0,
  };
  os_autoupdate: EnabledPolicy = {
    warn: true,
  };
  disk_encryption: EnabledPolicy = {
    warn: true,
  };
  screen_lock: EnabledPolicy = {
    warn: true,
  };
  antivirus: EnabledPolicy = {
    warn: true,
  };
}

export interface SAMLConfiguration {
  Enabled: boolean;
  ServiceProviderAssertionConsumerServiceURL: string;
  IdentityProviderSSOURL: string;
  IdentityProviderIssuer: string;
  IdentityProviderPublicCertificate: string;
  SignResponse: boolean;
  FactorOnly?: boolean;
  FactorOnlyAudience?: string;
  ForceReauthentication?: boolean;
  PreventPassiveLogin?: boolean;
  AzureForceAccountChooser?: boolean;
  GoogleForceAccountChooser?: boolean;
}

export interface OAuthConfiguration {
  Enabled: boolean;
  RedirectURLToClient?: string;
  TokenEndpointOfProvider?: string;
}

export interface Count {
  count: number;
  connect_count: number;
}

export type Currency = "USD" | "EUR" | "GBP";

export function getCurrencySymbol(currency: Currency | undefined): string {
  switch (currency) {
    case "USD":
      return "$";
    case "GBP":
      return "£";
    default:
      return "€";
  }
}

export type Frequency = "daily" | "weekly" | "monthly";

export interface PaymentPlanDetails {
  Name: string;
  NrOfPolicies: number;
  NrOfDiscovery: number;
  AwarenessVerificationFrequency: Frequency;
  AwarenessRiskFrequency: Frequency;
  NrOfEnforcement: number;
  CustomIntegration: boolean;
  AdminSecurityReports: Frequency;
  Cves: boolean;
  Mfa: boolean;
  AdvancedMfa: boolean;
  ExportToGRC: boolean;
}

export interface BillingSettings {
  OrganizationID: string;
  InsightPrice: number;
  ConnectPrice: number;
  FreeDevices: number;
  TrialEndDate: string;
  EndDate?: string;
  PerUserPrice?: number;
  BlockedAccess?: boolean;
  TotalYearlyPrice?: number;
  NrOfYearlyLicenses: number;
  BillExtraLicenses: boolean;
  StartDate?: string;
  Partner?: string;
  PayingCustomer: boolean;
  PaymentPlan: PaymentPlanDetails;
  Currency: Currency;
  PaymentTermInDays: number;
  PONumber?: string;
}

export interface GoogleConnection {
  Scopes: string[];
  Email?: string;
  ErrorMobileDevicesAPI?: string;
  ErrorCloudIdentityAPI?: string;
  OnlyUnmanaged?: boolean; //Only discover unmanaged devices
  OnlyGroups?: GoogleGroup[]; //Only discover devices from users in these groups (no groups means all groups)
  ExcludeDesktop?: boolean; //exclude desktop devices
  ExcludeMobile?: boolean; //exclude mobile devices
}

export interface MicrosoftConnection {
  Scopes: string[];
  Error?: string;
  OnlyUnmanaged?: boolean; //Only discover unmanaged devices
  OnlyGroups?: MicrosoftGroup[]; //Only discover devices from users in these groups (no groups means all groups)
  ExcludeDesktop?: boolean; //exclude desktop devices
  ExcludeMobile?: boolean; //exclude mobile devices
}

export interface OktaConnection {
  OktaDomain: string;
  ClientID: string;
  ClientSecret: string;
}

// GoogleGroup holds the name and id of a group to filter discovered devices
export interface GoogleGroup {
  Name: string;
  ID: string;
}

// GoogleGroup holds the name and id of a group to filter discovered devices
export interface ExternalGroup {
  name: string;
  id: string;
}

// MicrosoftGroup holds the name and id of a group to filter discovered devices
export interface MicrosoftGroup {
  Name: string;
  ID: string;
}

export interface ConnectionError {
  GoogleMobileDevicsAPIError?: string;
  GoogleCloudIdentityAPIError?: string;
  MicrosoftAPIError?: string;
}

export interface MfaDevice {
  deviceId: string;
  id: string;
}

export interface ExternalUser {
  id: string;
  email: string;
  "first-name": string;
  "last-name": string;
}

export interface Counter {
  organization_id: string;
  date: string;
  type: string;
  warned: number;
  blocked: number;
  verification_email: number;
  risk_email: number;
}

export interface Transaction {
  application?: Application;
  decisions?: Decisions;
  detectedServiceProviderName?: string;
  device_id?: string;
  device_id_from_browser?: string;
  organization?: Organization;
  redirectUrl?: string;
  status: TransactionStatus;
  suggestManualRedirect?: boolean;
  suggestManualRedirectOnIOS?: boolean;
  transaction_id: string;
  ttl: number;
  user: User;
  verification?: Verification;
}

export interface Verification {
  DeviceID?: string; // DeviceID is the deviceID of the device that is being verified (can be discovered)
  SuggestedOSName?: string;
  Policies?: Policies;
}

export interface DecisionAffiliated {
  // Status status of decision
  status: DecisionAffiliatedStatus;
}

// DecisionAffiliatedStatus status of decision
export type DecisionAffiliatedStatus = string;

// DecisionEnabled defines model for DecisionEnabled.
export interface DecisionEnabled {
  // Status status of decision
  status: DecisionEnabledStatus;
}

// DecisionEnabledStatus status of decision
export type DecisionEnabledStatus = string;

// DecisionInstalled defines model for DecisionInstalled.
export interface DecisionInstalled {
  // Status status of decision
  status: DecisionInstalledStatus;
}

// DecisionInstalledStatus status of decision
export type DecisionInstalledStatus = string;

// DecisionMfa defines model for DecisionMfa.
export interface DecisionMfa {
  // AutoApprove will MFA be auto approved
  autoApprove?: boolean;

  // AutoApproved is MFA auto approved
  autoApproved?: boolean;
  credentials?: {
    credentials?: Credential[];
    displayName?: string;
    id?: string;
    name?: string;
    userId?: string;
  };
  error?: string;

  // Status status of decision
  status: DecisionMfaStatus;
}

// DecisionMfaStatus status of decision
export type DecisionMfaStatus = string;

// DecisionPasswordmanager defines model for DecisionPasswordmanager.
export interface DecisionPasswordmanager {
  // Status status of decision
  status: DecisionPasswordmanagerStatus;
}

// DecisionPasswordmanagerStatus status of decision
export type DecisionPasswordmanagerStatus = string;

// DecisionSkip defines model for DecisionSkip.
export interface DecisionSkip {
  // Allowed is skipping allowed or not
  allowed: boolean;
}

// DecisionUnsupported defines model for DecisionUnsupported.
export interface DecisionUnsupported {
  // Allowed is unsupported allowed or not
  allowed: boolean;
}

// DecisionVersion defines model for DecisionVersion.
export interface DecisionVersion {
  // LatestVersion the latest version
  latestVersion: string;

  // Status status of decision
  status: DecisionVersionStatus;
  timeSinceOutdated: number;
  timeTillBlocked?: number;
}

// DecisionVersionStatus status of decision
export type DecisionVersionStatus = string;

// Decisions defines model for Decisions.
export interface Decisions {
  affiliated?: DecisionAffiliated;
  antivirus?: DecisionEnabled;
  betaBrowserVersion?: DecisionEnabled;
  betaOsVersion?: DecisionEnabled;
  biometrics?: DecisionEnabled;
  browserUpToDate?: DecisionVersion;
  diskEncryption?: DecisionEnabled;
  installed?: DecisionInstalled;
  mfa?: DecisionMfa;
  olderSupportedBrowserVersion?: DecisionEnabled;
  olderSupportedOsVersion?: DecisionEnabled;
  osAutoupdate?: DecisionEnabled;
  osUpToDate?: DecisionVersion;
  passwordmanager?: DecisionPasswordmanager;
  screenlock?: DecisionEnabled;
  skip?: DecisionSkip;
  unsupported?: DecisionUnsupported;
}

export type TransactionStatus =
  | "PENDING"
  | "COMPLETED"
  | "BLOCKED"
  | "GRANTED"
  | "SKIPPED"
  | "FILTERED"
  | "UNSUPPORTED";

const XFA_API = {
  getAllDevices: async () => {
    try {
      const result = await API.get("XFA_API", `devices`, {});
      if (result) {
        if (result.devices) {
          return result.devices as Device[];
        } else {
          return [] as Device[];
        }
      }
      console.log("getAllDevices call not successful");
    } catch (err) {
      console.log(err);
    }
    return undefined;
  },
  getDevices: async (organizationId: string, policyId?: string | undefined) => {
    try {
      const result = await API.get(
        "XFA_API",
        `devices?organization_id=${organizationId}${policyId ? `&policyId=${policyId}` : ""}`,
        {},
      );
      if (result) {
        if (result.devices) {
          return result.devices as Device[];
        } else {
          return [] as Device[];
        }
      }
      console.log("getAllDevices call not successful");
    } catch (err) {
      console.log(err);
    }
    return undefined;
  },
  getDevicesForUser: async (
    organizationID: string,
    email: string,
    policyId?: string | undefined,
  ) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationID}/devices?email=${email}${policyId ? `&policyId=${policyId}` : ""}`,
        {},
      );

      if (result) {
        return result as Device[];
      } else {
        return [] as Device[];
      }
    } catch (err) {
      throw new Error(
        `Sending verification request for devices associated with ${email} not successful`,
      );
    }
  },
  getGroupedDevices: async (
    organizationId: string,
    startKey: string | undefined,
    policyId?: string | undefined,
    withCves?: boolean,
  ): Promise<
    { shadows: Shadow[]; lastEvaluatedKey: string | undefined } | undefined
  > => {
    try {
      const result = await API.get(
        "XFA_API",
        `devices/grouped?organization_id=${organizationId}${
          startKey ? `&exclusive_start_key=${startKey}` : ""
        }${policyId ? `&policyId=${policyId}` : ""}${
          withCves ? "&add_cves=true" : ""
        }`,
        {},
      );
      if (result) {
        if (result.shadows) {
          const shadows: Shadow[] = Object.keys(result.shadows).map(
            (email) => ({
              email,
              devices: result.shadows[email],
            }),
          );
          return { shadows, lastEvaluatedKey: result.last_evaluated_key };
        } else {
          return { shadows: [], lastEvaluatedKey: undefined };
        }
      }
      throw new Error(
        withCves
          ? "getGroupedDevices call with CVEs not successful"
          : "getGroupedDevices call not successful",
      );
    } catch (err) {
      console.error(err);
      throw new Error(
        withCves
          ? "Failed to get grouped devices with CVEs"
          : "Failed to get grouped devices",
      );
    }
  },
  getDeviceCount: async (organizationId: string, date: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `devices/count?organization_id=${organizationId}&date=${date}`,
        {},
      );
      if (result) {
        return result as Count;
      }
      return {
        count: 0,
        connect_count: 0,
      } as Count;
    } catch (err) {
      throw Error("Could not retrieve device count");
    }
  },
  getDeviceCountCustomTimeframe: async (
    organizationId: string,
    startDate: string,
    endDate: string | undefined,
    onlyShadows: boolean,
  ) => {
    try {
      let queryParams = `start_date_unix=${startDate}`;
      if (endDate) {
        queryParams += `&end_date_unix=${endDate}`;
      }
      const result = await API.get(
        "XFA_API",
        `devices/count?organization_id=${organizationId}&${queryParams}&only_shadows=${onlyShadows}`,
        {},
      );
      if (result) {
        return result as Count;
      }
      return {
        count: 0,
        connect_count: 0,
      } as Count;
    } catch (err) {
      throw Error("Could not retrieve device count");
    }
  },
  getUserCount: async (organizationId: string, date: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `devices/count-users?organization_id=${organizationId}&date=${date}`,
        {},
      );
      if (result) {
        return result as Count;
      }
      return {
        count: 0,
      } as Count;
    } catch (err) {
      throw Error("Could not retrieve user count");
    }
  },
  getUserCountCustomTimeframe: async (
    organizationId: string,
    startDate: string,
    endDate: string | undefined,
    onlyShadows: boolean,
  ) => {
    try {
      let queryParams = `start_date_unix=${startDate}`;
      if (endDate) {
        queryParams += `&end_date_unix=${endDate}`;
      }
      const result = await API.get(
        "XFA_API",
        `devices/count-users?organization_id=${organizationId}&${queryParams}&only_shadows=${onlyShadows}`,
        {},
      );
      if (result) {
        return result as Count;
      }
      return {
        count: 0,
      } as Count;
    } catch (err) {
      throw Error("Could not retrieve user count");
    }
  },
  getRoles: async () => {
    try {
      const result = await API.get("XFA_API", `roles`, {});
      if (result) {
        return result as Role[];
      } else {
        return [] as Role[];
      }
    } catch (err) {
      console.log(err);
    }
  },
  createOrganization: async (
    name: string,
  ): Promise<Organization | undefined> => {
    try {
      const result = await API.post("XFA_API", `organizations`, {
        body: { name: name },
      });

      if (result) {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();

        const refreshedSession = await new Promise((resolve, reject) => {
          cognitoUser.refreshSession(
            currentSession.getRefreshToken(),
            (err: any, session: any) => {
              if (err) {
                console.error(err);
                reject(err);
              } else {
                resolve(session);
              }
            },
          );
        });

        return result as Organization;
      }
      return undefined;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  },
  getCounters: async (organizationId: string): Promise<Counter[]> => {
    const days = calculateDaysSinceFiveMonthsAgoStart(new Date());
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationId}/counters?number_of_days=${days}`,
        {},
      );
      if (result) {
        return result as Counter[];
      }
      return [];
    } catch (err) {
      console.error(err);
      return [];
    }
  },
  getOrganizations: async () => {
    try {
      const result = await API.get("XFA_API", `organizations`, {});
      if (result) {
        return result as FullOrganization[];
      }
      console.log("retrieving organizations call not successful");
    } catch (err) {
      console.log(err);
    }
  },
  getOrganizationFull: async (organizationId: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationId}/full`,
        {},
      );
      if (result) {
        return result as FullOrganization;
      }
      console.log("retrieving organization call not successful");
    } catch (err) {
      throw Error("Retrieving organization not succesfull");
    }
  },
  updateOrganization: async (organizationId: string, org: Organization) => {
    try {
      await API.put("XFA_API", `organizations/${organizationId}`, {
        body: org,
      });
    } catch (err) {
      throw Error("Updating organization not succesfull");
    }
  },
  inviteUser: async (organizationID: string, email: string) => {
    try {
      const result = await API.post("XFA_API", `invitations`, {
        body: { organization_id: organizationID, email: email },
      });
      if (result) {
        return;
      }
      throw Error("Inviting user not successful");
    } catch (err) {
      throw Error("Inviting user not successful");
    }
  },
  resendInviteUser: async (organizationID: string, email: string) => {
    try {
      await API.put("XFA_API", `invitations`, {
        body: { organization_id: organizationID, email: email },
      });
    } catch (err) {
      throw Error("Re-inviting user not successful");
    }
  },
  getUsers: async (organizationID: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationID}/users`,
        {},
      );
      if (result) {
        if (result.users) {
          return result.users as User[];
        } else {
          return [] as User[];
        }
      }
      throw Error("Requesting users of organization not successful");
    } catch (err) {
      throw Error("Requesting users of organization not successful");
    }
  },
  getDashboardUsers: async (organizationID: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationID}/dashboard-users`,
        {},
      );
      if (result) {
        if (result) {
          return result as DashboardUser[];
        } else {
          return [] as DashboardUser[];
        }
      }
      throw Error("Requesting dashboard users of organization not successful");
    } catch (err) {
      throw Error("Requesting dashboard users of organization not successful");
    }
  },
  updateDashboardUser: async (organizationID: string, user: DashboardUser) => {
    try {
      const result = await API.put(
        "XFA_API",
        `organizations/${organizationID}/dashboard-users`,
        { body: user },
      );
    } catch (err) {
      throw Error("Updating dashboard user of organization not successful");
    }
  },
  deleteUser: async (
    organizationID: string,
    deviceIds: string[] | undefined,
    email: string,
  ) => {
    try {
      await API.del("XFA_API", `affiliations-admin`, {
        body: {
          organization_id: organizationID,
          device_ids: deviceIds,
          email: email,
        },
      });
    } catch (err) {
      throw Error("Deleting user not successful");
    }
  },
  inviteAdminUser: async (organizationID: string, email: string) => {
    try {
      await API.post("XFA_API", `organizations/${organizationID}/users`, {
        body: { invitee: email },
      });
    } catch (err) {
      throw Error("Inviting admin not successful");
    }
  },
  deleteAdminUser: async (organizationID: string, email: string) => {
    try {
      await API.del("XFA_API", `organizations/${organizationID}/users`, {
        body: { email: email },
      });
    } catch (err) {
      throw Error("Deleting admin not successful");
    }
  },
  getInvoices: async (organizationID: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationID}/invoices`,
        {},
      );
      if (result) {
        if (result.invoices) {
          return result.invoices as Invoice[];
        } else {
          return [] as Invoice[];
        }
      }
      throw Error("Requesting invoices of organization not successful");
    } catch (err) {
      throw Error("Requesting invoices of organization not successful");
    }
  },
  getInvoice: async (
    organizationID: string,
    date: string,
    invoiceNr: string,
  ) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationID}/invoices/${date}/${invoiceNr}`,
        {},
      );
      if (result) {
        return result;
      }
      throw Error("Requesting invoice not successful");
    } catch (err) {
      throw Error("Requesting invoice not successful");
    }
  },
  addPaymentMethod: async (
    organizationID: string,
    cardToken: string | undefined,
  ) => {
    const path = cardToken
      ? `payment-method/${organizationID}?cardToken=${cardToken}`
      : `payment-method/${organizationID}`;
    try {
      const result = await API.post("XFA_API", path, {});
      if (result) {
        return result.Checkout;
      }
      throw Error("Adding payment method not successful");
    } catch (err) {
      throw Error("Adding payment method not successful");
    }
  },
  updatePaymentMethod: async (organizationID: string) => {
    try {
      const result = await API.put(
        "XFA_API",
        `payment-method/${organizationID}`,
        {},
      );
      if (result) {
        return result.Checkout;
      }
      throw Error("Updating payment method not successful");
    } catch (err) {
      throw Error("Updating payment method not successful");
    }
  },
  getPaymentMethod: async (organizationID: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `payment-method/${organizationID}`,
        {},
      );
      if (result) {
        return result;
      }
      throw Error("Getting payment method not successful");
    } catch (err) {
      throw Error("Getting payment method not successful");
    }
  },
  getApplications: async (organizationID: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationID}/applications`,
        {},
      );
      if (result) {
        if (result.Applications) {
          return result.Applications as Application[];
        } else {
          return [] as Application[];
        }
      }
      throw Error("Requesting applications of organization not successful");
    } catch (err) {
      throw Error("Requesting applications of organization not successful");
    }
  },
  getDashboardDemo: async (organizationID: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationID}/applications/dashboard-demo`,
        {},
      );
      if (result) {
        return result as Application;
      }
      throw Error("Requesting dashboard demo application not successful");
    } catch (err) {
      throw Error("Requesting dashboard application demo not successful");
    }
  },
  createApplication: async (
    organizationID: string,
    application: Application,
  ) => {
    try {
      if (
        application.Policies === undefined &&
        application.Type !== "Google" &&
        application.Type !== "Microsoft" &&
        application.Type !== "OktaDiscovery"
      ) {
        application.Policies = new DefaultPolicies();
      }

      const result = await API.post(
        "XFA_API",
        `organizations/${organizationID}/applications`,
        {
          body: application,
        },
      );
      if (result) {
        return result;
      }
      throw Error("Creating application not successful");
    } catch (err) {
      throw Error("Creating application not successful");
    }
  },
  updateApplication: async (application: Application) => {
    try {
      const result = await API.put(
        "XFA_API",
        `organizations/${application.OrganizationID}/applications/${application.ApplicationID}`,
        { body: application },
      );
      if (result) {
        return result;
      }
      throw Error("Updating application not successful");
    } catch (err) {
      throw Error("Updating application not successful");
    }
  },
  deleteApplication: async (application: Application) => {
    try {
      await API.del(
        "XFA_API",
        `organizations/${application.OrganizationID}/applications/${application.ApplicationID}`,
        {},
      );
    } catch (err) {
      throw Error("Deleting application not successful");
    }
  },
  connectVanta: async (
    code: string,
    organizationID: string,
    redirectUri: string,
  ) => {
    try {
      await API.post("XFA_API", `connect/vanta`, {
        body: {
          code: code,
          organization_id: organizationID,
          redirect_uri: redirectUri,
        },
      });
    } catch (err) {
      throw Error("Connecting Vanta not successful");
    }
  },
  connectThoropass: async (
    code: string,
    code_verifier: string,
    organizationID: string,
    redirectUri: string,
  ) => {
    try {
      await API.post("XFA_API", `connect/thoropass`, {
        body: {
          code: code,
          code_verifier: code_verifier,
          organization_id: organizationID,
          redirect_uri: redirectUri,
        },
      });
    } catch (err) {
      throw Error("Connecting Thoropass not successful");
    }
  },
  getBillingSettings: async (
    organizationID: string,
  ): Promise<BillingSettings> => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationID}/billing-settings`,
        {},
      );
      if (result) {
        return result as BillingSettings;
      }
      throw Error("Getting billing settings not successful");
    } catch (err) {
      throw Error("Getting billing settings not successful");
    }
  },
  updateBillingSettings: async (
    organizationID: string,
    billingSettings: BillingSettings,
  ): Promise<void> => {
    try {
      const result = await API.post(
        "XFA_API",
        `organizations/${organizationID}/billing-settings`,
        {
          body: billingSettings,
        },
      );
    } catch (err) {
      throw Error("Getting billing settings not successful");
    }
  },
  connectApplication: async (
    organizationID: string,
    applicationID: string,
    code: string,
    redirectUri: string,
  ) => {
    try {
      const result = await API.post(
        "XFA_API",
        `organizations/${organizationID}/applications/${applicationID}/connect`,
        {
          body: {
            Code: code,
            RedirectURI: redirectUri,
          },
        },
      );
      if (result) {
        return result as ConnectionError;
      }
    } catch (err) {
      throw Error("Connecting application not successful");
    }
  },
  getFreeReport: async (
    code: string,
    redirectUri: string,
    type: string,
    email?: string,
  ) => {
    try {
      const result = await API.post(
        "XFA_API_UNAUTHORIZED",
        `single-discovery`,
        {
          body: {
            code: code,
            redirect_uri: redirectUri,
            type: type,
            email: email,
          },
        },
      );
      if (result) {
        return result as ConnectionError;
      }
    } catch (err: any) {
      console.log("error creating free report: ", err);
      console.log("error code: ", err.code);
      throw Error(
        "Connecting organization for free report was not successful. Try again or contact support.",
      );
    }
  },
  triggerDiscovery: async (organizationID: string, applicationID: string) => {
    try {
      const result = await API.post(
        "XFA_API",
        `organizations/${organizationID}/applications/${applicationID}/trigger-discovery`,
        {},
      );

      if (result) {
        return result as ConnectionError;
      }
    } catch (err) {
      console.error(err);
      throw Error("Triggering discovery not successful");
    }
  },
  getGroupsDiscoveryIntegration: async (
    organizationID: string,
    applicationID: string,
  ) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationID}/applications/${applicationID}/groups`,
        {},
      );

      if (result) {
        return result;
      }
    } catch (err) {
      console.error(err);
    }
  },
  getUsersGroupsDiscoveryIntegration: async (
    organizationID: string,
    applicationID: string,
    groupID: string,
  ) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationID}/applications/${applicationID}/groups/${groupID}/users`,
        {},
      );

      if (result) {
        return result as ExternalUser[];
      }
    } catch (err) {
      console.error(err);
    }
  },
  getMfaDevices: async (organizationID: string, email: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationID}/users/${email}/credential`,
        {},
      );
      if (result) {
        return (result as MfaDevice[]).map((device) => device.deviceId);
      }
      throw Error("Getting mfa devices not successful");
    } catch (err) {
      throw Error("Getting mfa devices not successful");
    }
  },
  deleteMfa: async (
    organizationID: string,
    deviceID: string | undefined,
    email: string,
  ) => {
    try {
      let path =
        deviceID == undefined
          ? `organizations/${organizationID}/users/${email}`
          : `organizations/${organizationID}/users/${email}/credential/${deviceID}`;
      await API.del("XFA_API", path, {});
    } catch (err) {
      if ((err as { response: { status: number } }).response.status == 404) {
        console.error(err);
        return;
      }
      throw Error("Deleting mfa not successful");
    }
  },
  deleteOrganization: async (organizationID: string) => {
    try {
      await API.del("XFA_API", `organizations/${organizationID}`, {});
    } catch (err) {
      throw Error("Deleting organization not successful");
    }
  },
  createPolicy: async (organizationId: string, policy: Policies) => {
    try {
      const result = await API.post(
        "XFA_API",
        `organizations/${organizationId}/policies`,
        {
          body: policy,
        },
      );
      if (result) {
        return result;
      }
      throw Error("Creating policy not successful");
    } catch (err) {
      throw Error("Creating policy not successful");
    }
  },
  getPolicies: async (organizationId: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationId}/policies`,
        {},
      );
      if (result) {
        return result as Policies[];
      } else {
        return [];
      }
    } catch (err) {
      throw Error("Retrieving policies not successful");
    }
  },
  getPolicy: async (organizationId: string, policyId: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `organizations/${organizationId}/policies/${policyId}`,
        {},
      );
      if (result) {
        return result as Policies;
      }
      throw Error("Retrieving policy not successful");
    } catch (err) {
      throw Error("Retrieving policy not successful");
    }
  },
  updatePolicy: async (
    organizationId: string,
    policyId: string,
    policy: Policies,
  ) => {
    try {
      await API.put(
        "XFA_API",
        `organizations/${organizationId}/policies/${policyId}`,
        {
          body: policy,
        },
      );
    } catch (err) {
      throw Error("Updating policy not successful");
    }
  },
  deletePolicy: async (organizationId: string, policyId: string) => {
    try {
      await API.del(
        "XFA_API",
        `organizations/${organizationId}/policies/${policyId}`,
        {},
      );
    } catch (err) {
      throw Error("Deleting policy not successful");
    }
  },
  sendVerificationEmail: async (
    organizationID: string,
    deviceID: string,
    policyId?: string,
  ) => {
    try {
      let url = `organizations/${organizationID}/devices/${deviceID}/send-verification-request`;
      if (policyId) {
        url += `?policyId=${policyId}`;
      }
      await API.post("XFA_API", url, {});
    } catch (err) {
      throw Error("Sending verification email not successful");
    }
  },
  sendRiskEmail: async (
    organizationID: string,
    deviceID: string,
    policyId?: string,
  ) => {
    try {
      let url = `organizations/${organizationID}/devices/${deviceID}/send-risk-request`;
      if (policyId) {
        url += `?policyId=${policyId}`;
      }
      await API.post("XFA_API", url, {});
    } catch (err) {
      console.error(err);
      throw Error("Sending Risk email not successful ");
    }
  },
  sendVerificationRequestForUser: async (
    organizationID: string,
    email: string,
    policyId?: string,
  ) => {
    try {
      let url = `organizations/${organizationID}/devices?email=${email}`;
      if (policyId) {
        url += `&policyId=${policyId}`;
      }
      const response = await API.get("XFA_API", url, {});

      const devices = response as Device[];
      await Promise.all(
        getRecentDevices(devices).map((device) => {
          if (device.os_name) {
            XFA_API.sendVerificationEmail(organizationID, device.device_id);
          }
        }),
      );
    } catch (err) {
      throw new Error(
        `Sending verification request for devices associated with ${email} not successful`,
      );
    }
  },
  sendRiskRequestForUser: async (
    organizationID: string,
    email: string,
    policyId?: string,
  ) => {
    try {
      let url = `organizations/${organizationID}/devices?email=${email}`;
      if (policyId) {
        url += `&policyId=${policyId}`;
      }

      const response = await API.get("XFA_API", url, {});

      const devices = response as Device[];

      await Promise.all(
        getRecentDevices(devices).map((device) => {
          if (device.os_name) {
            XFA_API.sendRiskEmail(organizationID, device.device_id);
          }
        }),
      );
    } catch (err) {
      throw new Error(
        `Sending verification request for devices associated with ${email} not successful`,
      );
    }
  },
  getTransactions: async (organizationID: string) => {
    try {
      const result = await API.get(
        "XFA_API",
        `transactions?organizationId=${organizationID}`,
        {},
      );
      if (result) {
        return (result as Transaction[])
          .map(mapCamelToSnake)
          .map((transaction) => {
            console.log(transaction.application);
            if (transaction.application && transaction.application.name) {
              transaction.application.Name = transaction.application.name;
              delete transaction.application.name;
            }
            return transaction;
          });
      }
      return [];
    } catch (err) {
      throw Error("Getting transactions not successful");
    }
  },
  approveTransaction: async (organizationID: string, transactionID: string) => {
    try {
      await API.put(
        "XFA_API",
        `transactions/${transactionID}?organizationId=${organizationID}`,
        {},
      );
    } catch (err) {
      throw Error("Approving transaction not successful");
    }
  },
};

export default XFA_API;

const mapCamelToSnake = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(mapCamelToSnake);
  } else if (obj !== null && typeof obj === "object") {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        key.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase(), // Convert camelCase to snake_case
        mapCamelToSnake(value),
      ]),
    );
  }
  return obj;
};
