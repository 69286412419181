import React from "react";
import ContentLoader from "react-content-loader";

const SkeletonLoader = () => (
  <div className={`risk-card skeleton`}>
    <ContentLoader
      speed={0.7}
      backgroundColor="#CCD0D6"
      foregroundColor="#ecebeb"
    >
      <path d="M 20 30.611 a 6.611 6.611 0 100 -13.222 6.611 6.611 a 0 0 13.222 z" />
      <path d="M 20 25.5 a 1.5 1.5 0 100 -3 1.5 1.5 a 0 0 3 z" />
      <path d="M 20 30.611 a 6.611 6.611 0 100 -13.222 6.611 6.611 a 0 0 13.222 z" />
      <path d="M 20 25.5 a 1.5 1.5 0 100 -3 1.5 1.5 a 0 0 3 z" />
      <path d="M 364.191 48 H 15.809 C 13.153 48 11 52.395 11 53.5 v 0.5 c 0 1.105 2.153 6 4.809 6 h 348.382 c 2.656 0 4.809 -3.895 4.809 -5 v -1.5 c 0 -1.105 -2 -4.5 -4.809 -5.5 z M 260.615 66 H 15.385 C 13.515 66 12 71.395 12 72.5 c 0 1.105 1.515 5.5 3.385 5.5 h 245.23 c 1.869 0 3.385 -4.895 3.385 -6 c 0 -1.105 -1.516 -6 -3.385 -6 z" />
      <path d="M 314 17 H 43 a 7 7 0 100 14 h 271 a 7 7 0 100 -14 z" />
      <path d="M 362.875 48 H 17.125 a 6.125 6.125 0 0 12.25 h 345.75 a 6.125 6.125 0 100 -12.25 z M 258.826 66 H 17.174 C 13.764 66 11 68.686 11 72 s 2.764 6 6.174 6 h 241.652 c 3.41 0 6.174 -2.686 6.174 -6 s -2.764 -6 -6.174 -6 z" />
      <path d="M 448.667 88 H 14 a 6 6 0 0 -6 6 v 24 a 6 6 0 6 6 h 434.667 a 6 6 0 6 -6 V 94 a 6 6 0 0 -6 -6 z" />
      <path d="M 428.875 100 H 44.125 a 6.125 6.125 0 100 12.25 h 384.75 a 6.125 6.125 0 100 -12.25 z" />
      <path d="M 448.667 128 H 14 a 6 6 0 0 -6 6 v 24 a 6 6 0 6 6 h 434.667 a 6 6 0 6 -6 v -24 a 6 6 0 0 -6 -6 z" />
      <path d="M 25.75 143 h -3.5 a 3.25 3.25 0 100 6.501 h 3.5 a 3.25 3.25 0 100 -6.501 z M 252.875 140 H 46.125 a 6.125 6.125 0 100 12.25 h 206.75 a 6.125 6.125 0 100 -12.25 z M 25.75 103 h -3.5 a 3.25 3.25 0 100 6.501 h 3.5 a 3.25 3.25 0 100 -6.501 z" />
    </ContentLoader>
  </div>
);

export default SkeletonLoader;
