import React from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  ButtonBase,
  Chip,
  CircularProgress,
  Typography,
} from "@mui/material";
import "./OrganizationBilling.css";
import XFA_API, {
  BillingSettings,
  Invoice,
  Organization,
  Role,
  getCurrencySymbol,
} from "../../API/XFA_API";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Download } from "@mui/icons-material";
import BillingAddress from "./BillingAddress";
import CheckIcon from "@mui/icons-material/Check";
import BillingPlan from "./BillingPlan";
import Usage from "../../Statistics/Usage";

interface OrganizationBillingProps {
  role: Role;
  onSaveBillingAddress: (org: Organization) => void;
}

export function openBase64NewTab(base64Pdf: string): void {
  const blob = base64toBlob(base64Pdf);
  const blobUrl = URL.createObjectURL(blob);
  window.open(blobUrl);
}

function base64toBlob(base64Data: string) {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: "application/pdf" });
}

const OrganizationBilling: React.FC<OrganizationBillingProps> = (
  props: OrganizationBillingProps,
) => {
  const [loadInProgress, setLoadInProgress] = React.useState<boolean>(true);
  const [downloadInvoiceInProgress, setDownloadInvoiceInProgress] =
    React.useState<number | undefined>(undefined);
  const [invoices, setInvoices] = React.useState<Invoice[]>([]);
  const [error, setError] = React.useState<string>("");
  const [settingsLoading, setSettingsLoading] = React.useState<boolean>(true);
  const [settings, setSettings] = React.useState<BillingSettings>();

  const refreshBillingPlan = React.useCallback(async () => {
    try {
      setSettingsLoading(true);
      const billingSettings = await XFA_API.getBillingSettings(
        props.role.organization.organization_id,
      );
      setSettings(billingSettings);
      setSettingsLoading(false);
    } catch (error) {
      setSettingsLoading(false);
    }
  }, [props.role.organization.organization_id]);

  React.useEffect(() => {
    refreshBillingPlan();
  }, [refreshBillingPlan]);

  const { t } = useTranslation();

  const refreshInvoices = React.useCallback(async () => {
    try {
      const invoices = await XFA_API.getInvoices(
        props.role.organization.organization_id,
      );
      setLoadInProgress(false);
      setInvoices(invoices);
    } catch (error) {
      console.log(error);
    }
  }, [props.role.organization.organization_id]);

  React.useEffect(() => {
    refreshInvoices();
  }, [refreshInvoices]);

  return (
    <div>
      <div className="billingPapers">
        <Usage role={props.role} />
        <Paper
          className="billingPaperContainer billingPaperWithTitle"
          style={{ paddingTop: 20 }}
        >
          <BillingPlan
            role={props.role}
            settings={settings}
            settingsLoading={settingsLoading}
          />
        </Paper>
        <Paper
          className="billingPaperContainer billingPaperWithTitle"
          style={{ paddingTop: 20 }}
        >
          <BillingAddress
            role={props.role}
            onSave={props.onSaveBillingAddress}
          />
        </Paper>
      </div>

      <Typography variant="h5" color="primary">
        {t("Billing.invoicesTitle")}
      </Typography>
      <div className="table w-full" data-cy="users">
        {error && (
          <div style={{ marginBottom: 15 }}>
            <Alert severity="error">{error}</Alert>
          </div>
        )}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ background: "var(--color-gray-100)" }}>
              <TableRow>
                <TableCell>{t("Billing.invoiceNumber")}</TableCell>
                <TableCell>{t("Billing.date")}</TableCell>
                <TableCell>{t("Billing.invoiceDate")}</TableCell>
                <TableCell>{t("Billing.Amount")}</TableCell>
                <TableCell>{t("Billing.Status")}</TableCell>
                <TableCell>{t("Billing.File")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices &&
                invoices
                  .sort((a, b) => (a.Timestamp < b.Timestamp ? 1 : -1))
                  .map((invoice: Invoice, index: number) => (
                    <TableRow className="stripedRows" key={invoice.Date}>
                      <TableCell component="th" scope="row">
                        {invoice.Number}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {invoice.Date.length === 4 
                          ? invoice.Date 
                          : t("months." + invoice.Date.substring(4, 6)) + " " + invoice.Date.substring(0, 4)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {new Date(invoice.Timestamp * 1000).toLocaleDateString(
                          "nl-BE",
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {getCurrencySymbol(settings?.Currency)} {invoice.Amount}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {invoice.Status !== "" && invoice.Status === "paid" ? (
                          <Chip
                            icon={<CheckIcon />}
                            color="success"
                            variant="outlined"
                            label={t("Billing.paidStatus")}
                          />
                        ) : (
                          <Chip
                            color="error"
                            variant="outlined"
                            label={t("Billing.openStatus")}
                          />
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <ButtonBase
                          onClick={(e) => {
                            setDownloadInvoiceInProgress(invoice.Number);
                            XFA_API.getInvoice(
                              props.role.organization.organization_id,
                              invoice.Date,
                              String(invoice.Number),
                            ).then((result) => {
                              setDownloadInvoiceInProgress(undefined);
                              openBase64NewTab(result);
                            });
                          }}
                          disabled={
                            downloadInvoiceInProgress === invoice.Number
                          }
                        >
                          {downloadInvoiceInProgress === invoice.Number && (
                            <CircularProgress
                              size="20px"
                              style={{ marginRight: "10px" }}
                              color="primary"
                            />
                          )}
                          <Download color="primary" />
                        </ButtonBase>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          {error && (
            <Alert
              onClose={() => {
                setError("");
              }}
              severity="error"
            >
              {error}
            </Alert>
          )}
          <div style={{ textAlign: "center", paddingTop: 5 }}>
            {loadInProgress ? (
              <CircularProgress size={30} />
            ) : (
              invoices &&
              invoices.length === 0 && <span>{t("Billing.noInvoices")}</span>
            )}
          </div>
        </TableContainer>
      </div>
    </div>
  );
};

export default OrganizationBilling;
