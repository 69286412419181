import React from "react";
import { useTranslation } from "react-i18next";
import {
  TextField,
  Button,
  CircularProgress,
  Typography,
  DialogTitle,
  DialogActions,
  Dialog,
  DialogContent,
  Alert,
} from "@mui/material";
import XFA_API, { Organization, Role } from "../../API/XFA_API";
import ConfirmationDialog from "../../Dialogs/ConfirmationDialog";

interface BillingAddressProps {
  role: Role;
  onSave: (org: Organization) => void;
}

interface PaymentMethodObj {
  Account: string;
  Bic: string;
  CardExpiryDate: string;
  CardHolder: string;
  CardLabel: string;
  CardNumber: string;
  Date: string;
  Name: string;
  OrganizationID: string;
  PaymentProviderCustomerID: string;
  Type: string;
}

const updateOrganization = async (orgId: string, org: Organization) => {
  await XFA_API.updateOrganization(orgId, org);
};

const BillingAddress: React.FC<BillingAddressProps> = (
  props: BillingAddressProps,
) => {
  const [updateInProgress, setUpdateInProgress] =
    React.useState<boolean>(false);
  const [orgName, setOrgName] = React.useState<string>(
    props.role.organization.name,
  );
  const [orgAddress, setOrgAddress] = React.useState<string>(
    props.role.organization.address,
  );
  const [orgZipCode, setOrgZipCode] = React.useState<string>(
    props.role.organization.zip_code,
  );
  const [orgCity, setOrgCity] = React.useState<string>(
    props.role.organization.city,
  );
  const [orgCountry, setOrgCountry] = React.useState<string>(
    props.role.organization.country,
  );
  const [orgVat, setOrgVat] = React.useState<string>(
    props.role.organization.vat,
  );
  const [orgBillingEmail, setOrgBillingEmail] = React.useState<string>(
    props.role.organization.billing_email,
  );
  const [editBillingAddress, setEditBillingAddress] =
    React.useState<boolean>(false);
  const [method, setMethod] = React.useState<PaymentMethodObj | undefined>(
    undefined,
  );
  const [methodLoading, setMethodLoading] = React.useState<boolean>(true);
  const [confirmChangePaymentMethod, setConfirmChangePaymentMethod] =
    React.useState<boolean>(false);
  const [changePaymentMethodInProgress, setChangePaymentMethodInProgress] =
    React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");

  const refreshPaymentMethod = React.useCallback(async () => {
    try {
      setMethodLoading(true);
      const paymentMethod = await XFA_API.getPaymentMethod(
        props.role.organization.organization_id,
      );
      setMethod(paymentMethod);
      setMethodLoading(false);
    } catch (error) {
      setMethodLoading(false);
    }
  }, [props.role.organization.organization_id]);

  React.useEffect(() => {
    refreshPaymentMethod();
  }, [refreshPaymentMethod]);

  const { t } = useTranslation();

  function onCancel() {
    setOrgName(props.role.organization.name);
    setOrgAddress(props.role.organization.address);
    setOrgZipCode(props.role.organization.zip_code);
    setOrgCity(props.role.organization.city);
    setOrgCountry(props.role.organization.country);
    setOrgVat(props.role.organization.vat);
    setOrgBillingEmail(props.role.organization.billing_email);
    setEditBillingAddress(false);
  }

  return (
    <div>
      <Typography className="paperTitle" color="primary">
        {t("Billing.billingAddressTitle")}
      </Typography>
      <Typography className="paperContent">
        {props.role.organization.name}
      </Typography>
      <Typography className="paperContent">
        {props.role.organization.address}
      </Typography>
      <Typography className="paperContent">
        {props.role.organization.zip_code} {props.role.organization.city}
      </Typography>
      <Typography className="paperContent">
        {props.role.organization.country}
      </Typography>
      <Typography className="paperContent">
        {props.role.organization.vat}
      </Typography>
      <Typography className="paperContent">
        {props.role.organization.billing_email}
      </Typography>
      <Button
        className={"editButton"}
        variant={"contained"}
        type={"button"}
        color={"primary"}
        disableElevation
        size="small"
        onClick={() => {
          setEditBillingAddress(true);
        }}
      >
        {t("Billing.editButton")}
      </Button>

      <Typography
        className="paperTitle"
        color="primary"
        style={{ marginTop: 24 }}
      >
        {t("Billing.paymentMethodTitle")}
      </Typography>
      {error && (
        <div style={{ marginBottom: 15 }}>
          <Alert severity="error">{error}</Alert>
        </div>
      )}
      {method && !methodLoading ? (
        method.Type === "creditcard" ? (
          <>
            <Typography className="paperContent">
              {method.CardLabel} {t("Billing.ending")} {method.CardNumber}
            </Typography>
            <Typography className="paperContent">
              {t("Billing.expiring")} {method.CardExpiryDate}
            </Typography>
          </>
        ) : (
          <>
            <Typography className="paperContent">{method.Account}</Typography>
            <Typography className="paperContent">{method.Bic}</Typography>
          </>
        )
      ) : methodLoading ? (
        <div>
          <CircularProgress
            size="20px"
            style={{ marginRight: "10px" }}
            color="primary"
          />
        </div>
      ) : (
        <></>
      )}
      <Button
        className={"editButton"}
        variant={"contained"}
        type={"button"}
        color={"primary"}
        disableElevation
        size="small"
        onClick={() => {
          setConfirmChangePaymentMethod(true);
        }}
      >
        {method
          ? t("Billing.editPaymentMethod")
          : t("Billing.addPaymentMethod")}
      </Button>

      {confirmChangePaymentMethod && (
        <ConfirmationDialog
          title={
            method
              ? t("Billing.changeMethodDialogTitle")
              : t("Billing.addMethodDialogTitle")
          }
          content={
            method
              ? t("Billing.changeMethodDialogContent")
              : t("Billing.addMethodDialogContent")
          }
          cancelText={t("Users.delete.cancel")}
          confirmText={t("Users.delete.confirm")}
          inProgress={changePaymentMethodInProgress}
          onCancel={() => {
            setConfirmChangePaymentMethod(false);
            setChangePaymentMethodInProgress(false);
          }}
          onConfirm={() => {
            setChangePaymentMethodInProgress(true);
            if (method) {
              XFA_API.updatePaymentMethod(
                props.role.organization.organization_id,
              )
                .then((checkoutUrl) => {
                  setConfirmChangePaymentMethod(false);
                  setChangePaymentMethodInProgress(false);
                  window.location.href = checkoutUrl;
                })
                .catch(() => {
                  setConfirmChangePaymentMethod(false);
                  setChangePaymentMethodInProgress(false);
                  setError("Something went wrong, try again later");
                });
            } else {
              XFA_API.addPaymentMethod(
                props.role.organization.organization_id,
                undefined,
              )
                .then((checkoutUrl) => {
                  setConfirmChangePaymentMethod(false);
                  setChangePaymentMethodInProgress(false);
                  window.location.href = checkoutUrl;
                })
                .catch(() => {
                  setConfirmChangePaymentMethod(false);
                  setChangePaymentMethodInProgress(false);
                  setError("Something went wrong, try again later");
                });
            }
          }}
        />
      )}

      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: "80%" } }}
        maxWidth="xs"
        open={editBillingAddress}
        onClose={onCancel}
      >
        <DialogTitle>{t("Billing.editDialogTitle")}</DialogTitle>
        <DialogContent>
          <form id="billingAddress">
            <div className="field">
              <TextField
                label={t("organization.settings.name")}
                variant="outlined"
                fullWidth
                value={orgName}
                onChange={(event) => {
                  setOrgName(event.target.value);
                }}
                autoComplete="orgName"
                autoFocus
                required
              />
            </div>
            <div className="field">
              <TextField
                label={t("organization.settings.address")}
                variant="outlined"
                fullWidth
                value={orgAddress}
                onChange={(event) => {
                  setOrgAddress(event.target.value);
                }}
                autoComplete="orgAddress"
              />
            </div>
            <div className="field">
              <TextField
                label={t("organization.settings.zipCode")}
                variant="outlined"
                fullWidth
                value={orgZipCode}
                onChange={(event) => {
                  setOrgZipCode(event.target.value);
                }}
                autoComplete="orgZipCode"
              />
            </div>
            <div className="field">
              <TextField
                label={t("organization.settings.city")}
                variant="outlined"
                fullWidth
                value={orgCity}
                onChange={(event) => {
                  setOrgCity(event.target.value);
                }}
                autoComplete="orgCity"
              />
            </div>
            <div className="field">
              <TextField
                label={t("organization.settings.country")}
                variant="outlined"
                fullWidth
                value={orgCountry}
                onChange={(event) => {
                  setOrgCountry(event.target.value);
                }}
                autoComplete="orgCountry"
              />
            </div>
            <div className="field">
              <TextField
                label={t("organization.settings.vat")}
                variant="outlined"
                fullWidth
                value={orgVat}
                onChange={(event) => {
                  setOrgVat(event.target.value);
                }}
                autoComplete="orgVAT"
              />
            </div>
            <div className="field">
              <TextField
                label={t("organization.settings.email")}
                variant="outlined"
                fullWidth
                value={orgBillingEmail}
                onChange={(event) => {
                  setOrgBillingEmail(event.target.value);
                }}
                autoComplete="orgBillingEmail"
              />
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{t("Users.delete.cancel")}</Button>
          <Button
            variant={"contained"}
            type={"button"}
            color={"primary"}
            disableElevation
            size="large"
            onClick={() => {
              setUpdateInProgress(true);
              const newOrg: Organization = {
                organization_id: props.role.organization.organization_id,
                name: orgName,
                address: orgAddress,
                zip_code: orgZipCode,
                city: orgCity,
                country: orgCountry,
                vat: orgVat,
                billing_email: orgBillingEmail,
              };
              updateOrganization(
                props.role.organization.organization_id,
                newOrg,
              ).then(() => {
                props.onSave(newOrg);
                setUpdateInProgress(false);
                setEditBillingAddress(false);
              });
            }}
            disabled={updateInProgress}
          >
            {updateInProgress && (
              <CircularProgress size="20px" style={{ marginRight: "10px" }} />
            )}
            {t("organization.settings.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BillingAddress;
