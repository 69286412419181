import React, { useContext } from "react";
import {
  Alert,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Tooltip,
} from "@mui/material";
import XFA_API, { Device, Policies } from "../API/XFA_API";
import DeviceStatusChip from "./DeviceStatusChip";
import {
  DeviceRisk,
  getDeviceStatus,
  getDeviceType,
  isMobile,
  isDesktop,
  getDeviceRisk,
  DeviceStatus,
  isDefined,
  getRelativeTime,
} from "./UserUtils";
import StatusChip from "./StatusChip";
import "./DeviceDetailsCard.css";
import GoogleIcon from "../Applications/NewOrEditPage/Google/google.svg";
import MicrosoftIcon from "../Applications/NewOrEditPage/Microsoft/microsoft.svg";
import OktaIcon from "../../images/okta-icon.png";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "../Dialogs/ConfirmationDialog";
import { RoleContext } from "../Home";
import MfaResetIcon from "../../images/mfa_reset.svg";
import { PasswordManagerCheck } from "../Devices/Checks";
import { parseOS } from "../Devices/OS";

import DiscoveryIcon from "../../icons/discovery.svg";
import WarningIcon from "../../icons/WarningIcon.svg";
import UnsupportedIcon from "../../icons/UnsupportedIcon.svg";
import DropdownMenu from "../General/Dropdown/DropdownMenu";
import mailIcon from "../../images/mail-icon.png";
import { getDeviceCheck } from "./CheckUtils";

interface DeviceDetailsCardProps {
  device: Device;
  policy?: Policies | undefined;
  mfaEnabled: boolean;
  onDelete: () => void;
}

const DeviceDetailsCard: React.FC<DeviceDetailsCardProps> = ({
  device,
  policy,
  mfaEnabled,
  onDelete,
}) => {
  const deviceStatus = getDeviceStatus(device);
  const deviceRisk = getDeviceRisk(device, policy);
  const { t } = useTranslation();
  const activeRole = useContext(RoleContext);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [mfaInProgress, setMfaInProgress] = React.useState<boolean>(false);
  const [mailSent, setMailSent] = React.useState<boolean>(false);
  const [sendingMail, setSendingMail] = React.useState<boolean>(false);
  const [deleteDeviceInProgress, setDeleteDeviceInProgress] =
    React.useState<boolean>(false);
  const [confirmMfaReset, setConfirmMfaReset] = React.useState<
    | {
        deviceId: string;
        email: string;
      }
    | undefined
  >(undefined);
  const [confirmDeleteDevice, setConfirmDeleteDevice] = React.useState<
    | {
        deviceId: string;
        email: string;
        organizationId: string;
        deviceName: string;
      }
    | undefined
  >(undefined);

  const statusChips = [
    {
      key: "osUpToDate",
      checkLabel: t("devices.OSUpToDate"),
      risk: getDeviceCheck(device, "os_uptodate"),
      enforced: policy?.os,
      tooltip: device.os_version && `Version: ${device.os_version}`,
      show: true,
    },
    {
      key: "osAutoUpdate",
      checkLabel: t("devices.OSAutoUpdate"),
      risk: getDeviceCheck(device, "os_autoupdate"),
      enforced: policy?.os_autoupdate,
      show: isDesktop(device.os_name),
    },
    {
      key: "diskEncryption",
      checkLabel: t("devices.DiskEncryption"),
      risk: getDeviceCheck(device, "diskencryption_active"),
      enforced: policy?.disk_encryption,
      show: true,
    },
    {
      key: "authentication",
      checkLabel: t("devices.Authentication"),
      risk: getDeviceCheck(device, "authentication_active"),
      enforced: policy?.screen_lock,
      show: true,
    },
    {
      key: "biometrics",
      checkLabel: t("devices.Biometrics"),
      risk: getDeviceCheck(device, "biometrics"),
      enforced: policy?.biometrics,
      show: isMobile(device.os_name),
    },
    {
      key: "antivirus",
      checkLabel: t("devices.Antivirus"),
      risk: getDeviceCheck(device, "antivirus_enabled"),
      enforced: policy?.antivirus,
      show: isDesktop(device.os_name),
    },
    {
      key: "chromeVersion",
      checkLabel: "Chrome",
      risk: getDeviceCheck(device, "chrome_uptodate"),
      enforced: policy?.browser,
      tooltip: device.chrome_version && `Version: ${device.chrome_version}`,
      show: isDesktop(device.os_name) && device.chrome_version,
    },
    {
      key: "firefoxVersion",
      checkLabel: "Firefox",
      risk: getDeviceCheck(device, "firefox_uptodate"),
      enforced: policy?.browser,
      tooltip: device.firefox_version && `Version: ${device.firefox_version}`,
      show: isDesktop(device.os_name) && device.firefox_version,
    },
    {
      key: "edgeVersion",
      checkLabel: "Edge",
      risk: getDeviceCheck(device, "edge_uptodate"),
      enforced: policy?.browser,
      tooltip: device.edge_version && `Version: ${device.edge_version}`,
      show: isDesktop(device.os_name) && device.edge_version,
    },
    {
      key: "safariVersion",
      checkLabel: "Safari",
      risk: getDeviceCheck(device, "safari_uptodate"),
      enforced: policy?.browser,
      tooltip: device.safari_version && `Version: ${device.safari_version}`,
      show: isDesktop(device.os_name) && device.safari_version,
    },
    {
      key: "chromeSafeBrowsing",
      checkLabel: t("devices.ChromeSafeBrowsing"),
      risk: getDeviceCheck(device, "chrome_safebrowsing_enabled"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      show:
        isDesktop(device.os_name) &&
        activeRole?.organization.alpha_features &&
        device.chrome_version,
    },
    {
      key: "chromeSafeBrowsingEnhanced",
      checkLabel: t("devices.ChromeSafeBrowsingEnhanced"),
      risk: getDeviceCheck(device, "chrome_safebrowsing_enhanced"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      show:
        isDesktop(device.os_name) &&
        activeRole?.organization.alpha_features &&
        device.chrome_version,
    },
    {
      key: "chromeDoNotTrack",
      checkLabel: t("devices.ChromeDoNotTrack"),
      risk: getDeviceCheck(device, "chrome_do_not_track"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      show:
        isDesktop(device.os_name) &&
        activeRole?.organization.alpha_features &&
        device.chrome_version,
    },
    {
      key: "firefoxHttpsOnly",
      checkLabel: t("devices.FirefoxHttpsOnly"),
      risk: getDeviceCheck(device, "firefox_https_only"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      show:
        isDesktop(device.os_name) &&
        activeRole?.organization.alpha_features &&
        device.firefox_version,
    },
    {
      key: "firefoxDnsOverHttps",
      checkLabel: t("devices.FirefoxDnsOverHttps"),
      risk: getDeviceCheck(device, "firefox_dns_over_https"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      show:
        isDesktop(device.os_name) &&
        activeRole?.organization.alpha_features &&
        device.firefox_version,
    },
    {
      key: "firefoxEnhancedTrackingProtection",
      checkLabel: t("devices.FirefoxEnhancedTrackingProtection"),
      risk: getDeviceCheck(device, "firefox_enhanced_tracking_protection"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      show:
        isDesktop(device.os_name) &&
        activeRole?.organization.alpha_features &&
        device.firefox_version,
    },
    {
      key: "firefoxEnhancedTrackingProtectionStrict",
      checkLabel: t("devices.FirefoxEnhancedTrackingProtectionStrict"),
      risk: getDeviceCheck(
        device,
        "firefox_enhanced_tracking_protection_strict",
      ),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      show:
        isDesktop(device.os_name) &&
        activeRole?.organization.alpha_features &&
        device.firefox_version,
    },
    {
      key: "firewall",
      checkLabel: t("devices.Firewall"),
      risk: getDeviceCheck(device, "firewall_enabled"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      show:
        isDesktop(device.os_name) && activeRole?.organization.alpha_features,
    },
    {
      key: "findMyDevice",
      checkLabel: t("devices.FindMyDevice"),
      risk: getDeviceCheck(device, "findmydevice_enabled"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      show:
        isDefined(device.os_name) &&
        ["Windows", "macOS"].includes(device.os_name!) &&
        activeRole?.organization.alpha_features,
    },
    {
      key: "vantaClientRunning",
      checkLabel: t("devices.VantaClientRunning"),
      risk: getDeviceCheck(device, "vanta_client_running"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      show:
        isDesktop(device.os_name) &&
        device.vanta_client_running === true &&
        activeRole?.organization.alpha_features,
    },
    {
      key: "intuneEnrolled",
      checkLabel: t("devices.IntuneEnrolled"),
      risk: getDeviceCheck(device, "intune_enrolled"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      show:
        isDesktop(device.os_name) &&
        device.intune_enrolled === true &&
        activeRole?.organization.alpha_features,
    },
    {
      key: "timeSinceBoot",
      checkLabel: t("devices.TimeSinceBoot"),
      risk: getDeviceCheck(device, "time_since_boot_in_days"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      show: isDesktop(device.os_name),
    },
    {
      key: "microsoftWord",
      checkLabel: "Microsoft Word",
      risk: getDeviceCheck(device, "microsoft_word_uptodate"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      tooltip:
        device.microsoft_word_version &&
        `Version: ${device.microsoft_word_version}`,
      show: device.microsoft_word_version,
    },
    {
      key: "microsoftExcel",
      checkLabel: "Microsoft Excel",
      risk: getDeviceCheck(device, "microsoft_excel_uptodate"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      tooltip:
        device.microsoft_excel_version &&
        `Version: ${device.microsoft_excel_version}`,
      show: device.microsoft_excel_version,
    },
    {
      key: "microsoftPowerpoint",
      checkLabel: "Microsoft PowerPoint",
      risk: getDeviceCheck(device, "microsoft_powerpoint_uptodate"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      tooltip:
        device.microsoft_powerpoint_version &&
        `Version: ${device.microsoft_powerpoint_version}`,
      show: device.microsoft_powerpoint_version,
    },
    {
      key: "microsoftOutlook",
      checkLabel: "Microsoft Outlook",
      risk: getDeviceCheck(device, "microsoft_outlook_uptodate"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      tooltip:
        device.microsoft_outlook_version &&
        `Version: ${device.microsoft_outlook_version}`,
      show: device.microsoft_outlook_version,
    },
    {
      key: "microsoftTeams",
      checkLabel: "Microsoft Teams",
      risk: getDeviceCheck(device, "microsoft_teams_uptodate"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      tooltip:
        device.microsoft_teams_version &&
        `Version: ${device.microsoft_teams_version}`,
      show: device.microsoft_teams_version,
    },
    {
      key: "slack",
      checkLabel: "Slack",
      risk: getDeviceCheck(device, "slack_uptodate"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      tooltip: device.slack_version && `Version: ${device.slack_version}`,
      show: device.slack_version,
    },
    {
      key: "zoom",
      checkLabel: "Zoom",
      risk: getDeviceCheck(device, "zoom_uptodate"),
      enforced: false, // future: not yet present
      tooltip: device.zoom_version && `Version: ${device.zoom_version}`,
      show: device.zoom_version,
    },
    {
      key: "skype",
      checkLabel: "Skype",
      risk: getDeviceCheck(device, "skype_uptodate"),
      enforced: false, // future: not yet present
      tooltip: device.skype_version && `Version: ${device.skype_version}`,
      show: device.skype_version,
    },
    {
      key: "adobeAcrobatReader",
      checkLabel: "Adobe Acrobat Reader",
      risk: getDeviceCheck(device, "adobe_acrobat_reader_uptodate"),
      enforced: false, // future: not yet present
      tooltip:
        device.adobe_acrobat_reader_version &&
        `Version: ${device.adobe_acrobat_reader_version}`,
      show: device.adobe_acrobat_reader_version,
    },
    {
      key: "adobeAcrobatStandardOrPro",
      checkLabel: "Adobe Acrobat Standard/Pro",
      risk: getDeviceCheck(device, "adobe_acrobat_standard_or_pro_uptodate"),
      enforced: false, // future: not yet present
      tooltip:
        device.adobe_acrobat_standard_or_pro_version &&
        `Version: ${device.adobe_acrobat_standard_or_pro_version}`,
      show: device.adobe_acrobat_standard_or_pro_version,
    },
    {
      key: "foxitReader",
      checkLabel: "Foxit Reader",
      risk: getDeviceCheck(device, "foxit_reader_uptodate"),
      enforced: false, // future: not yet present
      tooltip:
        device.foxit_reader_version &&
        `Version: ${device.foxit_reader_version}`,
      show: device.foxit_reader_version,
    },
    {
      key: "sumatraPdf",
      checkLabel: "Sumatra PDF",
      risk: getDeviceCheck(device, "sumatra_pdf_uptodate"),
      enforced: false, // future: not yet present
      tooltip:
        device.sumatra_pdf_version && `Version: ${device.sumatra_pdf_version}`,
      show: device.sumatra_pdf_version,
    },
    {
      key: "nitroPdfReader",
      checkLabel: "Nitro PDF Reader",
      risk: getDeviceCheck(device, "nitro_pdf_reader_uptodate"),
      enforced: false, // future: not yet present
      tooltip:
        device.nitro_pdf_reader_version &&
        `Version: ${device.nitro_pdf_reader_version}`,
      show: device.nitro_pdf_reader_version,
    },
    {
      key: "jailbroken",
      checkLabel: t("devices.Jailbroken"),
      risk: getDeviceCheck(device, "jailbroken"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      show: device.os_name === "iOS" && activeRole?.organization.alpha_features,
    },
    {
      key: "rooted",
      checkLabel: t("devices.Rooted"),
      risk: getDeviceCheck(device, "rooted"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      show:
        device.os_name === "Android" && activeRole?.organization.alpha_features,
    },
    {
      key: "developerMode",
      checkLabel: t("devices.DeveloperMode"),
      risk: getDeviceCheck(device, "developer_mode"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      show:
        device.os_name === "Android" && activeRole?.organization.alpha_features,
    },
    {
      key: "secureBoot",
      checkLabel: t("devices.SecureBoot"),
      risk: getDeviceCheck(device, "secure_boot_enabled"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      show:
        isDesktop(device.os_name) && activeRole?.organization.alpha_features,
    },
    {
      key: "remoteLogin",
      checkLabel: t("devices.RemoteLogin"),
      risk: getDeviceCheck(device, "remote_login_enabled"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      show:
        isDesktop(device.os_name) && activeRole?.organization.alpha_features,
    },
    {
      key: "remoteManagement",
      checkLabel: t("devices.RemoteManagement"),
      risk: getDeviceCheck(device, "remote_management_enabled"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      show:
        isDesktop(device.os_name) && activeRole?.organization.alpha_features,
    },
    {
      key: "remoteScripting",
      checkLabel: t("devices.RemoteScripting"),
      risk: getDeviceCheck(device, "remote_scripting_enabled"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      show:
        isDesktop(device.os_name) && activeRole?.organization.alpha_features,
    },
    {
      key: "screenSharing",
      checkLabel: t("devices.ScreenSharing"),
      risk: getDeviceCheck(device, "screen_sharing_enabled"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      show:
        isDesktop(device.os_name) && activeRole?.organization.alpha_features,
    },
    {
      key: "integrityProtection",
      checkLabel: t("devices.IntegrityProtection"),
      risk: getDeviceCheck(device, "integrity_protection_enabled"),
      enforced: false, // future: not yet present in policy, can be refactored to read from object 'evaluated_by_policy'
      show:
        isDesktop(device.os_name) && activeRole?.organization.alpha_features,
    },
    {
      key: "nativeClientInstalled",
      checkLabel: t("devices.NativeClientInstalled"),
      risk: getDeviceCheck(device, "nativeClientInstalled"),
      enforced: policy?.installed === true,
      show: !isMobile(device.os_name) && !device.nativeclient_installed,
    },
  ];

  const enforcedChips = statusChips.filter(
    (chip) => chip.enforced && chip.show,
  );
  const nonEnforcedChips = statusChips.filter(
    (chip) => !chip.enforced && chip.show,
  );

  const getDetails = React.useCallback(() => {
    const details = [];
    const os = parseOS(
      device.os_name,
      device.os_version,
      device.os_flavour,
      device.android_security_level,
    );

    if (os !== undefined && os !== null && os !== "") {
      details.push(os);
    }

    if (
      device.manufacturer !== undefined &&
      device.manufacturer !== null &&
      device.manufacturer !== ""
    ) {
      details.push(device.manufacturer);
    }

    if (
      device.model !== undefined &&
      device.model !== null &&
      device.model !== ""
    ) {
      details.push(device.model);
    }

    return details.join(" - ");
  }, [device]);

  return (
    <Card
      variant="outlined"
      className="details-card"
      style={{
        borderStyle:
          deviceStatus === DeviceStatus.Unverified ? "dashed" : "solid",
        borderColor:
          deviceStatus === DeviceStatus.Unverified
            ? "var(--color-gray-400)"
            : undefined,
      }}
    >
      <CardContent className="details-card-content">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="details-title">
            {device.computer_name?.name || getDeviceType(device, t)}
            {device?.user?.username && <span> ({device?.user?.username})</span>}
          </div>
          <div className="status-chip-container">
            <StatusChip status={deviceRisk} />
          </div>
        </div>
        <div className="details-os">{getDetails()}</div>
        <Divider style={{ margin: "8px 0" }} />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "8px",
            marginTop: "4px",
          }}
        >
          {enforcedChips.map((chip) => (
            <DeviceStatusChip
              key={chip.key}
              status={deviceStatus}
              risk={chip.risk}
              label={chip.checkLabel}
              tooltip={chip.tooltip}
            />
          ))}

          {policy &&
            isDefined(policy.password_manager) &&
            isDesktop(device.os_name) && (
              <PasswordManagerCheck device={device} />
            )}
        </div>
        {nonEnforcedChips.length > 0 && (
          <>
            <div
              style={{
                fontWeight: "600",
                fontSize: "12px",
                margin: "16px 0px 4px",
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px",
                opacity: "0.5",
                color: "var(--color-gray-500)",
              }}
            >
              <Divider style={{ flex: 1 }} />
              {t("devices.notEnforcedByPolicy")}
              <Divider style={{ flex: 1 }} />
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "8px",
                opacity: 0.5,
              }}
            >
              {nonEnforcedChips.map((chip) => (
                <DeviceStatusChip
                  key={chip.key}
                  status={deviceStatus}
                  risk={chip.risk}
                  label={chip.checkLabel}
                  tooltip={chip.tooltip}
                />
              ))}
              {!isDefined(policy?.password_manager) &&
                isDesktop(device.os_name) && (
                  <PasswordManagerCheck device={device} />
                )}
            </div>
          </>
        )}

        <div className="details">
          <div className="details-last-active-container">
            <div>
              <div className="details-last-active-title">
                {t("devices.TimestampSubmitted")}:{" "}
              </div>
              <div className="details-last-active">
                {getRelativeTime(device.timestamp_submitted * 1000, t)}
              </div>
            </div>
          </div>
          {device.timestamp_last_notified && (
            <div
              className="details-last-active-container"
              style={{ marginRight: "auto", marginLeft: "32px" }}
            >
              <div>
                <div className="details-last-active-title">
                  {t("devices.lastNotified")}:{" "}
                </div>
                <div className="details-last-active">
                  {getRelativeTime(device.timestamp_last_notified * 1000, t)}
                </div>
              </div>
            </div>
          )}

          <div className="reset-mfa" style={{ display: "flex" }}>
            {mailSent && (
              <div style={{ margin: "auto" }}>
                {t("devices.verificationEmailSent")}
              </div>
            )}
            {sendingMail && (
              <div style={{ width: "100%", display: "flex" }}>
                <CircularProgress
                  style={{
                    margin: "auto",
                    height: 20,
                    width: 20,
                  }}
                />
              </div>
            )}
            <DropdownMenu
              mode="dropdown"
              actions={[
                ...(activeRole?.organization?.organization_id && mfaEnabled
                  ? [
                      {
                        label: "devices.resetMfa",
                        icon: (
                          <img
                            src={MfaResetIcon}
                            alt="Reset MFA"
                            className="icon"
                          />
                        ),
                        onClick: () =>
                          setConfirmMfaReset({
                            deviceId: device.device_id,
                            email: device.email,
                          }),
                      },
                    ]
                  : []),
                ...(getDeviceStatus(device) === DeviceStatus.Unverified &&
                device.os_name
                  ? [
                      {
                        label: "devices.verificationEmail",
                        icon: (
                          <img src={mailIcon} alt="Resend" className="icon" />
                        ),
                        onClick: () => {
                          setSendingMail(true);
                          XFA_API.sendVerificationEmail(
                            activeRole?.organization.organization_id!,
                            device.device_id,
                            policy?.policy_id,
                          )
                            .then(() => {
                              setMailSent(true);
                              setTimeout(() => {
                                //remove confirmation message after 3 seconds
                                setMailSent(false);
                              }, 3000);
                            })
                            .catch((e) => {
                              setError(e.message);
                            })
                            .finally(() => {
                              setSendingMail(false);
                            });
                        },
                      },
                    ]
                  : []),
                ...(getDeviceRisk(device, policy) !== DeviceRisk.Safe &&
                getDeviceStatus(device) === DeviceStatus.Verified &&
                device.os_name
                  ? [
                      {
                        label: "devices.riskEmail",
                        icon: (
                          <img src={mailIcon} alt="Resend" className="icon" />
                        ),
                        onClick: () => {
                          setSendingMail(true);
                          XFA_API.sendRiskEmail(
                            activeRole?.organization.organization_id!,
                            device.device_id,
                            policy?.policy_id,
                          )
                            .then(() => {
                              setMailSent(true);
                              setTimeout(() => {
                                //remove confirmation message after 3 seconds
                                setMailSent(false);
                              }, 3000);
                            })
                            .catch((e) => {
                              setError(e.message);
                            })
                            .finally(() => {
                              setSendingMail(false);
                            });
                        },
                      },
                    ]
                  : []),
                {
                  label: "devices.removeDevice",
                  icon: (
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon-delete"
                    >
                      <path
                        d="M15 6.75L13.5037 15.2597C13.3777 15.977 12.7546 16.5 12.0265 16.5H5.97355C5.24534 16.5 4.62232 15.977 4.49622 15.2597L3 6.75"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.75 4.5H11.5312M11.5312 4.5V3C11.5312 2.17157 10.8597 1.5 10.0312 1.5H7.96875C7.14032 1.5 6.46875 2.17157 6.46875 3V4.5M11.5312 4.5H6.46875M2.25 4.5H6.46875"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ),
                  onClick: () => {
                    setConfirmDeleteDevice({
                      deviceName:
                        device.computer_name?.name || getDeviceType(device, t),
                      deviceId: device.device_id,
                      email: device.email,
                      organizationId:
                        activeRole?.organization?.organization_id!,
                    });
                  },
                  delete: true,
                },
              ]}
            />
          </div>
        </div>
        {error && (
          <Alert
            onClose={() => {
              setError(undefined);
            }}
            severity="error"
          >
            {error}
          </Alert>
        )}
      </CardContent>
      {confirmMfaReset !== undefined && (
        <ConfirmationDialog
          title={t("devices.mfa.confirmTitle") + confirmMfaReset.email}
          cancelText={t("devices.mfa.cancel")}
          confirmText={t("devices.mfa.confirm")}
          inProgress={mfaInProgress}
          onCancel={() => {
            setConfirmMfaReset(undefined);
          }}
          onConfirm={() => {
            setMfaInProgress(true);
            XFA_API.deleteMfa(
              activeRole!.organization.organization_id,
              confirmMfaReset?.deviceId,
              confirmMfaReset?.email,
            )
              .catch((e) => {
                setError(e.message);
              })
              .finally(() => {
                setMfaInProgress(false);
                setConfirmMfaReset(undefined);
              });
          }}
        />
      )}
      {confirmDeleteDevice !== undefined && (
        <ConfirmationDialog
          title={
            t("devices.deleteDeviceTitle") + confirmDeleteDevice.deviceName
          }
          cancelText={t("devices.mfa.cancel")}
          confirmText={t("devices.mfa.confirm")}
          inProgress={deleteDeviceInProgress}
          onCancel={() => {
            setConfirmDeleteDevice(undefined);
          }}
          onConfirm={async () => {
            setDeleteDeviceInProgress(true);
            if (activeRole?.organization?.organization_id && mfaEnabled) {
              await XFA_API.deleteMfa(
                activeRole!.organization.organization_id,
                confirmDeleteDevice?.deviceId,
                confirmDeleteDevice?.email,
              ).catch((e) => {
                setError(e.message);
              });
            }

            XFA_API.deleteUser(
              activeRole!.organization.organization_id,
              [confirmDeleteDevice?.deviceId],
              confirmDeleteDevice?.email,
            )
              .then(() => {
                onDelete();
              })
              .catch((e) => {
                setError(e.message);
              })
              .finally(() => {
                setDeleteDeviceInProgress(false);
                setConfirmDeleteDevice(undefined);
              });
          }}
        />
      )}
      <UnverifiedLabel device={device} />
    </Card>
  );
};

function managedDevice(device: Device) {
  const { t } = useTranslation();

  return device.managed === true ? (
    <Tooltip title={t("devices.managedTooltip")}>
      <span className="flex align-center" style={{ marginLeft: "auto" }}>
        {t("devices.managed")}
        <img
          src={MicrosoftIcon}
          style={{ height: "18px", width: "18px", marginLeft: 6 }}
        />
      </span>
    </Tooltip>
  ) : null;
}

function UnverifiedLabel({ device }: { device: Device }) {
  const deviceStatus = getDeviceStatus(device);

  const { t } = useTranslation();

  if (deviceStatus === DeviceStatus.Unverified || device.managed === true) {
    return (
      <div className="unverified-label">
        {device.discovered === true ? (
          <>
            <Tooltip title={t("devices.deviceDiscoveredTooltip")}>
              <span className="flex align-center">
                <img
                  src={DiscoveryIcon}
                  style={{ width: 18, height: 18, marginRight: 6 }}
                />
                {t("devices.deviceDiscoveredIn")}
              </span>
            </Tooltip>
            <span className="discovered-images">
              {((device.google_mobile_device_ids &&
                device.google_mobile_device_ids.length > 0) ||
                (device.google_cloud_identity_device_ids &&
                  device.google_cloud_identity_device_ids.length > 0)) && (
                <Tooltip title={t("devices.deviceDiscoveredGoogle")}>
                  <img src={GoogleIcon} style={{ width: 18, height: 18 }} />
                </Tooltip>
              )}
              {device.microsoft_device_ids &&
                device.microsoft_device_ids.length > 0 && (
                  <Tooltip title={t("devices.deviceDiscoveredMicrosoft")}>
                    <img
                      src={MicrosoftIcon}
                      style={{ width: 18, height: 18 }}
                    />
                  </Tooltip>
                )}
              {device.okta_discovered && (
                <Tooltip title={t("devices.deviceDiscoveredOkta")}>
                  <img src={OktaIcon} style={{ width: 18, height: 18 }} />
                </Tooltip>
              )}
            </span>
            {managedDevice(device)}
          </>
        ) : device.skip === true ? (
          <>
            <Tooltip title={t("devices.deviceSkippedTooltip")}>
              <span className="flex align-center">
                <img
                  src={WarningIcon}
                  style={{ height: "18px", width: "18px", marginRight: 6 }}
                />
                {t("devices.deviceSkipped")}
              </span>
            </Tooltip>
            {managedDevice(device)}
          </>
        ) : device.unsupported === true ? (
          <>
            <Tooltip title={t("devices.deviceUnsupportedTooltip")}>
              <span className="flex align-center">
                <img
                  src={UnsupportedIcon}
                  style={{ height: 18, width: 18, marginRight: 6 }}
                />
                {t("devices.deviceUnsupported")}
              </span>
            </Tooltip>
            {managedDevice(device)}
          </>
        ) : (
          device.managed === true && <>{managedDevice(device)}</>
        )}
      </div>
    );
  }

  return null;
}

export default DeviceDetailsCard;
