import React from "react";
import { API, Auth } from "aws-amplify";
import { Route, Routes } from "react-router-dom";
import { getAuthConfig } from "../../utils/authConfig";
import { getApiConfig } from "../../utils/apiConfig";
import "./AuthStateApp.css";
import AuthStateHandler from "./AuthStateHandler";

/**
 * This is a hack to fix the issue with the Amplify library treating all incoming urls as OAuth responses.
 * https://github.com/aws-amplify/amplify-js/issues/9208#issuecomment-1309890756*
 */
// @ts-ignore
const _handleAuthResponse = Auth._handleAuthResponse.bind(Auth);
// @ts-ignore
Auth._handleAuthResponse = (url: string) => {
  const configuration = Auth.configure();
  // @ts-ignore
  if (!url.includes(configuration.oauth?.redirectSignIn)) return;
  if (
    url.includes("/connect/") ||
    url.includes("/vanta") ||
    url.includes("/thoropass") ||
    url.includes("/applications/") ||
    url.includes("/discovery/connect-google") ||
    url.includes("/discovery/connect-microsoft") ||
    url.includes("/signup/connect-google") ||
    url.includes("/signup/connect-microsoft") ||
    url.includes("/free-discovery-report/connect-google") ||
    url.includes("/free-discovery-report/connect-microsoft")
  )
    return; //add paths that should not be treated as Cognito responses
  return _handleAuthResponse(url);
};

// Configure AWS Amplify
Auth.configure(getAuthConfig());
API.configure(getApiConfig());

const AuthStateApp: React.FC = () => {
  return (
    <Routes>
      <Route path="*" element={<AuthStateHandler />} />
    </Routes>
  );
};

export default AuthStateApp;
