import { useState, useEffect } from "react";
import XFA_API, { Device, Role, Transaction } from "../../API/XFA_API";

export const useTransactions = (role: Role) => {
  const [transactions, setTransactions] = useState<Transaction[] | undefined>(
    undefined,
  );
  const [devices, setDevices] = useState<Device[] | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchTransactions = () => {
    setLoading(true);
    XFA_API.getTransactions(role.organization.organization_id)
      .then((transactions) => {
        transactions = transactions.filter((transaction) => {
          const today = Date.now() / 1000;
          return 60 - Math.floor((transaction.ttl - today) / 60) <= 15;
        });
        transactions = transactions.filter(
          (transaction) => transaction.verification === undefined,
        );
        setTransactions(transactions);
        setLoading(false);
      })
      .catch((error) => {
        setTransactions([]);
        console.log(error);
        setError(error.message);
        setLoading(false);
      });
  };

  const fetchDevices = () => {
    const uniqueEmails = Array.from(
      new Set(transactions!.map((transaction) => transaction.user.email)),
    );
    setLoading(true);
    Promise.all(
      uniqueEmails.map((email) =>
        XFA_API.getDevicesForUser(role.organization.organization_id, email),
      ),
    )
      .then((devices) => {
        const allDevices = devices.flat();
        setDevices(allDevices);
        setLoading(false);
      })
      .catch((error) => {
        setDevices([]);
        setError(error.message);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (transactions) {
      if (transactions.length > 0) {
        fetchDevices();
      } else {
        setDevices([]);
      }
    }
  }, [transactions]);

  useEffect(() => {
    fetchTransactions();
  }, []);

  return { transactions, devices, loading, error, refresh: fetchTransactions };
};
