import React from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress, Typography } from "@mui/material";
import XFA_API, { BillingSettings, Role, getCurrencySymbol } from "../../API/XFA_API";

interface BillingPlanProps {
  role: Role;
  settings: BillingSettings | undefined;
  settingsLoading: boolean;
}

const BillingPlan: React.FC<BillingPlanProps> = (props: BillingPlanProps) => {
  const { t } = useTranslation();
  const [numberOfUsers, setNumberOfUsers] = React.useState<number>(0);
  const [numberOfDevices, setNumberOfDevices] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [isInTrial, setIsInTrial] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!loading) return;
    if (props.settings) {
      const currentTime = new Date(Date.now());
      const date = new Date(
        currentTime.getFullYear(),
        currentTime.getMonth(),
        1,
      );
      if (props.settings.PerUserPrice) {
        XFA_API.getUserCount(
          props.role.organization.organization_id,
          date.getFullYear().toString() +
            ("0" + (date.getMonth() + 1)).slice(-2),
        ).then((count) => {
          setNumberOfUsers(count.count);
          setLoading(false);
        });
      } else {
        XFA_API.getDeviceCount(
          props.role.organization.organization_id,
          date.getFullYear().toString() +
            ("0" + (date.getMonth() + 1)).slice(-2),
        ).then((count) => {
          setNumberOfDevices(count.count + count.connect_count);
          setLoading(false);
        });
      }
    }
  }, [props.settings]);

  React.useEffect(() => {
    if (!props.settings) return;
    setIsInTrial(
      new Date().setFullYear(
        parseInt(props.settings.TrialEndDate.substring(0, 4)),
        parseInt(props.settings.TrialEndDate.substring(4, 6)) - 1,
        parseInt(props.settings.TrialEndDate.substring(6)),
      ) >= new Date().getTime(),
    );
  }, [props.settings]);

  return (
    <>
      <Typography className="paperTitle" color="primary">
        {t("Billing.billingPlanTitle")}
      </Typography>

      {props.settings && !props.settingsLoading ? (
        <>
          <Typography className="paperContent">
            {t("Billing.billingPlan")}
          </Typography>
          <Typography className="paperContentBold">
            {!props.settings.PerUserPrice ? (
              <>
                {props.settings.InsightPrice}{" "}
                {t("Billing.pricingStructureDevice", { currency: props.settings.Currency || "EUR" })}
              </>
            ) : (
              <>
                {props.settings.PerUserPrice}{" "}
                {t("Billing.pricingStructureUser", { currency: props.settings.Currency || "EUR" })}
              </>
            )}
          </Typography>
          <Typography className="paperContentSmall">
            {!props.settings.PerUserPrice && props.settings.FreeDevices > 0 ? (
              <>
                {t("Billing.firstXDevicesFree", {
                  count: props.settings.FreeDevices,
                })}{" "}
                {t("Billing.firstMonthFree")}
              </>
            ) : (
              <>{t("Billing.firstMonthFreeCap")}</>
            )}
          </Typography>
          {props.settings.TrialEndDate != "" ? (
            <>
              <Typography
                className="paperContent"
                style={{ marginTop: "24px" }}
              >
                {t("Billing.startDate")}
              </Typography>
              <Typography className="paperContentBold">
                {props.settings.TrialEndDate.substring(6).replace(/^0+/, "")}{" "}
                {t("months." + props.settings.TrialEndDate.substring(4, 6))}{" "}
                {props.settings.TrialEndDate.substring(0, 4)}
              </Typography>
            </>
          ) : (
            <></>
          )}
        </>
      ) : props.settingsLoading ? (
        <CircularProgress
          size="20px"
          style={{ marginRight: "10px" }}
          color="primary"
        />
      ) : (
        <></>
      )}
      <Typography
        className="paperTitle"
        color="primary"
        style={{ marginTop: 24 }}
      >
        {t("Billing.usageTitle")}
      </Typography>
      {props.settings && !props.settingsLoading && !loading ? (
        <>
          <Typography className="paperContent" style={{ marginTop: "24px" }}>
            {t("Billing.currentMonth")}
          </Typography>
          <Typography className="paperContentBold">
            {getCurrencySymbol(props.settings.Currency)}{" "}
            {props.settings.PerUserPrice
              ? numberOfUsers * props.settings.PerUserPrice
              : numberOfDevices * props.settings.InsightPrice}
            {isInTrial && "*"}
          </Typography>
          {isInTrial && (
            <Typography className="paperContentSmall">
              {t("Billing.isInTrial")}
            </Typography>
          )}
        </>
      ) : props.settingsLoading ? (
        <CircularProgress
          size="20px"
          style={{ marginRight: "10px" }}
          color="primary"
        />
      ) : (
        <CircularProgress
          size="20px"
          style={{ marginRight: "10px" }}
          color="primary"
        />
      )}
    </>
  );
};

export default BillingPlan;
