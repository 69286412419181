import XFA_API, { Policies, Role, Application } from "../../API/XFA_API";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  CircularProgress,
  Divider,
  Typography,
  Alert,
} from "@mui/material";
import NavigationService from "../../../utils/NavigationService";
import BreadCrumbs from "../../General/BreadCrumbs";
import { useParams } from "react-router-dom";
import PolicyRulesComponent from "./PolicyRulesComponent";
import PolicyIntegrationsComponent from "./PolicyIntegrationsComponent";
import "./PolicyPage.css";

interface PolicyPageProps {
  role: Role;
  organizationId: string;
  applications: Application[];
}

const PolicyPage: React.FC<PolicyPageProps> = (props: PolicyPageProps) => {
  const { t } = useTranslation();
  const { policyId } = useParams();
  const [creating] = useState(!policyId);

  const [loading, setLoading] = useState(false);
  const [policy, setPolicy] = useState<Policies>();

  const breadcrumbLinks = [
    { label: t("navigation.policies"), href: "/policies" },
    {
      label: policyId
        ? t("policies.editPolicy.title")
        : t("policies.createPolicy.title"),
      href: "/policies/new",
    },
  ];

  const [error, setError] = useState<string | undefined>(undefined);
  const [applicationsToUpdate, setApplicationsToUpdate] = useState<
    Application[]
  >([]);

  const closeApplication = () => {
    NavigationService.navigateToPolicies();
  };

  React.useEffect(() => {
    if (creating) {
      if (!policy) {
        setPolicy({
          name: "",
          policy_id: "",
          organization_id: "",
          installed: false,
          unsupported: false,
          skip: false,
          skip_mobile: false,
          skip_desktop: false,
          os: {
            WarnTime: 0,
          },
          os_autoupdate: {
            warn: true,
          },
          browser: {
            WarnTime: 0,
          },
          disk_encryption: {
            warn: true,
          },
          screen_lock: {
            warn: true,
          },
          antivirus: {
            warn: true,
          },
          password_manager: {
            warn: true,
          },
          biometrics: {
            warn: true,
          },
        });
      }
    } else {
      //load policy object
      setLoading(true);
      XFA_API.getPolicy(props.role.organization.organization_id, policyId!)
        .then((response) => {
          setPolicy(response);
          setLoading(false);
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });
    }
  }, []);

  const createPolicy = () => {
    if (!policy) {
      setError(t("policies.createPolicy.generalError"));
      return;
    }
    if (!policy.name) {
      setError(t("policies.createPolicy.noName"));
      return;
    }
    setLoading(true);
    XFA_API.createPolicy(props.role.organization.organization_id, policy!)
      .then((response: Policies) => {
        applicationsToUpdate.forEach((application) => {
          if (!application.PolicyID || application.PolicyID === "") {
            application.PolicyID = response.policy_id;
          }
          XFA_API.updateApplication(application).catch((error) => {
            console.log(error);
            setError(error.message);
          });
        });
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
        closeApplication();
      });
  };

  const addToApplicationsToUpdate = (application: Application) => {
    setApplicationsToUpdate([...applicationsToUpdate, application]);
  };

  const updatePolicy = () => {
    if (!policyId || !policy) {
      setError(t("policies.createPolicy.generalError"));
      return;
    }
    if (!policy.name) {
      setError(t("policies.createPolicy.noName"));
      return;
    }
    setLoading(true);

    XFA_API.updatePolicy(
      props.role.organization.organization_id,
      policyId!,
      policy!,
    )
      .then(() => {
        applicationsToUpdate.forEach((application) => {
          XFA_API.updateApplication(application).catch((error) => {
            console.log(error);
          });
        });
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
        closeApplication();
      });
  };

  return (
    <>
      <BreadCrumbs links={breadcrumbLinks} />
      <div style={{ display: "flex" }}>
        <Typography variant="pagetitle" component="h2">
          {`${creating ? t("policies.createPolicy.title") : t("policies.editPolicy.title")}${policy?.name ? ` - ${policy.name}` : ""}`}
        </Typography>
        <div style={{ marginLeft: "auto", gap: 8, display: "flex" }}>
          <Button onClick={closeApplication}>{t("Users.delete.cancel")}</Button>

          <Button
            onClick={() => {
              creating ? createPolicy() : updatePolicy();
            }}
            disabled={loading}
            variant="contained"
          >
            {loading && (
              <CircularProgress size="20px" style={{ marginRight: "10px" }} />
            )}
            {t("policies.createPolicy.saveButton")}
          </Button>
        </div>
      </div>
      <Divider style={{ marginTop: 16 }} />
      <Alert
        severity="error"
        style={{
          display: error ? "flex" : "none",
          width: "calc(50% - 8px)",
          marginTop: 16,
        }}
      >
        {error}
      </Alert>
      {loading ? (
        <div
          className="w-full flex justify-center items-center"
          style={{ marginTop: 16 }}
        >
          <CircularProgress />
        </div>
      ) : (
        policy && (
          <div className="policy-page">
            <PolicyRulesComponent
              policy={policy}
              setPolicy={(newPolicy: Policies) => {
                setPolicy(newPolicy);
              }}
              loading={loading}
            />
            <PolicyIntegrationsComponent
              organizationId={props.organizationId}
              policyId={policyId ?? ""}
              addApplicationToUpdate={addToApplicationsToUpdate}
              policyName={policy.name ?? ""}
            />
          </div>
        )
      )}
    </>
  );
};

export default PolicyPage;
