import { Card, Button } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./PeriodSelector.css";

const options = [
  "overview.chartFilter.daily",
  "overview.chartFilter.weekly",
  "overview.chartFilter.monthly",
];

interface PeriodSelectorProps {
  defaultSelectedIndex?: number;
  onLeftClick?: () => void;
  onRightClick?: () => void;
}

export default function PeriodSelector({
  defaultSelectedIndex = 2,
  onLeftClick,
  onRightClick,
}: PeriodSelectorProps) {
  const [selectedIndex, setSelectedIndex] = useState(defaultSelectedIndex);
  const { t } = useTranslation();

  const handleLeftClick = () => {
    if (selectedIndex > 0) {
      setSelectedIndex((prev) => prev - 1);
      if (onLeftClick) onLeftClick();
    }
  };

  const handleRightClick = () => {
    if (selectedIndex < options.length - 1) {
      setSelectedIndex((prev) => prev + 1);
      if (onRightClick) onRightClick();
    }
  };

  return (
    <Card className="period-selector">
      <Button
        className="period-selector-button"
        onClick={handleLeftClick}
        disabled={selectedIndex === 0}
      >
        {"<"}
      </Button>
      <span className="period-selector-content">
        {t(options[selectedIndex])}
      </span>
      <Button
        onClick={handleRightClick}
        className="period-selector-button"
        disabled={selectedIndex === options.length - 1}
      >
        {">"}
      </Button>
    </Card>
  );
}
