import React from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Button,
  ButtonBase,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  TextField,
  Tooltip,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import "./OrganizationsOverview.css";
import XFA_API, {
  BillingSettings,
  Role,
  FullOrganization,
  Currency,
  PaymentPlanDetails,
  Frequency,
} from "../API/XFA_API";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Download } from "@mui/icons-material";
import { openBase64NewTab } from "./Billing/OrganizationBilling";
import EditIcon from "@mui/icons-material/Edit";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { XfaSwitch } from "../General/XfaSwitch";

function getCurrentMonth(): string {
  const date = new Date();
  return (date.getMonth() + 1).toString().padStart(2, "0");
}

function getLastMonth(): string {
  const date = new Date();
  if (date.getMonth() === 0) {
    return "12";
  }
  return date.getMonth().toString().padStart(2, "0");
}

interface OrganizationsOverviewProps {
  role: Role;
}

const OrganizationsOverview: React.FC<OrganizationsOverviewProps> = () => {
  const { t } = useTranslation();
  const [error, setError] = React.useState<string>("");
  const [organizations, setOrganizations] = React.useState<
    FullOrganization[] | undefined
  >(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [fetching, setFetching] = React.useState<boolean>(false);
  const [downloadInvoiceInProgress, setDownloadInvoiceInProgress] =
    React.useState<number | undefined>(undefined);
  const [organizationIdToEdit, setOrganizationIdToEdit] =
    React.useState<string>("");
  const [billingSettings, setBillingSettings] =
    React.useState<BillingSettings>();
  const [total, setTotal] = React.useState<number>(0);
  const [totalLastMonth, setTotalLastMonth] = React.useState<number>(0);
  const [totalUsers, setTotalUsers] = React.useState<number>(0);
  const [totalUsersLastMonth, setTotalUsersLastMonth] =
    React.useState<number>(0);
  const [totalCurrentMRR, setTotalCurrentMRR] = React.useState<number>(0);
  const [totalCurrentARR, setTotalCurrentARR] = React.useState<number>(0);
  const [totalContractedMRR, setTotalContractedMRR] = React.useState<number>(0);
  const [totalContractedARR, setTotalContractedARR] = React.useState<number>(0);
  const [totalPotentialMRR, setTotalPotentialMRR] = React.useState<number>(0);
  const [totalPotentialARR, setTotalPotentialARR] = React.useState<number>(0);
  const [totalLastMonthCurrentMRR, setTotalLastMonthCurrentMRR] =
    React.useState<number>(0);
  const [totalLastMonthCurrentARR, setTotalLastMonthCurrentARR] =
    React.useState<number>(0);
  const [totalLastMonthContractedMRR, setTotalLastMonthContractedMRR] =
    React.useState<number>(0);
  const [totalLastMonthContractedARR, setTotalLastMonthContractedARR] =
    React.useState<number>(0);
  const [totalLastMonthPotentialMRR, setTotalLastMonthPotentialMRR] =
    React.useState<number>(0);
  const [totalLastMonthPotentialARR, setTotalLastMonthPotentialARR] =
    React.useState<number>(0);

  React.useEffect(() => {
    if (fetching) return;
    setFetching(true);
    setLoading(true);
    try {
      XFA_API.getOrganizations().then((organizations) => {
        organizations?.sort((a, b) => {
          if (a.timestamp < b.timestamp) {
            return 1;
          } else if (a.timestamp > b.timestamp) {
            return -1;
          }
          return 0;
        });
        let totalTemp = 0;
        let totalLastMonthTemp = 0;
        let totalUsersTemp = 0;
        let totalUsersLastMonthTemp = 0;
        let totalCurrentMRRTemp = 0;
        let totalContractedMRRTemp = 0;
        let totalPotentialMRRTemp = 0;
        let totalCurrentMRRLastMonthTemp = 0;
        let totalContractedMRRLastMonthTemp = 0;
        let totalPotentialMRRLastMonthTemp = 0;
        organizations?.forEach((organization) => {
          totalTemp +=
            organization.active_users.current_connect +
            organization.active_users.current_insight;
          totalLastMonthTemp +=
            organization.active_users.last_connect +
            organization.active_users.last_insight;
          totalUsersTemp += organization.active_users.current_user_count;
          totalUsersLastMonthTemp += organization.active_users.last_user_count;
          let deviceCount = Math.max(
            0,
            organization.active_users.current_insight +
              organization.active_users.current_connect -
              organization.settings.FreeDevices,
          );
          organization.currentMRR = organization.invoice
            ? organization.settings.PerUserPrice
              ? organization.settings.PerUserPrice *
                organization.active_users.current_user_count
              : organization.settings.InsightPrice * deviceCount
            : 0;
          totalCurrentMRRTemp += organization.currentMRR;
          organization.contractedMRR = organization.invoice
            ? organization.settings.PerUserPrice
              ? 6 * organization.active_users.current_user_count
              : 3 * deviceCount
            : 0;
          totalContractedMRRTemp += organization.contractedMRR;
          organization.potentialMRR =
            organization.settings.PerUserPrice ||
            (organization.settings.InsightPrice &&
              deviceCount > organization.settings.FreeDevices)
              ? 6 * organization.active_users.current_user_count
              : 0;
          totalPotentialMRRTemp += organization.potentialMRR;
          let deviceCountLastMonth = Math.max(
            0,
            organization.active_users.last_insight +
              organization.active_users.last_connect -
              organization.settings.FreeDevices,
          );
          organization.currentMRRLastMonth = organization.invoice
            ? organization.settings.PerUserPrice
              ? organization.settings.PerUserPrice *
                organization.active_users.last_user_count
              : organization.settings.InsightPrice * deviceCountLastMonth
            : 0;
          totalCurrentMRRLastMonthTemp += organization.currentMRRLastMonth;
          organization.contractedMRRLastMonth = organization.invoice
            ? organization.settings.PerUserPrice
              ? 6 * organization.active_users.last_user_count
              : 3 * deviceCountLastMonth
            : 0;
          totalContractedMRRLastMonthTemp +=
            organization.contractedMRRLastMonth;
          organization.potentialMRRLastMonth =
            organization.settings.PerUserPrice ||
            (organization.settings.InsightPrice &&
              deviceCountLastMonth > organization.settings.FreeDevices)
              ? 6 * organization.active_users.last_user_count
              : 0;
          totalPotentialMRRLastMonthTemp += organization.potentialMRRLastMonth;
        });
        setTotal(totalTemp);
        setTotalLastMonth(totalLastMonthTemp);
        setTotalUsers(totalUsersTemp);
        setTotalUsersLastMonth(totalUsersLastMonthTemp);
        setTotalCurrentMRR(totalCurrentMRRTemp);
        setTotalCurrentARR(totalCurrentMRRTemp * 12);
        setTotalContractedMRR(totalContractedMRRTemp);
        setTotalContractedARR(totalContractedMRRTemp * 12);
        setTotalPotentialMRR(totalPotentialMRRTemp);
        setTotalPotentialARR(totalPotentialMRRTemp * 12);
        setTotalLastMonthCurrentMRR(totalCurrentMRRLastMonthTemp);
        setTotalLastMonthCurrentARR(totalCurrentMRRLastMonthTemp * 12);
        setTotalLastMonthContractedMRR(totalContractedMRRLastMonthTemp);
        setTotalLastMonthContractedARR(totalContractedMRRLastMonthTemp * 12);
        setTotalLastMonthPotentialMRR(totalPotentialMRRLastMonthTemp);
        setTotalLastMonthPotentialARR(totalPotentialMRRLastMonthTemp * 12);
        setLoading(false);
        setFetching(false);
        setOrganizations(organizations);
      });
    } catch (error) {
      setLoading(false);
      setFetching(false);
      setError(t("organization.error"));
    }
  }, []);

  const numberFormatter = new Intl.NumberFormat("nl-BE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return (
    <div>
      <Typography variant="h4" color="primary">
        {t("navigation.organizations")}
      </Typography>
      <Typography>
        <div className="flex-row" style={{ display: "flex" }}>
          <div className="flex-1">
            <Table className="countTable">
              <TableHead>
                <TableRow sx={{ borderBottom: 1 }}>
                  <TableCell style={{ minWidth: "120px" }} />
                  <TableCell
                    style={{ minWidth: "90px" }}
                    className="countCellCenter"
                  >
                    {t("months." + getLastMonth())}
                  </TableCell>
                  <TableCell
                    style={{ minWidth: "140px" }}
                    className="countCellCenter"
                  >
                    {t("months." + getCurrentMonth())}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ borderBottom: 1 }}>
                  <TableCell className="countCellRight">
                    Total devices
                  </TableCell>
                  <TableCell className="countCellLeft">
                    {totalLastMonth}
                  </TableCell>
                  <TableCell className="countCellLeft">
                    {total} (
                    {((total / totalLastMonth - 1) * 100).toFixed(0) + "%"})
                  </TableCell>
                </TableRow>
                <TableRow sx={{ borderBottom: 1 }}>
                  <TableCell className="countCellRight">Total users</TableCell>
                  <TableCell className="countCellLeft">
                    {totalUsersLastMonth}
                  </TableCell>
                  <TableCell className="countCellLeft">
                    {totalUsers} (
                    {((totalUsers / totalUsersLastMonth - 1) * 100).toFixed(0) +
                      "%"}
                    )
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className="flex-1">
            <Table className="countTable flex">
              <TableHead>
                <TableRow sx={{ borderBottom: 1 }}>
                  <TableCell style={{ minWidth: "120px" }} />
                  <TableCell
                    style={{ minWidth: "90px" }}
                    className="countCellCenter"
                  >
                    {t("months." + getLastMonth())}
                  </TableCell>
                  <TableCell
                    style={{ minWidth: "140px" }}
                    className="countCellCenter"
                  >
                    {t("months." + getCurrentMonth())}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className="countCellRight">Current MRR</TableCell>
                  <TableCell className="countCellLeft">
                    &euro; {numberFormatter.format(totalLastMonthCurrentMRR)}
                  </TableCell>
                  <TableCell className="countCellLeft">
                    &euro; {numberFormatter.format(totalCurrentMRR)} (
                    {(
                      (totalCurrentMRR / totalLastMonthCurrentMRR - 1) *
                      100
                    ).toFixed(0) + "%"}
                    )
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="countCellRight">
                    Contracted MRR
                  </TableCell>
                  <TableCell className="countCellLeft">
                    &euro; {numberFormatter.format(totalLastMonthContractedMRR)}
                  </TableCell>
                  <TableCell className="countCellLeft">
                    &euro; {numberFormatter.format(totalContractedMRR)} (
                    {(
                      (totalContractedMRR / totalLastMonthContractedMRR - 1) *
                      100
                    ).toFixed(0) + "%"}
                    )
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="countCellRight">
                    Potential MRR
                  </TableCell>
                  <TableCell className="countCellLeft">
                    &euro; {numberFormatter.format(totalLastMonthPotentialMRR)}
                  </TableCell>
                  <TableCell className="countCellLeft">
                    &euro; {numberFormatter.format(totalPotentialMRR)} (
                    {(
                      (totalPotentialMRR / totalLastMonthPotentialMRR - 1) *
                      100
                    ).toFixed(0) + "%"}
                    )
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className="flex-1">
            <Table className="countTable flex">
              <TableHead>
                <TableRow sx={{ borderBottom: 1 }}>
                  <TableCell style={{ minWidth: "120px" }} />
                  <TableCell
                    style={{ minWidth: "90px" }}
                    className="countCellCenter"
                  >
                    {t("months." + getLastMonth())}
                  </TableCell>
                  <TableCell
                    style={{ minWidth: "140px" }}
                    className="countCellCenter"
                  >
                    {t("months." + getCurrentMonth())}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className="countCellRight">Current ARR</TableCell>
                  <TableCell className="countCellLeft">
                    &euro; {numberFormatter.format(totalLastMonthCurrentARR)}
                  </TableCell>
                  <TableCell className="countCellLeft">
                    &euro; {numberFormatter.format(totalCurrentARR)} (
                    {(
                      (totalCurrentARR / totalLastMonthCurrentARR - 1) *
                      100
                    ).toFixed(0) + "%"}
                    )
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="countCellRight">
                    Contracted ARR
                  </TableCell>
                  <TableCell className="countCellLeft">
                    &euro; {numberFormatter.format(totalLastMonthContractedARR)}
                  </TableCell>
                  <TableCell className="countCellLeft">
                    &euro; {numberFormatter.format(totalContractedARR)} (
                    {(
                      (totalContractedARR / totalLastMonthContractedARR - 1) *
                      100
                    ).toFixed(0) + "%"}
                    )
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="countCellRight">
                    Potential ARR
                  </TableCell>
                  <TableCell className="countCellLeft">
                    &euro; {numberFormatter.format(totalLastMonthPotentialARR)}
                  </TableCell>
                  <TableCell className="countCellLeft">
                    &euro; {numberFormatter.format(totalPotentialARR)} (
                    {(
                      (totalPotentialARR / totalLastMonthPotentialARR - 1) *
                      100
                    ).toFixed(0) + "%"}
                    )
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </Typography>
      <div className="table w-full" data-cy="users">
        {error && (
          <div style={{ marginBottom: 15 }}>
            <Alert severity="error">{error}</Alert>
          </div>
        )}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ maxWidth: "500px" }}>
                  {t("organization.id")}
                </TableCell>
                <TableCell>{t("organization.name")}</TableCell>
                <TableCell>{t("organization.activeUsers")}</TableCell>
                <TableCell>{t("organization.potential")}</TableCell>
                <TableCell>{t("organization.latestInvoice")}</TableCell>
                <TableCell>{t("applications.actionsTitle")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organizations &&
                organizations.map((organization: FullOrganization) => (
                  <TableRow
                    className="stripedRows"
                    key={organization.organization_id}
                  >
                    <TableCell style={{ maxWidth: "500px" }}>
                      {organization.organization_id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div>{organization.name}</div>
                      <div className="address">
                        {organization.address}
                        {organization.address !== "" && ","}{" "}
                        {organization.zip_code} {organization.city}
                        {organization.city !== "" && ","} {organization.country}
                      </div>
                    </TableCell>
                    <TableCell>
                      {organization.active_users && (
                        <Table className="countTable">
                          <TableHead>
                            <TableRow sx={{ borderBottom: 1 }}>
                              <TableCell style={{ minWidth: "70px" }} />
                              <TableCell
                                style={{ minWidth: "35px" }}
                                className="countCellCenter"
                              >
                                {t("months." + getLastMonth())}
                              </TableCell>
                              <TableCell
                                style={{ minWidth: "35px" }}
                                className="countCellCenter"
                              >
                                {t("months." + getCurrentMonth())}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow sx={{ borderBottom: 1 }}>
                              <TableCell className="countCellRight">
                                {t("organization.deviceCount")}
                              </TableCell>
                              <TableCell className="countCellCenter">
                                {organization.active_users.last_insight +
                                  organization.active_users.last_connect}
                              </TableCell>
                              <TableCell className="countCellCenter">
                                {organization.active_users.current_insight +
                                  organization.active_users.current_connect}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="countCellRight">
                                {t("organization.userCount")}
                              </TableCell>
                              <TableCell className="countCellCenter">
                                {organization.active_users.last_user_count}
                              </TableCell>
                              <TableCell className="countCellCenter">
                                {organization.active_users.current_user_count}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      )}
                    </TableCell>
                    <TableCell>
                      <Table className="countTable">
                        <TableHead>
                          <TableRow sx={{ borderBottom: 1 }}>
                            <TableCell style={{ minWidth: "70px" }} />
                            <TableCell
                              style={{ minWidth: "35px" }}
                              className="countCellCenter"
                            >
                              {t("months." + getLastMonth())}
                            </TableCell>
                            <TableCell
                              style={{ minWidth: "35px" }}
                              className="countCellCenter"
                            >
                              {t("months." + getCurrentMonth())}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{ borderBottom: 1 }}>
                            <TableCell className="countCellRight">
                              Current MRR
                            </TableCell>
                            <TableCell className="countCellCenter">
                              &euro; {organization.currentMRRLastMonth}
                            </TableCell>
                            <TableCell className="countCellCenter">
                              &euro; {organization.currentMRR}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="countCellRight">
                              Contracted MRR
                            </TableCell>
                            <TableCell className="countCellCenter">
                              &euro; {organization.contractedMRRLastMonth}
                            </TableCell>
                            <TableCell className="countCellCenter">
                              &euro; {organization.contractedMRR}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="countCellRight">
                              Potential MRR
                            </TableCell>
                            <TableCell className="countCellCenter">
                              &euro; {organization.potentialMRRLastMonth}
                            </TableCell>
                            <TableCell className="countCellCenter">
                              &euro; {organization.potentialMRR}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableCell>
                    <TableCell>
                      {organization.invoice && (
                        <>
                          {t("organization.amount")} &euro;
                          {organization.invoice.Amount.toLocaleString("nl-BE", {
                            maximumFractionDigits: 2,
                          })}{" "}
                          <ButtonBase
                            sx={{ marginLeft: 2 }}
                            onClick={(e) => {
                              setDownloadInvoiceInProgress(
                                organization.invoice.Number,
                              );
                              XFA_API.getInvoice(
                                organization.organization_id,
                                organization.invoice.Date,
                                String(organization.invoice.Number),
                              ).then((result) => {
                                setDownloadInvoiceInProgress(undefined);
                                openBase64NewTab(result);
                              });
                            }}
                            disabled={
                              downloadInvoiceInProgress ===
                              organization.invoice.Number
                            }
                          >
                            {downloadInvoiceInProgress ===
                              organization.invoice.Number && (
                              <CircularProgress
                                size="20px"
                                style={{ marginRight: "10px" }}
                                color="primary"
                              />
                            )}
                            <Download color="primary" />
                          </ButtonBase>
                        </>
                      )}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      <Tooltip
                        title={t("applications.edit.tooltip") || ""}
                        arrow
                        placement={"bottom"}
                      >
                        <ButtonBase
                          onClick={async () => {
                            const billingSettings =
                              await XFA_API.getBillingSettings(
                                organization.organization_id,
                              );
                            setBillingSettings(billingSettings);
                            setOrganizationIdToEdit(
                              organization.organization_id,
                            );
                          }}
                        >
                          <EditIcon color="primary" />
                        </ButtonBase>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {error && (
            <Alert
              onClose={() => {
                setError("");
              }}
              severity="error"
            >
              {error}
            </Alert>
          )}
          <div style={{ textAlign: "center", paddingTop: 5 }}>
            {loading ? (
              <CircularProgress size={30} />
            ) : (
              organizations &&
              organizations.length === 0 && (
                <span>{t("organization.noOrganizations")}</span>
              )
            )}
          </div>
        </TableContainer>
      </div>
      {organizationIdToEdit !== "" && billingSettings && (
        <BillingSettingsDialog
          organizationId={organizationIdToEdit}
          billingSettings={billingSettings}
          onClose={() => {
            setOrganizationIdToEdit("");
          }}
        />
      )}
    </div>
  );
};

interface BillingSettingsDialogProps {
  organizationId: string;
  billingSettings: BillingSettings;
  onClose: () => void;
}

const BillingSettingsDialog: React.FC<BillingSettingsDialogProps> = (
  props: BillingSettingsDialogProps,
) => {
  const { t } = useTranslation();
  const [freeDevices, setFreeDevices] = React.useState<number>(
    props.billingSettings.FreeDevices,
  );
  const [insightPrice, setInsightPrice] = React.useState<number>(
    props.billingSettings.InsightPrice,
  );
  const [connectPrice, setConnectPrice] = React.useState<number>(
    props.billingSettings.ConnectPrice,
  );
  const [trialEnds, setTrialEnds] = React.useState<Dayjs>(
    dayjs(props.billingSettings.TrialEndDate, "YYYYMMDD"),
  );
  const [endDate, setEndDate] = React.useState<Dayjs | null>(
    props.billingSettings.EndDate ? dayjs(props.billingSettings.EndDate, "YYYYMMDD") : null,
  );
  const [startDate, setStartDate] = React.useState<Dayjs | null>(
    props.billingSettings.StartDate ? dayjs(props.billingSettings.StartDate, "YYYYMMDD") : null,
  );
  const [isPerUserPricing, setIsPerUserPricing] = React.useState<boolean>(
    props.billingSettings.PerUserPrice != undefined,
  );
  const [perUserPrice, setPerUserPrice] = React.useState<number | undefined>(
    props.billingSettings.PerUserPrice,
  );
  const [currency, setCurrency] = React.useState<string>(
    props.billingSettings.Currency || "EUR",
  );
  const [blockedAccess, setBlockedAccess] = React.useState<boolean>(
    props.billingSettings.BlockedAccess || false,
  );
  const [totalYearlyPrice, setTotalYearlyPrice] = React.useState<number | undefined>(
    props.billingSettings.TotalYearlyPrice,
  );
  const [nrOfYearlyLicenses, setNrOfYearlyLicenses] = React.useState<number>(
    props.billingSettings.NrOfYearlyLicenses || 0,
  );
  const [billExtraLicenses, setBillExtraLicenses] = React.useState<boolean>(
    props.billingSettings.BillExtraLicenses || false,
  );
  const [partner, setPartner] = React.useState<string>(
    props.billingSettings.Partner || "",
  );
  const [payingCustomer, setPayingCustomer] = React.useState<boolean>(
    props.billingSettings.PayingCustomer || false,
  );
  const [paymentPlan, setPaymentPlan] = React.useState<PaymentPlanDetails>({
    Name: props.billingSettings.PaymentPlan?.Name || "",
    NrOfPolicies: props.billingSettings.PaymentPlan?.NrOfPolicies || 0,
    NrOfDiscovery: props.billingSettings.PaymentPlan?.NrOfDiscovery || 0,
    AwarenessVerificationFrequency: (props.billingSettings.PaymentPlan?.AwarenessVerificationFrequency as Frequency) || "monthly",
    AwarenessRiskFrequency: (props.billingSettings.PaymentPlan?.AwarenessRiskFrequency as Frequency) || "monthly",
    NrOfEnforcement: props.billingSettings.PaymentPlan?.NrOfEnforcement || 0,
    CustomIntegration: props.billingSettings.PaymentPlan?.CustomIntegration || false,
    AdminSecurityReports: (props.billingSettings.PaymentPlan?.AdminSecurityReports as Frequency) || "monthly",
    Cves: props.billingSettings.PaymentPlan?.Cves || false,
    Mfa: props.billingSettings.PaymentPlan?.Mfa || false,
    AdvancedMfa: props.billingSettings.PaymentPlan?.AdvancedMfa || false,
    ExportToGRC: props.billingSettings.PaymentPlan?.ExportToGRC || false,
  });
  const [paymentTermInDays, setPaymentTermInDays] = React.useState<number>(
    props.billingSettings.PaymentTermInDays || 30,
  );
  const [poNumber, setPoNumber] = React.useState<string>(
    props.billingSettings.PONumber || "",
  );

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          maxHeight: 800,
        },
      }}
      maxWidth="md"
      open={true}
      onClose={() => {
        props.onClose();
      }}
    >
      <DialogTitle>{t("Billing.changeBillingSettingsTitle")}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth={true}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <XfaSwitch
                    checked={isPerUserPricing}
                    onChange={() => {
                      if (isPerUserPricing) {
                        setPerUserPrice(undefined);
                      }
                      setIsPerUserPricing(!isPerUserPricing);
                    }}
                  />
                }
                label={t("Billing.switchPricing")}
              />
            </Grid>

            {!isPerUserPricing && (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    required
                    id="freeDevices"
                    value={freeDevices}
                    type="number"
                    label={t("Billing.freeDevicesForm")}
                    variant="outlined"
                    onChange={(e) => setFreeDevices(parseInt(e.target.value))}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    required
                    id="insightPrice"
                    value={insightPrice}
                    type="number"
                    inputProps={{ step: "0.01" }}
                    label={t("Billing.insightPrice")}
                    variant="outlined"
                    onChange={(e) => setInsightPrice(parseFloat(e.target.value))}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    required
                    id="connectPrice"
                    value={connectPrice}
                    type="number"
                    inputProps={{ step: "0.01" }}
                    label={t("Billing.connectPrice")}
                    variant="outlined"
                    onChange={(e) => setConnectPrice(parseFloat(e.target.value))}
                  />
                </Grid>
              </>
            )}

            {isPerUserPricing && (
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  required
                  id="perUserPrice"
                  value={perUserPrice}
                  type="number"
                  inputProps={{ step: "0.01" }}
                  label={t("Billing.perUserPrice")}
                  variant="outlined"
                  onChange={(e) => setPerUserPrice(parseFloat(e.target.value))}
                />
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                required
                id="currency"
                value={currency}
                label={t("Billing.currency")}
                variant="outlined"
                onChange={(e) => setCurrency(e.target.value)}
                SelectProps={{ native: true }}
              >
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
              </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                id="totalYearlyPrice"
                value={totalYearlyPrice}
                type="number"
                inputProps={{ step: "0.01" }}
                label={t("Billing.totalYearlyPrice")}
                variant="outlined"
                onChange={(e) => setTotalYearlyPrice(parseFloat(e.target.value))}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                id="nrOfYearlyLicenses"
                value={nrOfYearlyLicenses}
                type="number"
                label={t("Billing.nrOfYearlyLicenses")}
                variant="outlined"
                onChange={(e) => setNrOfYearlyLicenses(parseInt(e.target.value))}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                id="paymentTermInDays"
                value={paymentTermInDays}
                type="number"
                label={t("Billing.paymentTermInDays")}
                variant="outlined"
                onChange={(e) => setPaymentTermInDays(parseInt(e.target.value))}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                id="partner"
                value={partner}
                label={t("Billing.partner")}
                variant="outlined"
                onChange={(e) => setPartner(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                id="poNumber"
                value={poNumber}
                label={t("Billing.poNumber")}
                variant="outlined"
                onChange={(e) => setPoNumber(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t("Billing.trialEnds")}
                  value={trialEnds}
                  onChange={(newValue) => setTrialEnds(newValue!)}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t("Billing.startDate")}
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t("Billing.endDate")}
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <XfaSwitch
                    checked={blockedAccess}
                    onChange={(e) => setBlockedAccess(e.target.checked)}
                  />
                }
                label={t("Billing.blockedAccess")}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <XfaSwitch
                    checked={billExtraLicenses}
                    onChange={(e) => setBillExtraLicenses(e.target.checked)}
                  />
                }
                label={t("Billing.billExtraLicenses")}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <XfaSwitch
                    checked={payingCustomer}
                    onChange={(e) => setPayingCustomer(e.target.checked)}
                  />
                }
                label={t("Billing.payingCustomer")}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                {t("Billing.paymentPlan")}
              </Typography>
              <Divider />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                required
                id="paymentPlanName"
                value={paymentPlan.Name}
                label={t("Billing.paymentPlanName")}
                variant="outlined"
                onChange={(e) => setPaymentPlan({ ...paymentPlan, Name: e.target.value })}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                required
                type="number"
                id="nrOfPolicies"
                value={paymentPlan.NrOfPolicies}
                label={t("Billing.nrOfPolicies")}
                variant="outlined"
                onChange={(e) => setPaymentPlan({ ...paymentPlan, NrOfPolicies: parseInt(e.target.value) })}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                required
                type="number"
                id="nrOfDiscovery"
                value={paymentPlan.NrOfDiscovery}
                label={t("Billing.nrOfDiscovery")}
                variant="outlined"
                onChange={(e) => setPaymentPlan({ ...paymentPlan, NrOfDiscovery: parseInt(e.target.value) })}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                required
                type="number"
                id="nrOfEnforcement"
                value={paymentPlan.NrOfEnforcement}
                label={t("Billing.nrOfEnforcement")}
                variant="outlined"
                onChange={(e) => setPaymentPlan({ ...paymentPlan, NrOfEnforcement: parseInt(e.target.value) })}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                required
                id="awarenessVerificationFrequency"
                value={paymentPlan.AwarenessVerificationFrequency}
                label={t("Billing.awarenessVerificationFrequency")}
                variant="outlined"
                onChange={(e) => setPaymentPlan({ ...paymentPlan, AwarenessVerificationFrequency: e.target.value as Frequency })}
                SelectProps={{ native: true }}
              >
                <option value="daily">{t("Billing.frequencyDaily")}</option>
                <option value="weekly">{t("Billing.frequencyWeekly")}</option>
                <option value="monthly">{t("Billing.frequencyMonthly")}</option>
              </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                required
                id="awarenessRiskFrequency"
                value={paymentPlan.AwarenessRiskFrequency}
                label={t("Billing.awarenessRiskFrequency")}
                variant="outlined"
                onChange={(e) => setPaymentPlan({ ...paymentPlan, AwarenessRiskFrequency: e.target.value as Frequency })}
                SelectProps={{ native: true }}
              >
                <option value="daily">{t("Billing.frequencyDaily")}</option>
                <option value="weekly">{t("Billing.frequencyWeekly")}</option>
                <option value="monthly">{t("Billing.frequencyMonthly")}</option>
              </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                required
                id="adminSecurityReports"
                value={paymentPlan.AdminSecurityReports}
                label={t("Billing.adminSecurityReports")}
                variant="outlined"
                onChange={(e) => setPaymentPlan({ ...paymentPlan, AdminSecurityReports: e.target.value as Frequency })}
                SelectProps={{ native: true }}
              >
                <option value="daily">{t("Billing.frequencyDaily")}</option>
                <option value="weekly">{t("Billing.frequencyWeekly")}</option>
                <option value="monthly">{t("Billing.frequencyMonthly")}</option>
              </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <XfaSwitch
                    checked={paymentPlan.CustomIntegration}
                    onChange={(e) => setPaymentPlan({ ...paymentPlan, CustomIntegration: e.target.checked })}
                  />
                }
                label={t("Billing.customIntegration")}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <XfaSwitch
                    checked={paymentPlan.Cves}
                    onChange={(e) => setPaymentPlan({ ...paymentPlan, Cves: e.target.checked })}
                  />
                }
                label={t("Billing.cves")}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <XfaSwitch
                    checked={paymentPlan.Mfa}
                    onChange={(e) => setPaymentPlan({ ...paymentPlan, Mfa: e.target.checked })}
                  />
                }
                label={t("Billing.mfa")}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <XfaSwitch
                    checked={paymentPlan.AdvancedMfa}
                    onChange={(e) => setPaymentPlan({ ...paymentPlan, AdvancedMfa: e.target.checked })}
                  />
                }
                label={t("Billing.advancedMfa")}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <XfaSwitch
                    checked={paymentPlan.ExportToGRC}
                    onChange={(e) => setPaymentPlan({ ...paymentPlan, ExportToGRC: e.target.checked })}
                  />
                }
                label={t("Billing.exportToGRC")}
              />
            </Grid>
          </Grid>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onClose();
          }}
        >
          {t("Users.delete.cancel")}
        </Button>
        <Button
          onClick={() => {
            XFA_API.updateBillingSettings(props.organizationId, {
              OrganizationID: props.organizationId,
              InsightPrice: insightPrice,
              ConnectPrice: connectPrice,
              FreeDevices: freeDevices,
              TrialEndDate: trialEnds !== null ? trialEnds.format("YYYYMMDD") : "",
              EndDate: endDate !== null ? endDate.format("YYYYMMDD") : "",
              StartDate: startDate !== null ? startDate.format("YYYYMMDD") : "",
              PerUserPrice: perUserPrice,
              Currency: currency as Currency,
              BlockedAccess: blockedAccess,
              TotalYearlyPrice: totalYearlyPrice,
              NrOfYearlyLicenses: nrOfYearlyLicenses,
              BillExtraLicenses: billExtraLicenses,
              Partner: partner,
              PayingCustomer: payingCustomer,
              PaymentPlan: paymentPlan,
              PaymentTermInDays: paymentTermInDays,
              PONumber: poNumber,
            });
            props.onClose();
          }}
        >
          {t("Billing.updateSettings")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrganizationsOverview;
