import { removeCvePrefix } from "../../../utils/filterUtils";
import NavigationService from "../../../utils/NavigationService";
import { Device } from "../../API/XFA_API";

let cachedDevices: Device[] | null = null;
let cachedResult: ReturnType<typeof getRiskAssessmentData> | null = null;

export function getRiskAssessmentDataCached(devices: Device[]) {
  if (cachedDevices === devices && cachedResult) {
    return cachedResult;
  }
  const result = getRiskAssessmentData(devices);
  cachedDevices = devices;
  cachedResult = result;
  return result;
}

export function getAllCves(device: Device) {
  return [
    ...(device.chrome_cves || []),
    ...(device.firefox_cves || []),
    ...(device.safari_cves || []),
    ...(device.edge_cves || []),
    ...(device.os_cves || []),
  ];
}

function getRiskAssessmentData(devices: Device[]) {
  let nonEncryptedCount = 0;
  let outOfDateOSCount = 0;
  let outOfDateBrowserCount = 0;

  let ransomwareRisks = new Set<string>();
  let phishingRisks = new Set<string>();
  let dataBreachRisks = new Set<string>();

  const ransomwareAffectedDevices = new Set<string>();
  const phishingAffectedDevices = new Set<string>();
  const dataBreachAffectedDevices = new Set<string>();

  const ransomwareCveCounts = new Map<string, number>();
  const phishingCveCounts = new Map<string, number>();
  const dataBreachCveCounts = new Map<string, number>();

  for (const device of devices) {
    if (device.diskencryption_active === false) {
      nonEncryptedCount++;
    }
    if (device.os_version !== "" && device.os_uptodate === false) {
      outOfDateOSCount++;
    }
    if (
      device.browser_uptodate === false ||
      device.firefox_uptodate === false ||
      device.chrome_uptodate === false ||
      device.safari_uptodate === false ||
      device.edge_uptodate === false
    ) {
      outOfDateBrowserCount++;
    }

    const cves = getAllCves(device);

    for (const cve of cves) {
      const cleanTag = removeCvePrefix(cve.tag);
      if (cve.categories?.includes("Phishing")) {
        phishingRisks.add(cleanTag);
        phishingAffectedDevices.add(device.device_id);
        phishingCveCounts.set(
          cleanTag,
          (phishingCveCounts.get(cleanTag) || 0) + 1,
        );
      }
      if (cve.categories?.includes("Data Breach")) {
        dataBreachRisks.add(cleanTag);
        dataBreachAffectedDevices.add(device.device_id);
        dataBreachCveCounts.set(
          cleanTag,
          (dataBreachCveCounts.get(cleanTag) || 0) + 1,
        );
      }
      if (cve.categories?.includes("Ransomware")) {
        ransomwareAffectedDevices.add(device.device_id);
        ransomwareCveCounts.set(
          cleanTag,
          (ransomwareCveCounts.get(cleanTag) || 0) + 1,
        );
      }
    }
  }

  const formatRisks = (risksMap: Map<string, number>) => {
    return Array.from(risksMap.entries()).map(([tag, count]) => ({
      description: tag,
      numberOfAffectedDevices: count,
      onClick: () => NavigationService.navigateToDevices(undefined, tag),
    }));
  };

  return {
    nonEncryptedCount,
    outOfDateOSCount,
    outOfDateBrowserCount,
    ransomwareRisks: formatRisks(ransomwareCveCounts),
    phishingRisks: formatRisks(phishingCveCounts),
    dataBreachRisks: formatRisks(dataBreachCveCounts),
    ransomwareAffectedCount: ransomwareAffectedDevices.size,
    phishingAffectedCount: phishingAffectedDevices.size,
    dataBreachAffectedCount: dataBreachAffectedDevices.size,
  };
}
