import { useState, useEffect, useCallback } from "react";
import XFA_API, { Device, Role, Shadow } from "../../API/XFA_API";

export const useDevices = (
  role: Role,
  policy_id: string | undefined,
  addCves: boolean,
) => {
  const [devices, setDevices] = useState<Device[] | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [loadedCves, setLoadedCVEs] = useState<boolean>(false);

  const fetchDevices = useCallback(
    async (withCves?: boolean) => {
      try {
        let allDevices: Device[] = [];
        let lastEvaluatedKey: string | undefined = undefined;

        do {
          const result = await XFA_API.getGroupedDevices(
            role.organization.organization_id,
            lastEvaluatedKey,
            policy_id ?? role.organization.default_policy_id,
            withCves,
          );

          if (result && result.shadows) {
            const devicesFromShadows = result.shadows.flatMap(
              (shadow: Shadow) => shadow.devices,
            );
            allDevices = [...allDevices, ...devicesFromShadows];
            lastEvaluatedKey = result.lastEvaluatedKey;
          } else {
            lastEvaluatedKey = undefined;
          }
        } while (lastEvaluatedKey);
        if (allDevices.length > (devices?.length ?? 0)) {
          setDevices(allDevices);
        }
        setError(null);
      } catch (err: any) {
        setError(err.message || "Error fetching devices");
      } finally {
        setLoading(false);
        if (withCves && !loadedCves) {
          setLoadedCVEs(true);
        }
      }
    },
    [role.organization.organization_id, policy_id, loadedCves, setLoadedCVEs],
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchDevices();
      if (!loadedCves && addCves) {
        fetchDevices(true);
      }
    };
    fetchData();
  }, []);

  return {
    devices,
    loading,
    error,
    refresh: fetchDevices,
    loadedCves,
  };
};
