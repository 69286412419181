import XFA_API, {
  ApplicationCreationRequest,
  Role,
} from "../components/API/XFA_API";
import { trackEvent } from "../components/intercom/intercomProvider";
import NavigationService from "./NavigationService";

export const startGoogleDiscovery = async (role: Role) => {
  trackEvent("Selecting Google Integration");
  const application = await XFA_API.createApplication(
    role.organization.organization_id,
    {
      Name: "Google",
      Type: "Google",
    } as ApplicationCreationRequest,
  )
    .then((respApplication) => {
      return respApplication;
    })
    .catch((error) => {
      console.log(error);
    });
  askForGooglePermissions(
    RedirectEndpointType.DEFAULT,
    application.ApplicationID,
  );
};

export const startOktaDiscovery = async (role: Role) => {
  trackEvent("Selecting Okta Discovery Integration");
  const application = await XFA_API.createApplication(
    role.organization.organization_id,
    {
      Name: "Okta Discovery",
      Type: "OktaDiscovery",
    } as ApplicationCreationRequest,
  )
    .then((respApplication) => {
      return respApplication;
    })
    .catch((error) => {
      console.log(error);
    });
  NavigationService.navigateToDiscovery(application.ApplicationID);
};

export const startMicrosoftDiscovery = async (role: Role) => {
  trackEvent("Selecting Microsoft Discovery Integration");
  const application = await XFA_API.createApplication(
    role.organization.organization_id,
    {
      Name: "Microsoft Discovery",
      Type: "Microsoft",
    } as ApplicationCreationRequest,
  )
    .then((respApplication) => {
      return respApplication;
    })
    .catch((error) => {
      console.log(error);
    });
  askForMicrosoftPermissions(
    RedirectEndpointType.DEFAULT,
    application.ApplicationID,
  );
};

export enum RedirectEndpointType {
  DEFAULT = "DEFAULT",
  FREE_REPORT = "FREE_REPORT",
}

export function parseBaseMicrosoftRequestUrl(
  redirectUrl: string,
  applicationId: string | undefined,
) {
  const devicesScope =
    "https://graph.microsoft.com/Device.Read.All+offline_access+https://graph.microsoft.com/AuditLog.Read.All+GroupMember.Read.All+User.ReadBasic.All+https://graph.microsoft.com/DeviceManagementManagedDevices.Read.All";
  const devicesMicrosoftClientId = "947c784d-d758-4f37-89c3-1dd1921f8b08";
  const targetUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?redirect_uri=${encodeURIComponent(
    redirectUrl,
  )}&response_type=code&client_id=${devicesMicrosoftClientId}&scope=${devicesScope}&access_type=offline&state=${applicationId}&prompt=consent`;
  return targetUrl;
}

export function getMicrosoftRedirectUri(redirectType: RedirectEndpointType) {
  if (redirectType === RedirectEndpointType.FREE_REPORT) {
    return `${window.location.origin}/free-discovery-report/connect-microsoft`;
  }
  return `${window.location.origin}/discovery/connect-microsoft`;
}

export function askForMicrosoftPermissions(
  redirectType: RedirectEndpointType,
  applicationId?: string,
) {
  if (!applicationId) {
    if (redirectType !== RedirectEndpointType.FREE_REPORT) {
      return;
    }
  }

  const targetUrl = parseBaseMicrosoftRequestUrl(
    getMicrosoftRedirectUri(redirectType),
    applicationId,
  );
  NavigationService.navigateToExternal(targetUrl);
}

export function parseBaseGoogleRequestUrl(
  redirectUrl: string,
  applicationId: string | undefined,
) {
  const devicesScope =
    "https://www.googleapis.com/auth/admin.directory.device.mobile";
  const groupsScope =
    "https://www.googleapis.com/auth/admin.directory.group.readonly";
  const chromebrowserScope =
    "https://www.googleapis.com/auth/admin.directory.device.chromebrowsers.readonly";
  const chromeosScope =
    "https://www.googleapis.com/auth/admin.directory.device.chromeos";
  const devicesGoogleClientId =
    "948587634820-0nj454fkcegujomr2383lpuru4e0hs64.apps.googleusercontent.com";
  const targetUrl = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${encodeURIComponent(
    redirectUrl,
  )}&response_type=code&client_id=${devicesGoogleClientId}&scope=email%20${devicesScope}%20${groupsScope}%20${chromebrowserScope}%20${chromeosScope}&access_type=offline&state=${applicationId}&prompt=consent`;
  return targetUrl;
}

export function getGoogleRedirectUri(redirectType: RedirectEndpointType) {
  if (redirectType === RedirectEndpointType.FREE_REPORT) {
    return `${window.location.origin}/free-discovery-report/connect-google`;
  }
  return `${window.location.origin}/discovery/connect-google`;
}

export function askForGooglePermissions(
  redirectType: RedirectEndpointType,
  applicationId?: string
) {
  if (!applicationId) {
    if (redirectType !== RedirectEndpointType.FREE_REPORT) {
      return;
    }
  }

  const targetUrl = parseBaseGoogleRequestUrl(
    getGoogleRedirectUri(redirectType),
    applicationId,
  );
  NavigationService.navigateToExternal(targetUrl);
}
