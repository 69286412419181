import {
  Paper,
  Typography,
  CircularProgress,
  Box,
  Button,
  Tooltip as MuiTooltip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Device, Policies } from "../API/XFA_API";
import "./Health.css";
import {
  getRecentDevices,
  DeviceRisk,
  getDeviceRisk,
} from "../Users/UserUtils";
import NavigationService from "../../utils/NavigationService";
import { FilterType } from "../Users/UsersOverview";

ChartJS.register(ArcElement, Tooltip, Legend);

interface HealthProps {
  devices: Device[] | undefined;
  selectedPolicy: Policies | undefined | null;
}

interface DeviceCount {
  safe: number;
  unsafe: number;
  unsupported: number;
  unknown: number;
}

const CustomTooltip = MuiTooltip;

const Health: React.FC<HealthProps> = (props: HealthProps) => {
  const { t } = useTranslation();
  const [graphData, setGraphData] = useState<number[]>([]);
  const [safePercentage, setSafePercentage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [hasNoRecentDevices, setHasNoRecentDevices] = useState<boolean>(false);

  useEffect(() => {
    if (props.devices === undefined || props.selectedPolicy === undefined) {
      setLoading(true);
      setHasNoRecentDevices(false);
      return;
    }

    setLoading(false);
    setHasNoRecentDevices(false);

    const recentDevices = getRecentDevices(props.devices);

    const deviceCount: DeviceCount = {
      safe: 0,
      unsafe: 0,
      unsupported: 0,
      unknown: 0,
    };

    recentDevices.forEach((device) => {
      const status: DeviceRisk = getDeviceRisk(device, props.selectedPolicy);

      if (device.unsupported || status === DeviceRisk.Unsupported) {
        deviceCount.unsupported += 1;
        return;
      }

      switch (status) {
        case DeviceRisk.Unknown:
          deviceCount.unknown += 1;
          break;
        case DeviceRisk.Safe:
          deviceCount.safe += 1;
          break;
        case DeviceRisk.Unsafe:
          deviceCount.unsafe += 1;
          break;
      }
    });

    const totalDevices = recentDevices.length;

    setGraphData([
      deviceCount.safe,
      deviceCount.unsafe,
      deviceCount.unknown,
      deviceCount.unsupported,
    ]);

    if (totalDevices > 0) {
      setSafePercentage(
        parseFloat(((deviceCount.safe / totalDevices) * 100).toFixed(2)),
      );
    } else {
      setSafePercentage(0);
      setHasNoRecentDevices(true);
    }
  }, [props.devices, props.selectedPolicy]);

  return (
    <Paper
      className="statisticContainer statisticWithTitle"
      style={{ paddingTop: 20 }}
    >
      <div className="statisticTitle">{t("statistics.health")}</div>
      <div className="statisticsDescription">
        {t("statistics.recentDescription")}
      </div>

      <div className="healthStatisticContent">
        {loading ? (
          <span className="usageGraphPlaceholder statisticContent">
            <CircularProgress />
          </span>
        ) : hasNoRecentDevices ? (
          <span className="usageGraphPlaceholder statisticContent">
            {t("devices.noRecentDevices")}
          </span>
        ) : (
          <HalfDonutChart data={graphData} safePercentage={safePercentage} />
        )}
      </div>
    </Paper>
  );
};
export default Health;

interface HalfDonutChartProps {
  data: number[];
  safePercentage: number;
}

const HalfDonutChart: React.FC<HalfDonutChartProps> = ({
  data,
  safePercentage,
}) => {
  const { t } = useTranslation();
  const totalDevices = data.reduce((a, b) => a + b, 0);
  const chartData = {
    labels: [
      t("statistics.deviceSafeTooltip", { count: data[0] }),
      t("statistics.deviceUnsafeTooltip", { count: data[1] }),
      t("statistics.deviceUnverifiedTooltip", { count: data[2] }),
      t("statistics.deviceUnsupportedTooltip", { count: data[3] }),
    ],
    types: [
      t("statistics.deviceSafe"),
      t("statistics.deviceUnsafe"),
      t("statistics.deviceUnknown"),
      t("statistics.deviceUnsupported"),
    ],
    datasets: [
      {
        data,
        backgroundColor: [
          "rgba(16, 185, 129, 0.3)",
          "rgba(239, 68, 68, 0.3)",
          "#e2e4e8",
          "rgba(29, 78, 216, 0.3)",
        ],
        borderWidth: 0,
        borderRadius: 200,
        hoverBackgroundColor: ["#10B981", "#EF4444", "#CCD0D6", "#1D4ED8"],
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    rotation: -90,
    circumference: 180,
    cutout: "85%",
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItem: { dataIndex: any }[]) => {
            const index = tooltipItem[0].dataIndex;
            const label = chartData.labels[index];
            return `${label}`;
          },
          label: () => {
            return "";
          },
        },
        backgroundColor: "rgba(0, 0, 0, 1)",
        displayColors: false,
        zIndex: 1,
      },
    },
    onClick: (evt: any, element: string | any[]) => {
      if (element.length > 0) {
        const index = element[0].index;
        const statusMap = [
          FilterType.Safe,
          FilterType.Unsafe,
          FilterType.Unknown,
          FilterType.Unsupported,
        ];

        NavigationService.navigateToDevices(statusMap[index]);
      }
    },
    onHover: (event: any, chartElement: any) => {
      event.native.target.style.cursor = chartElement[0]
        ? "pointer"
        : "default";
    },
  };

  return (
    <Box className="halfDonutChartContainer">
      <div className="chartContainer">
        <Doughnut data={chartData} options={options} />
      </div>
      <Box className="healthPercentageContainer">
        <div className="healthPercentage">{safePercentage}%</div>
        <div className="healthDescription">
          {t("statistics.healthPercentageDescription")}{" "}
          <CustomTooltip
            title={
              <Typography
                sx={{
                  fontFamily: "Manrope",
                  fontSize: "12px",
                  fontWeight: 600,
                  lineHeight: "18px",
                  textAlign: "center",
                }}
              >
                {t("statistics.deviceUnsafeExplanationTooltip")}
              </Typography>
            }
          >
            <span
              style={{
                color: "var(--color-green-500)",
                fontWeight: 900,
                cursor: "pointer",
              }}
            >
              {t("statistics.deviceSafe")}
            </span>
          </CustomTooltip>
        </div>
      </Box>
      <Box className="healthLegendContainer">
        <Box className="healthLegend">
          {chartData.types.map((label, index) => (
            <Box className="healthLegendItem" key={`legend-${index}`}>
              <Box
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "3px",
                  backgroundColor:
                    chartData.datasets[0].hoverBackgroundColor[index],
                }}
              />
              <Typography variant="body2">
                {label} (
                {totalDevices > 0
                  ? (
                      (chartData.datasets[0].data[index] / totalDevices) *
                      100
                    ).toFixed(2)
                  : 0}
                %)
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box className="healthButtonContainer">
        <Button
          variant="text"
          className="healthButton"
          onClick={() => {
            NavigationService.navigateToDevices(FilterType.Unsafe);
          }}
        >
          {t("statistics.healthRiskButton")}
        </Button>
        <Button
          variant="text"
          className="healthButton"
          onClick={() => {
            NavigationService.navigateToApplications();
          }}
        >
          {t("statistics.healthUpdatePolicyButton")}
        </Button>
      </Box>
    </Box>
  );
};
