import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress, Typography, Alert, Tabs, Tab } from "@mui/material";
import XFA_API, { DashboardUser, Organization, Role } from "../API/XFA_API";
import EmptyState from "../General/EmptyState";
import ConfirmationDialog from "../Dialogs/ConfirmationDialog";
import "./Settings.css";
import BreadCrumbs from "../General/BreadCrumbs";
import AdminTab, { AdminTabProps } from "./AdminTab";
import { InviteUsersDialog } from "../Users/InviteUsersDialog";
import OrganizationTab from "./OrganizationTab";
import OrganizationBilling from "../Organizations/Billing/OrganizationBilling";
import { useLocation } from "react-router-dom";
import NavigationService from "../../utils/NavigationService";
import { a11yProps, TabPanel } from "../General/TabUtils";
import NotificationsTab from "./NotificationsTab";

interface SettingsOverviewProps {
  role: Role;
  onSaveBillingAddress: (org: Organization) => void;
}

const formatDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(dateString).toLocaleDateString("en-US", options);
};

const SettingsOverview: React.FC<SettingsOverviewProps> = ({
  role,
  onSaveBillingAddress,
}) => {
  const { t } = useTranslation();
  const [openInviteUsersDialog, setOpenInviteUsersDialog] =
    useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [users, setUsers] = useState<DashboardUser[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [userDeleteInProgress, setUserDeleteInProgress] =
    useState<boolean>(false);
  const [confirmUserDelete, setConfirmUserDelete] = useState<
    DashboardUser | undefined
  >(undefined);
  const [adminInProgress, setAdminInProgress] = React.useState<string>("");
  const location = useLocation();

  const [tabValue, setTabValue] = useState(0);

  const getTabIndexFromUrl = (pathname: string) => {
    const tab = pathname.split("/").pop();
    switch (tab) {
      case "organization":
        return 0;
      case "admin":
        return 1;
      case "billing":
        return 2;
      case "notifications":
        return 3;
      default:
        return 0;
    }
  };

  const openInviteUsers = useCallback(() => {
    setOpenInviteUsersDialog(true);
  }, []);

  const getNrOfAdmins = React.useCallback(() => {
    if (users === undefined) {
      return 0;
    }
    return users.length;
  }, [users]);

  const refreshUsers = useCallback(async () => {
    setLoading(true);
    try {
      const users = await XFA_API.getDashboardUsers(
        role.organization.organization_id,
      );
      setLoading(false);
      setUsers(users);
    } catch (error) {
      setLoading(false);
      setError(t("users.error"));
    }
  }, [t, role.organization.organization_id]);

  const closeInviteUsers = useCallback(
    (refresh: boolean) => {
      setOpenInviteUsersDialog(false);
      if (refresh) {
        refreshUsers();
      }
    },
    [refreshUsers],
  );

  useEffect(() => {
    refreshUsers();
  }, [refreshUsers]);

  const breadcrumbLinks = [
    { label: t("navigation.settings"), href: "/settings" },
  ];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);

    const tabPath =
      newValue === 0
        ? "organization"
        : newValue === 1
          ? "admin"
          : newValue === 2
            ? "billing"
            : newValue === 3
              ? "notifications"
              : "";
    NavigationService.navigateTo(`/settings/${tabPath}`);
  };

  useEffect(() => {
    const currentTab = getTabIndexFromUrl(location.pathname);
    setTabValue(currentTab);
  }, [location.pathname]);

  const adminTabProps: AdminTabProps = {
    t,
    openInviteUsers,
    users: users ?? [],
    setAdminInProgress,
    refreshUsers,
    error,
    setError,
    formatDate,
    getNrOfAdmins,
    role,
  };

  return (
    <div>
      <BreadCrumbs links={breadcrumbLinks} />
      <div style={{ display: "flex" }}>
        <Typography variant="pagetitle" color="primary">
          <div>{t("navigation.settings")}</div>
        </Typography>
      </div>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="settings tabs"
        TabIndicatorProps={{
          style: {
            backgroundColor: "#393E45",
          },
        }}
        sx={{
          borderBottom: "1px solid #EAECF0",
          "& .MuiTab-root": {
            fontFamily: "Manrope",
            fontSize: "14px",
            lineHeight: "20px",
            textTransform: "none",
          },
          "& .Mui-selected": {
            color: "#393E45",
            fontWeight: 700,
          },
          "& .MuiTab-root:not(.Mui-selected)": {
            color: "#6D717A",
            fontWeight: 600,
          },
        }}
      >
        <Tab label={t("settings.organization.title")} {...a11yProps(0)} />
        <Tab label={t("settings.admin")} {...a11yProps(1)} />
        <Tab label={t("navigation.organizationBilling")} {...a11yProps(1)} />
        <Tab label={t("settings.notifications.title")} {...a11yProps(3)} />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <div>
          <OrganizationTab
            organizationID={role.organization.organization_id ?? ""}
          />
        </div>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <div className="table w-full" data-cy="users">
          {error && (
            <div style={{ marginBottom: 15 }}>
              <Alert severity="error">{error}</Alert>
            </div>
          )}
          {users && users.length === 0 ? (
            <EmptyState context="users" onClick={openInviteUsers} />
          ) : loading ? (
            <div style={{ textAlign: "center", paddingTop: 5 }}>
              <CircularProgress size={30} />
            </div>
          ) : (
            <AdminTab {...adminTabProps} />
          )}
        </div>
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <OrganizationBilling
          role={role}
          onSaveBillingAddress={onSaveBillingAddress}
        />
      </TabPanel>

      <TabPanel value={tabValue} index={3}>
        {error ||
        (users &&
          (users.length === 0 ||
            !users!.find(
              (user: DashboardUser) => user.email == role.email,
            ))) ? (
          <div style={{ marginBottom: 15 }}>
            <Alert severity="error">
              {error || t("settings.notifications.userNotFound")}
            </Alert>
          </div>
        ) : loading ? (
          <div style={{ textAlign: "center", paddingTop: 5 }}>
            <CircularProgress size={30} />
          </div>
        ) : (
          <NotificationsTab
            organizationID={role.organization.organization_id ?? ""}
            user={
              users!.find((user: DashboardUser) => user.email == role.email)!
            }
          />
        )}
      </TabPanel>

      {openInviteUsersDialog && (
        <InviteUsersDialog
          role={role}
          users={users}
          onInvite={XFA_API.inviteAdminUser}
          onClose={closeInviteUsers}
        />
      )}

      {confirmUserDelete && (
        <ConfirmationDialog
          title={t("Users.delete.confirmTitle") + confirmUserDelete?.email}
          cancelText={t("Users.delete.cancel")}
          confirmText={t("Users.delete.confirm")}
          inProgress={userDeleteInProgress}
          onCancel={() => setConfirmUserDelete(undefined)}
          onConfirm={() => {
            setUserDeleteInProgress(true);
            XFA_API.deleteAdminUser(
              role.organization.organization_id,
              confirmUserDelete.email,
            ).then(() => {
              refreshUsers();
              setUserDeleteInProgress(false);
              setConfirmUserDelete(undefined);
            });
          }}
        />
      )}
    </div>
  );
};

export default SettingsOverview;
