import React from "react";
import XFA_API from "../API/XFA_API";
import TransactionCompletedIcon from "../../icons/transaction-completed-icon.svg";
import TransactionBlockedIcon from "../../icons/transaction-blocked-icon.svg";
import PendingIcon from "../../icons/pending-icon.svg";
import { useTranslation } from "react-i18next";
import { ActivityItemProps } from "./types/types";
import { decisionUtils } from "./utils/decisionUtils";
import { TransactionMetadata } from "./components/TransactionMetadata";
import { ActionButton } from "./components/ActionButton";
import CheckIcon from "@mui/icons-material/Check";

const ActivityItem: React.FC<ActivityItemProps> = ({
  transaction,
  name,
  deviceName,
  refresh,
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const { t } = useTranslation();

  const isBlocking =
    transaction.status === "COMPLETED" &&
    transaction.decisions !== undefined &&
    transaction.decisions !== null &&
    decisionUtils.hasBlockingDecision(transaction.decisions);

  const isGranted = transaction.status === "GRANTED";

  const blockedTooltip = transaction.decisions
    ? Object.entries(transaction.decisions)
        .filter(([, decision]) => decision?.status === "BLOCKING")
        .map(([key]) => t(`decisions.${key}`))
        .join(", ") || t("decisions.mfa")
    : "";

  const handleAllowAccess = async () => {
    if (!transaction.organization?.organization_id) return;

    setLoading(true);
    try {
      await XFA_API.approveTransaction(
        transaction.organization.organization_id,
        transaction.transaction_id,
      ).then(() => {
        refresh();
      });
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col p-4 border-b border-gray-200">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3 flex-1">
          <img
            className="transaction-status-icon"
            src={
              transaction.status === "PENDING"
                ? PendingIcon
                : !isBlocking
                  ? TransactionCompletedIcon
                  : TransactionBlockedIcon
            }
            alt="Transaction status"
          />
          <TransactionMetadata
            name={name ?? t("activity.unknownEmail")}
            applicationName={
              transaction.application?.Name ?? t("activity.unknownApplication")
            }
            deviceName={deviceName ?? t("activity.unknownDevice")}
            ttl={transaction.ttl}
            status={transaction.status}
            isBlocking={isBlocking}
            isGranted={isGranted}
            blockedTooltip={blockedTooltip}
          />
        </div>

        {isGranted ? (
          <span
            className="flex items-center gap-2 activityItemStatus"
            style={{ color: "var(--color-green-700)", fontSize: "14px" }}
          >
            <CheckIcon fontSize="small" />
            {t("activity.accessGranted")}
          </span>
        ) : (
          <ActionButton
            isBlocking={isBlocking}
            isGranted={isGranted}
            loading={loading}
            onAllowAccess={handleAllowAccess}
          />
        )}
      </div>

      {error && (
        <div className="text-red-500 text-sm mt-2">
          {t("activity.error", { error })}
        </div>
      )}
    </div>
  );
};

export default ActivityItem;
