import { useState, useCallback } from "react";
import { removeCvePrefix } from "../../../utils/filterUtils";
import { Shadow, Policies, Device, User } from "../../API/XFA_API";
import { FilterType } from "../UsersOverview";
import {
  getRecentDevices,
  getUserSecurityStatus,
  DeviceRisk,
  getDeviceRisk,
} from "../UserUtils";
import { getAllCves } from "../../Overview/RiskCard/RiskAssesmentUtils";

export const useFilters = (
  users: Shadow[] | undefined,
  selectedPolicy: Policies | undefined,
  searchTerm: string,
  invitedUsers: User[] | undefined,
) => {
  const [filterStatus, setFilterStatus] = useState<FilterType>(FilterType.None);
  const [sortField, setSortField] = useState<string | null>(null);
  const [sortOrderAsc, setSortOrder] = useState<boolean>(true);

  const getSortedUsers = useCallback(
    (users: Shadow[]) => {
      if (!sortField) return users;

      return [...users].sort((a, b) => {
        let fieldA: string | DeviceRisk | undefined;
        let fieldB: string | DeviceRisk | undefined;

        if (sortField === "securityStatus") {
          fieldA = getUserSecurityStatus(
            getRecentDevices(a.devices),
            selectedPolicy,
          );
          fieldB = getUserSecurityStatus(
            getRecentDevices(b.devices),
            selectedPolicy,
          );
        } else {
          fieldA = (a[sortField as keyof Shadow] as unknown as string) || "";
          fieldB = (b[sortField as keyof Shadow] as unknown as string) || "";
        }

        if (fieldA < fieldB) return sortOrderAsc ? -1 : 1;
        if (fieldA > fieldB) return sortOrderAsc ? 1 : -1;
        return 0;
      });
    },
    [sortField, sortOrderAsc],
  );

  const filterUsersBySearchTerm = useCallback(
    (users: Shadow[]) => {
      if (!users) return [];

      return users.filter((user) => {
        const activeDevices = getRecentDevices(user.devices);
        const matchesEmail = user.email
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        const matchesDevice = activeDevices.some((device) =>
          device.computer_name?.name
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()),
        );
        const hasMatchingCVE = activeDevices.some((device) =>
          [
            ...(device.os_cves || []),
            ...(device.browser_cves || []),
            ...(device.chrome_cves || []),
            ...(device.firefox_cves || []),
            ...(device.edge_cves || []),
            ...(device.safari_cves || []),
          ].some((cve) =>
            removeCvePrefix(cve.tag?.toLowerCase()).includes(
              searchTerm.toLowerCase(),
            ),
          ),
        );

        return matchesEmail || matchesDevice || hasMatchingCVE;
      });
    },
    [users, searchTerm],
  );

  const getFilteredDevices = useCallback(
    (user: Shadow): Device[] => {
      var activeDevices = getRecentDevices(user.devices);
      if (searchTerm.toLowerCase().startsWith("cve")) {
        activeDevices = activeDevices.filter((device) =>
          [
            ...(device.os_cves || []),
            ...(device.browser_cves || []),
            ...(device.chrome_cves || []),
            ...(device.firefox_cves || []),
            ...(device.edge_cves || []),
            ...(device.safari_cves || []),
          ].some((cve) =>
            removeCvePrefix(cve.tag?.toLowerCase()).includes(
              searchTerm.toLowerCase(),
            ),
          ),
        );
      }

      if (filterStatus === FilterType.None) return activeDevices;

      return activeDevices.filter((device) => {
        switch (filterStatus) {
          case FilterType.Safe:
            return (
              getUserSecurityStatus([device], selectedPolicy) ===
              DeviceRisk.Safe
            );
          case FilterType.Unsafe:
            return (
              getUserSecurityStatus([device], selectedPolicy) ===
              DeviceRisk.Unsafe
            );
          case FilterType.HasCVE:
            return (
              (device.os_cves && device.os_cves.length > 0) ||
              (device.browser_cves && device.browser_cves.length > 0) ||
              (device.chrome_cves && device.chrome_cves.length > 0) ||
              (device.firefox_cves && device.firefox_cves.length > 0) ||
              (device.edge_cves && device.edge_cves.length > 0) ||
              (device.safari_cves && device.safari_cves.length > 0)
            );
          case FilterType.Phishing:
            return device.os_cves?.some((cve) =>
              cve.categories?.includes("Phishing"),
            );
          case FilterType.DataBreach:
            return device.os_cves?.some((cve) =>
              cve.categories?.includes("Data Breach"),
            );
          case FilterType.Ransomware:
            return device.os_cves?.some((cve) =>
              cve.categories?.includes("Ransomware"),
            );
          case FilterType.Managed:
            return device.managed;
          case FilterType.Discovered:
            return device.discovered;
          default:
            return (
              filterStatus.toString() ===
              getUserSecurityStatus(activeDevices, selectedPolicy).toString()
            );
        }
      });
    },
    [filterStatus, selectedPolicy, searchTerm],
  );

  const filterActiveUser = (user: Shadow) => {
    const activeDevices = getRecentDevices(user.devices);
    return (
      invitedUsers?.find(
        (invitedUser) =>
          invitedUser.email.toLowerCase() === user.email.toLowerCase(),
      ) !== undefined ||
      !(user.devices.length !== 0 && activeDevices.length === 0)
    );
  };

  const getFilteredUsers = useCallback(
    (users: Shadow[]) => {
      return users.filter(filterActiveUser).filter((user) => {
        if (filterStatus === FilterType.None) return true;
        const activeDevices = getRecentDevices(user.devices);
        if (
          filterStatus.toString() === DeviceRisk.Unknown ||
          filterStatus.toString() === DeviceRisk.Unsupported
        ) {
          return activeDevices.some(
            (device) =>
              getDeviceRisk(device, selectedPolicy) === filterStatus.toString(),
          );
        }

        if (filterStatus === FilterType.HasCVE) {
          return activeDevices.some(
            (device) =>
              // Check if there are any CVEs on OS or various browsers
              (device.os_cves && device.os_cves.length > 0) ||
              (device.browser_cves && device.browser_cves.length > 0) ||
              (device.chrome_cves && device.chrome_cves.length > 0) ||
              (device.firefox_cves && device.firefox_cves.length > 0) ||
              (device.edge_cves && device.edge_cves.length > 0) ||
              (device.safari_cves && device.safari_cves.length > 0),
          );
        }

        if (filterStatus === FilterType.DataBreach) {
          return activeDevices.some((device) =>
            getAllCves(device).some((cve) =>
              cve.categories?.includes("Data Breach"),
            ),
          );
        }

        if (filterStatus === FilterType.Phishing) {
          return activeDevices.some((device) =>
            getAllCves(device).some((cve) =>
              cve.categories?.includes("Phishing"),
            ),
          );
        }

        if (filterStatus === FilterType.Ransomware) {
          return activeDevices.some((device) =>
            getAllCves(device).some((cve) =>
              cve.categories?.includes("Ransomware"),
            ),
          );
        }

        // Filter for managed devices
        if (filterStatus === FilterType.Managed) {
          return activeDevices.some((device) => device.managed);
        }

        // Filter for discovered devices
        if (filterStatus === FilterType.Discovered) {
          return activeDevices.some((device) => device.discovered);
        }

        return (
          filterStatus.toString() ===
          getUserSecurityStatus(activeDevices, selectedPolicy).toString()
        );
      });
    },
    [filterStatus],
  );

  return {
    filterStatus,
    setFilterStatus,
    filterUsersBySearchTerm,
    getFilteredDevices,
    getSortedUsers,
    sortField,
    setSortField,
    sortOrderAsc,
    setSortOrder,
    getFilteredUsers,
  };
};
