import { Device } from "../API/XFA_API";
import { DeviceRisk, getSupported, isDefined } from "./UserUtils";

export type DeviceCheck =
  | "os_uptodate"
  | "os_autoupdate"
  | "diskencryption_active"
  | "authentication_active"
  | "antivirus_enabled"
  | "chrome_uptodate"
  | "firefox_uptodate"
  | "edge_uptodate"
  | "safari_uptodate"
  | "passwordmanager"
  | "biometrics"
  | "nativeClientInstalled"
  | "chrome_safebrowsing_enabled"
  | "chrome_safebrowsing_enhanced"
  | "chrome_do_not_track"
  | "firefox_https_only"
  | "firefox_dns_over_https"
  | "firefox_enhanced_tracking_protection"
  | "firefox_enhanced_tracking_protection_strict"
  | "firewall_enabled"
  | "findmydevice_enabled"
  | "vanta_client_running"
  | "intune_enrolled"
  | "time_since_boot_in_days"
  | "microsoft_word_uptodate"
  | "microsoft_excel_uptodate"
  | "microsoft_powerpoint_uptodate"
  | "microsoft_outlook_uptodate"
  | "microsoft_teams_uptodate"
  | "slack_uptodate"
  | "zoom_uptodate"
  | "skype_uptodate"
  | "adobe_acrobat_reader_uptodate"
  | "adobe_acrobat_standard_or_pro_uptodate"
  | "foxit_reader_uptodate"
  | "sumatra_pdf_uptodate"
  | "nitro_pdf_reader_uptodate"
  | "jailbroken"
  | "rooted"
  | "developer_mode"
  | "secure_boot_enabled"
  | "remote_login_enabled"
  | "remote_management_enabled"
  | "remote_scripting_enabled"
  | "screen_sharing_enabled"
  | "integrity_protection_enabled";

export function getDeviceCheck(
  device: Device,
  deviceCheck: DeviceCheck,
): DeviceRisk {
  const checkMapping: { [key in DeviceCheck]?: DeviceRisk } = {
    os_uptodate: createVersionCheck(device.os_version, device.os_uptodate),
    os_autoupdate: isDefined(device.os_autoupdate)
      ? device.os_autoupdate === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    diskencryption_active: isDefined(device.diskencryption_active)
      ? device.diskencryption_active === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    authentication_active: isDefined(device.authentication_active)
      ? device.authentication_active === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    biometrics: isDefined(device.biometrics)
      ? device.biometrics !== "none"
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    antivirus_enabled: isDefined(device.antivirus_enabled)
      ? device.antivirus_enabled === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    chrome_uptodate: createOptionalVersionCheck(
      device.chrome_version,
      device.chrome_uptodate,
    ),
    chrome_safebrowsing_enabled: isDefined(device.chrome_safebrowsing_enabled)
      ? device.chrome_safebrowsing_enabled === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    chrome_safebrowsing_enhanced: isDefined(device.chrome_safebrowsing_enhanced)
      ? device.chrome_safebrowsing_enhanced === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    chrome_do_not_track: isDefined(device.chrome_do_not_track)
      ? device.chrome_do_not_track === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    firefox_https_only: isDefined(device.firefox_https_only)
      ? device.firefox_https_only === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    firefox_dns_over_https: isDefined(device.firefox_dns_over_https)
      ? device.firefox_dns_over_https === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    firefox_enhanced_tracking_protection: isDefined(
      device.firefox_enhanced_tracking_protection,
    )
      ? device.firefox_enhanced_tracking_protection === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    firefox_enhanced_tracking_protection_strict: isDefined(
      device.firefox_enhanced_tracking_protection_strict,
    )
      ? device.firefox_enhanced_tracking_protection_strict === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    firefox_uptodate: createOptionalVersionCheck(
      device.firefox_version,
      device.firefox_uptodate,
    ),
    edge_uptodate: createOptionalVersionCheck(
      device.edge_version,
      device.edge_uptodate,
    ),
    safari_uptodate: createOptionalVersionCheck(
      device.safari_version,
      device.safari_uptodate,
    ),
    firewall_enabled: isDefined(device.firewall_enabled)
      ? device.firewall_enabled === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    findmydevice_enabled: isDefined(device.findmydevice_enabled)
      ? device.findmydevice_enabled === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    vanta_client_running: isDefined(device.vanta_client_running)
      ? device.vanta_client_running === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.NotPresent,
    intune_enrolled: isDefined(device.intune_enrolled)
      ? device.intune_enrolled === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.NotPresent,
    time_since_boot_in_days: isDefined(device.time_since_boot_in_days)
      ? device.time_since_boot_in_days! < 14 //TODO temporary local interpretation of the policy
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    microsoft_word_uptodate: createOptionalVersionCheck(
      device.microsoft_word_version,
      device.microsoft_word_uptodate,
    ),
    microsoft_excel_uptodate: createOptionalVersionCheck(
      device.microsoft_excel_version,
      device.microsoft_excel_uptodate,
    ),
    microsoft_powerpoint_uptodate: createOptionalVersionCheck(
      device.microsoft_powerpoint_version,
      device.microsoft_powerpoint_uptodate,
    ),
    microsoft_outlook_uptodate: createOptionalVersionCheck(
      device.microsoft_outlook_version,
      device.microsoft_outlook_uptodate,
    ),
    microsoft_teams_uptodate: createOptionalVersionCheck(
      device.microsoft_teams_version,
      device.microsoft_teams_uptodate,
    ),
    slack_uptodate: createOptionalVersionCheck(
      device.slack_version,
      device.slack_uptodate,
    ),
    zoom_uptodate: createOptionalVersionCheck(
      device.zoom_version,
      device.zoom_uptodate,
    ),
    skype_uptodate: createOptionalVersionCheck(
      device.skype_version,
      device.skype_uptodate,
    ),
    adobe_acrobat_reader_uptodate: createOptionalVersionCheck(
      device.adobe_acrobat_reader_version,
      device.adobe_acrobat_reader_uptodate,
    ),
    adobe_acrobat_standard_or_pro_uptodate: createOptionalVersionCheck(
      device.adobe_acrobat_standard_or_pro_version,
      device.adobe_acrobat_standard_or_pro_uptodate,
    ),
    foxit_reader_uptodate: createOptionalVersionCheck(
      device.foxit_reader_version,
      device.foxit_reader_uptodate,
    ),
    sumatra_pdf_uptodate: createOptionalVersionCheck(
      device.sumatra_pdf_version,
      device.sumatra_pdf_uptodate,
    ),
    nitro_pdf_reader_uptodate: createOptionalVersionCheck(
      device.nitro_pdf_reader_version,
      device.nitro_pdf_reader_uptodate,
    ),
    jailbroken: isDefined(device.jailbroken)
      ? device.jailbroken === true
        ? DeviceRisk.Unsafe
        : DeviceRisk.Safe
      : DeviceRisk.Unknown,
    rooted: isDefined(device.rooted)
      ? device.rooted === true
        ? DeviceRisk.Unsafe
        : DeviceRisk.Safe
      : DeviceRisk.Unknown,
    developer_mode: isDefined(device.developer_mode)
      ? device.developer_mode === true
        ? DeviceRisk.Unsafe
        : DeviceRisk.Safe
      : DeviceRisk.Unknown,
    secure_boot_enabled: isDefined(device.secure_boot_enabled)
      ? device.secure_boot_enabled === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    remote_login_enabled: isDefined(device.remote_login_enabled)
      ? device.remote_login_enabled === true
        ? DeviceRisk.Unsafe
        : DeviceRisk.Safe
      : DeviceRisk.Unknown,
    remote_management_enabled: isDefined(device.remote_management_enabled)
      ? device.remote_management_enabled === true
        ? DeviceRisk.Unsafe
        : DeviceRisk.Safe
      : DeviceRisk.Unknown,
    remote_scripting_enabled: isDefined(device.remote_scripting_enabled)
      ? device.remote_scripting_enabled === true
        ? DeviceRisk.Unsafe
        : DeviceRisk.Safe
      : DeviceRisk.Unknown,
    screen_sharing_enabled: isDefined(device.screen_sharing_enabled)
      ? device.screen_sharing_enabled === true
        ? DeviceRisk.Unsafe
        : DeviceRisk.Safe
      : DeviceRisk.Unknown,
    integrity_protection_enabled: isDefined(device.integrity_protection_enabled)
      ? device.integrity_protection_enabled === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    nativeClientInstalled: isDefined(device.nativeclient_installed)
      ? device.nativeclient_installed === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    passwordmanager: isDefined(device.passwordmanager)
      ? Object.values(device.passwordmanager!).some(
          (enabled) => enabled === true,
        )
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
  };

  return checkMapping[deviceCheck] ?? DeviceRisk.Unknown;
}

function createOptionalVersionCheck(
  version: string | undefined,
  uptodate: boolean | undefined,
): DeviceRisk {
  if (!isDefined(version)) {
    return DeviceRisk.NotPresent; // version not present means not present (e.g. browser)
  }
  return createVersionCheck(version, uptodate);
}

function createVersionCheck(
  version: string | undefined,
  uptodate: boolean | undefined,
): DeviceRisk {
  if (!isDefined(version)) {
    return DeviceRisk.Unknown; // version not present means unknown (e.g. OS)
  }
  return uptodate === true ? DeviceRisk.Safe : DeviceRisk.Unsafe;
}
