import React from "react";
import { useTranslation } from "react-i18next";
import { FormControl, TextField, Typography } from "@mui/material";
import CopyIdComponent from "../CopyIdComponent";
import { Application } from "../../../API/XFA_API";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

import XFAIcon from "../../xfa.png";

interface PingIdentityPageProps {
  creating: boolean;
  application: Application;
  setApplication: (application: Application) => void;
  vertical: boolean;
}

const PingIdentityPage: React.FC<PingIdentityPageProps> = (
  props: PingIdentityPageProps,
) => {
  const { t } = useTranslation();

  return (
    <div>
      <div
        className={
          props.vertical ? "verticalSelectionSection" : "selectionSection"
        }
      >
        <div style={{ marginBottom: 16 }}>
          <Typography variant="formsectiontitle">
            {t("applications.pingidentity.title")}
          </Typography>
          <Typography variant="instructions" style={{ marginBottom: 16 }}>
            {t("applications.pingidentity.content")}{" "}
            {t("applications.pingidentity.documentation")}
            <Link
              target="_blank"
              href="https://docs.xfa.tech/docs/admin/enforcement/idp/pingidentity"
            >
              {t("applications.pingidentity.documentationLink")}
            </Link>
          </Typography>
        </div>
        <div>
          <FormControl fullWidth={true}>
            <Typography variant="instructions" style={{ marginBottom: 16 }}>
              {t("applications.pingidentity.start")}
            </Typography>
            <Typography variant="setting" style={{ marginBottom: 16, marginTop: 16 }}>
              {t("applications.pingidentity.section0")}
            </Typography>
            <Typography variant="formlabel" style={{ marginBottom: 16 }}>
              {t("applications.pingidentity.start1")}
            </Typography>
            <Typography variant="instructions" style={{ marginBottom: 16 }}>
              {t("applications.pingidentity.start2")}
            </Typography>
            <Button
              variant="outlined"
              style={{ marginBottom: 16 }}
              onClick={() => {
                const file = document.createElement("a");
                //download XFA Icon
                file.href = XFAIcon;
                file.download = "xfa-icon.png";
                file.click();
              }}
            >
              {t("applications.pingidentity.downloadIcon")}
            </Button>
            <Typography variant="setting" style={{ marginBottom: 16, marginTop: 16 }}>
              {t("applications.pingidentity.section1")}
            </Typography>
            <CopyIdComponent
              title={t("applications.pingidentity.clientId")}
              value={props.application.ApplicationID}
              disabled={false}
            />
            <CopyIdComponent
              title={t("applications.pingidentity.clientSecret")}
              value={props.application.ClientSecret || ""}
              disabled={false}
            />
            <Typography variant="formlabel">
              {t("applications.pingidentity.callbackUrl")}
            </Typography>
            <TextField
              required
              id="redirectUrlToClient"
              placeholder={t(
                "applications.pingidentity.callbackUrlPlaceholder",
              )}
              value={props.application.RedirectURL}
              onChange={(event) => {
                props.setApplication({
                  ...props.application,
                  RedirectURL: event.target.value,
                });
              }}
              variant="outlined"
            />
            <div style={{ marginBottom: 16 }}></div>
            <Typography variant="setting" style={{ marginBottom: 16, marginTop: 16 }}>
              {t("applications.pingidentity.section2")}
            </Typography>
            <CopyIdComponent
              title={t("applications.pingidentity.authorizationEndpoint")}
              value={"https://device-api.xfa.tech/oauth2/authorize"}
              disabled={false}
            />
            <CopyIdComponent
              title={t("applications.pingidentity.tokenEndpoint")}
              value={"https://device-api.xfa.tech/oauth2/token"}
              disabled={false}
            />
            <CopyIdComponent
              title={t("applications.pingidentity.jwksEndpoint")}
              value={"https://device-api.xfa.tech/" + props.application.ApplicationID + "/.well-known/jwks"}
              disabled={false}
            />
            <CopyIdComponent
              title={t("applications.pingidentity.issuer")}
              value={"https://xfa.tech"}
              disabled={false}
            />
            <CopyIdComponent
              title={t("applications.pingidentity.userInformationEndpoint")}
              value={"https://device-api.xfa.tech/oauth2/userinfo"}
              disabled={false}
            />
            <CopyIdComponent
              title={t("applications.pingidentity.scopes")}
              value={"openid email profile"}
              disabled={false}
            />
            <Typography variant="formlabel" style={{ marginBottom: 16 }}>
              {t("applications.pingidentity.selection1")}
            </Typography>

            <Typography variant="instructions" style={{ marginBottom: 16 }}>
              {t("applications.pingidentity.nextsteps2")}
            </Typography>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default PingIdentityPage;
