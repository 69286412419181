// utils.tsx

import { Application } from "./components/API/XFA_API";

/**
 * Adjusts the transparency of an RGBA color string.
 *
 * @param color - The RGBA color string.
 * @param transparency - The desired transparency value.
 * @returns The adjusted RGBA color string.
 */
export const adjustColorTransparency = (
  color: string,
  transparency: number,
): string => {
  const rgbaPattern = /rgba\((\d+), (\d+), (\d+), (\d+(\.\d+)?)\)/;
  const match = color.match(rgbaPattern);

  if (match) {
    const [_, r, g, b] = match;
    return `rgba(${r}, ${g}, ${b}, ${transparency})`;
  }

  return color;
};

export const isValidEmail = (email: string): boolean => {
  const emailPattern =
    /^(?!\s)(?:(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*)|"(?:[\x20-\x21\x23-\x5b\x5d-\x7e]|\\[\x20-\x7e])*")@(?!\s)(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[IPv6:[a-fA-F0-9:]+\]|\[\d{1,3}(?:\.\d{1,3}){3}\])(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)*$(?!\s)/;
  return emailPattern.test(email);
};

export const isDiscoveryApplication = (application: Application): boolean => {
  return (
    application.Type === "OktaDiscovery" ||
    application.Type === "Microsoft" ||
    application.Type === "Google"
  );
};

export function mergeArrays<T>(
  array1: T[],
  array2: T[],
  predicate: (item1: T, item2: T) => boolean,
  mergeFunction: (item1: T, item2: T) => T,
): T[] {
  let mergedArray: T[] = [];
  let usedIndicesArray2: Set<number> = new Set();

  array1.forEach((item1) => {
    let matched = false;
    array2.forEach((item2, index2) => {
      if (predicate(item1, item2) && !usedIndicesArray2.has(index2)) {
        mergedArray.push(mergeFunction(item1, item2));
        usedIndicesArray2.add(index2);
        matched = true;
      }
    });

    if (!matched) mergedArray.push(item1);
  });

  array2.forEach((item, index) => {
    if (!usedIndicesArray2.has(index)) {
      mergedArray.push(item);
    }
  });

  return mergedArray;
}

export const calculateDaysSinceFiveMonthsAgoStart = (date: Date): number => {
  let year = date.getFullYear();
  let month = date.getMonth() - 5;
  if (month < 0) {
    year -= 1;
    month += 12;
  }
  const fiveMonthsAgo = new Date(year, month, 1);

  const diffTime = date.getTime() - fiveMonthsAgo.getTime();
  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

  return diffDays + 1;
};
