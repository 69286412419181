import { Decisions } from "../../API/XFA_API";

export const decisionUtils = {
  hasBlockingDecision: (decisions: Decisions): boolean => {
    return Object.values(decisions).some((decision) => {
      if (!decision) return false;
      if ("status" in decision && typeof decision.status === "string") {
        return decision.status === "BLOCKING";
      }
      return false;
    });
  },
};
