import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DropdownMenu, { MenuAction } from "../General/Dropdown/DropdownMenu";

interface ConfirmationDialogProps {
  title: string;
  content?: string;
  cancelText: string;
  confirmText: string;
  inProgress: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  actions: MenuAction[];
}

const DropdownDialog: React.FC<ConfirmationDialogProps> = (
  props: ConfirmationDialogProps,
) => {
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={true}
      onClose={props.onCancel}
    >
      <DialogTitle>{props.title}</DialogTitle>
      {props.content && <DialogContent>{props.content}</DialogContent>}
      <div style={{ margin: 16 }}>
        <DropdownMenu actions={props.actions} mode={"select"} />
      </div>
      <DialogActions>
        <Button onClick={props.onCancel}>{props.cancelText}</Button>
        <Button onClick={props.onConfirm} disabled={props.inProgress}>
          {props.inProgress && (
            <CircularProgress size="20px" style={{ marginRight: "10px" }} />
          )}
          {props.confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DropdownDialog;
