import React from "react";
import ActivityItem from "./ActivityItem";
import { Device, Transaction } from "../API/XFA_API";
import { Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getDeviceType } from "../Users/UserUtils";
import ChevronRightIcon from "../../icons/chevron-right-icon.svg";

interface ActivityListProps {
  transactions: Transaction[];
  devices: Device[];
  refresh: () => void;
}

const ActivityList: React.FC<ActivityListProps> = ({
  transactions,
  devices,
  refresh,
}) => {
  const { t } = useTranslation();

  const getDeviceName = (transaction: Transaction) => {
    const device = devices?.find((device) => {
      return (
        device.device_id === transaction.device_id ||
        device.device_id === transaction.device_id_from_browser
      );
    });
    if (!device) {
      return "";
    }
    return (
      (device?.computer_name?.name || getDeviceType(device, t)) +
      ">" +
      (device?.os_version?.includes(device?.os_name || "")
        ? device.os_version
        : device?.os_name + " " + device?.os_version)
    );
  };

  const getName = (transaction: Transaction) => {
    const device = devices?.find((device) => {
      return (
        device.device_id === transaction.device_id ||
        device.device_id === transaction.device_id_from_browser
      );
    });
    if (!device) {
      return transaction.user.email;
    }
    return device.user?.full_name ?? transaction.user.email;
  };

  return (
    <Paper className="activityPaper" style={{ paddingTop: 20 }}>
      <span className="activityListTitle">{t("activity.title")}</span>
      <div className="bg-white overflow-hidden">
        {transactions.length > 0 ? (
          [...transactions]
            .sort((a, b) => b.ttl - a.ttl)
            .map((transaction) => (
              <ActivityItem
                key={transaction.transaction_id}
                transaction={transaction}
                name={getName(transaction)}
                deviceName={getDeviceName(transaction)}
                refresh={refresh}
              />
            ))
        ) : (
          <div className="flex items-center justify-center p-4">
            <span>{t("activity.noActivity")}</span>
          </div>
        )}
      </div>
    </Paper>
  );
};

export default ActivityList;
