import { useState, useCallback, useEffect } from "react";
import XFA_API, { Shadow, Policies, Role, User } from "../../API/XFA_API";
import { useTranslation } from "react-i18next";
import { getRecentDevices } from "../UserUtils";
import { mergeArrays } from "../../../utils";

export const useUsers = (role: Role) => {
  const [users, setUsers] = useState<Shadow[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | undefined>(
    undefined,
  );
  const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);
  const [invitedUsers, setInvitedUsers] = useState<User[] | undefined>(
    undefined,
  );
  const [invitedLoading, setInvitedLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  const refreshUsers = useCallback(
    async (selectedPolicy?: Policies | undefined) => {
      setLoading(true);
      try {
        const selectedPolicyId = selectedPolicy
          ? selectedPolicy.policy_id
          : role.organization.default_policy_id;

        const result = await XFA_API.getGroupedDevices(
          role.organization.organization_id,
          undefined,
          selectedPolicyId,
          true,
        );
        if (result) {
          setUsers(
            result.shadows.filter(
              (shadow) => getRecentDevices(shadow.devices).length > 0,
            ),
          );
          setLastEvaluatedKey(result.lastEvaluatedKey);
        }
      } catch (err) {
        console.error(err);
        setError(t("users.error"));
      } finally {
        setLoading(false);
      }
    },
    [role.organization.organization_id],
  );

  const refreshInvitedUsers = useCallback(async () => {
    setInvitedLoading(true);
    try {
      const invitedUsers = await XFA_API.getUsers(
        role.organization.organization_id,
      );
      setInvitedLoading(false);
      setInvitedUsers(invitedUsers);
    } catch (error) {
      setInvitedLoading(false);
      setError(t("users.error"));
    }
  }, [t, role.organization.organization_id]);

  const fetchMoreUsers = useCallback(async () => {
    if (isFetchingMore || !lastEvaluatedKey) return;

    setIsFetchingMore(true);
    try {
      const result = await XFA_API.getGroupedDevices(
        role.organization.organization_id,
        lastEvaluatedKey,
        undefined,
        true,
      );
      if (result) {
        setUsers((prevUsers) =>
          mergeArrays(
            prevUsers!,
            result.shadows,
            (a, b) => a.email === b.email,
            (a, b) => {
              a.devices = mergeArrays(
                a.devices,
                b.devices,
                (a, b) => a.device_id === b.device_id,
                (a) => a,
              );
              return a;
            },
          ),
        );
        setLastEvaluatedKey(result.lastEvaluatedKey);
      }
    } catch (err) {
      console.error(err);
      setError(t("users.errorMore"));
    } finally {
      setIsFetchingMore(false);
    }
  }, [isFetchingMore, lastEvaluatedKey, role.organization.organization_id]);

  useEffect(() => {
    refreshUsers();
  }, [refreshUsers]);

  return {
    users,
    loading,
    error,
    refreshUsers,
    fetchMoreUsers,
    invitedUsers,
    refreshInvitedUsers,
    invitedLoading,
    setError,
    lastEvaluatedKey,
    isFetchingMore,
  };
};
