import React from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import NavigationArrowDownIcon from "./images/nav-arrow-down.svg";
import { useTranslation } from "react-i18next";

import "./LanguageSelector.css";

export const LanguageSelector = ({
  languageNames: languageNames,
}: {
  languageNames: { [key: string]: string | undefined };
}) => {
  const { i18n } = useTranslation();

  // Takes 'en' from 'en-GB'
  const normalizeLanguageCode = (lng: string) => {
    const normalized = lng?.split("-")[0];
    return Object.keys(languageNames).includes(normalized) ? normalized : "en";
  };

  const [currentLanguage, setCurrentLanguage] = React.useState(
    normalizeLanguageCode(i18n.language),
  );

  React.useEffect(() => {
    const onLanguageChanged = (lng: string) => {
      setCurrentLanguage(normalizeLanguageCode(lng));
    };

    i18n.on("languageChanged", onLanguageChanged);

    return () => {
      i18n.off("languageChanged", onLanguageChanged);
    };
  }, [i18n]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClickLanguage = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (lng: string) => {
    i18n.changeLanguage(lng);
    handleClose();
  };

  return (
    <div className="languageSelector">
      <Button
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleClickLanguage}
        className={`languageButton`}
        endIcon={
          <img
            alt="downIcon"
            src={NavigationArrowDownIcon}
            className={anchorEl ? "rotateIcon" : "icon"}
          />
        }
      >
        {languageNames[currentLanguage]}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="languageMenu"
        sx={{
          marginBottom: "4px",
          "& .MuiMenu-paper": {
            borderRadius: "6px",
            boxShadow: "none",
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {Object.entries(languageNames).map(([code, name]) => (
          <MenuItem
            key={code}
            className="languageMenuItem"
            selected={i18n.language === code}
            onClick={() => handleLanguageChange(code)}
          >
            {name as string}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
export default LanguageSelector;
