import { isDiscoveryApplication } from "../../../utils";
import XFA_API, { Policies, Role } from "../../API/XFA_API";
import React from "react";

export const useDiscovery = () => {
  const [hasDiscoverySetup, setHasDiscoverySetup] =
    React.useState<boolean>(false);
  const [discoveryLoading, setDiscoveryLoading] = React.useState<boolean>(true);
  const [policies, setPolicies] = React.useState<Policies[] | undefined>(
    undefined,
  );
  const [selectedPolicy, setSelectedPolicy] = React.useState<
    Policies | undefined
  >(undefined);

  const getDiscovery = React.useCallback((role: Role) => {
    setDiscoveryLoading(true);
    XFA_API.getApplications(role.organization.organization_id).then(
      async (applications) => {
        const filteredApplications = applications.filter((app) =>
          isDiscoveryApplication(app),
        );
        const policies = await XFA_API.getPolicies(
          role.organization.organization_id,
        );
        setPolicies(policies);
        setSelectedPolicy(
          policies?.find(
            (policy) =>
              policy.policy_id === role.organization.default_policy_id,
          ) ?? policies?.[0],
        );
        setHasDiscoverySetup(filteredApplications.length > 0);
        setDiscoveryLoading(false);
      },
    );
  }, []);

  return {
    getDiscovery,
    discoveryLoading,
    hasDiscoverySetup,
    policies,
    selectedPolicy,
    setSelectedPolicy,
  };
};
