import React from "react";
import RiskAssessmentCard, {
  RiskAssessmentCardProps,
} from "./RiskAssessmentCard";
import { Device } from "../../API/XFA_API";
import { Trans, useTranslation } from "react-i18next";
import { getRiskAssessmentDataCached } from "./RiskAssesmentUtils";

interface RiskAssessmentCardListProps {
  devices: Device[];
  loadedCves: boolean;
  failed: boolean;
}

const RiskAssessmentCardList: React.FC<RiskAssessmentCardListProps> = ({
  devices,
  loadedCves,
  failed,
}) => {
  const { t } = useTranslation();

  const {
    nonEncryptedCount,
    outOfDateOSCount,
    outOfDateBrowserCount,
    ransomwareRisks,
    phishingRisks,
    dataBreachRisks,
    ransomwareAffectedCount,
    phishingAffectedCount,
    dataBreachAffectedCount,
  } = getRiskAssessmentDataCached(devices);

  const assessments: RiskAssessmentCardProps[] = [
    {
      title: t("overview.riskAssessment.devicesSusceptibleToDataBreachesTitle"),
      description: t(
        "overview.riskAssessment.devicesSusceptibleToDataBreachesDescription",
      ),
      loading: !loadedCves,
      checks: [
        {
          label: failed ? (
            <Trans
              i18nKey={"overview.riskAssessment.checks.failedToFetch"}
              components={{
                bold: <strong style={{ fontWeight: "900" }} />,
              }}
            />
          ) : dataBreachRisks.length === 0 ? (
            <Trans
              i18nKey={"overview.riskAssessment.checks.noKnownRisksDetected"}
              components={{
                bold: <strong style={{ fontWeight: "900" }} />,
              }}
            />
          ) : (
            <Trans
              i18nKey={"overview.riskAssessment.checks.knownRisksDetected"}
              values={{
                count: dataBreachRisks.length,
                affectedDevices: dataBreachAffectedCount,
                context: `${dataBreachRisks.length === 1 ? "one" : "other"}_${dataBreachAffectedCount === 1 ? "one" : "other"}`,
              }}
              components={{
                bold: <strong style={{ fontWeight: "900" }} />,
              }}
            />
          ),
          status: failed
            ? "error"
            : dataBreachRisks.length === 0
              ? "success"
              : "danger",
          subItems: dataBreachRisks,
        },
        {
          label:
            nonEncryptedCount === 0 ? (
              <Trans
                i18nKey="overview.riskAssessment.checks.allDisksEncrypted"
                components={{
                  bold: <strong style={{ fontWeight: "900" }} />,
                }}
              />
            ) : (
              <Trans
                i18nKey="overview.riskAssessment.checks.partiallyEncryptedDisks"
                values={{ count: nonEncryptedCount }}
                components={{
                  bold: <strong style={{ fontWeight: "900" }} />,
                }}
              />
            ),
          status: nonEncryptedCount === 0 ? "success" : "danger",
        },
      ],
    },
    {
      title: t("overview.riskAssessment.devicesVulnerableToRansomwareTitle"),
      description: t(
        "overview.riskAssessment.devicesVulnerableToRansomwareDescription",
      ),
      loading: !loadedCves,
      checks: [
        {
          label: failed ? (
            <Trans
              i18nKey={"overview.riskAssessment.checks.failedToFetch"}
              components={{
                bold: <strong style={{ fontWeight: "900" }} />,
              }}
            />
          ) : ransomwareRisks.length === 0 ? (
            <Trans
              i18nKey={"overview.riskAssessment.checks.noKnownRisksDetected"}
              components={{
                bold: <strong style={{ fontWeight: "900" }} />,
              }}
            />
          ) : (
            <Trans
              i18nKey={"overview.riskAssessment.checks.knownRisksDetected"}
              values={{
                count: ransomwareRisks.length,
                affectedDevices: ransomwareAffectedCount,
                context: `${ransomwareRisks.length === 1 ? "one" : "other"}_${ransomwareAffectedCount === 1 ? "one" : "other"}`,
              }}
              components={{
                bold: <strong style={{ fontWeight: "900" }} />,
              }}
            />
          ),
          status: failed
            ? "error"
            : ransomwareRisks.length === 0
              ? "success"
              : "danger",
          subItems: ransomwareRisks,
        },
        {
          label:
            outOfDateOSCount > 0 ? (
              <Trans
                i18nKey="overview.riskAssessment.checks.outOfDateOperatingSystem"
                values={{ count: outOfDateOSCount }}
                components={{
                  bold: <strong style={{ fontWeight: "900" }} />,
                }}
              />
            ) : (
              <Trans
                i18nKey="overview.riskAssessment.checks.allDevicesUpToDate"
                components={{
                  bold: <strong style={{ fontWeight: "900" }} />,
                }}
              />
            ),
          status: outOfDateOSCount > 0 ? "danger" : "success",
        },
      ],
    },
    {
      title: t("overview.riskAssessment.devicesProneToPhishingTitle"),
      description: t(
        "overview.riskAssessment.devicesProneToPhishingDescription",
      ),
      loading: !loadedCves,
      checks: [
        {
          label: failed ? (
            <Trans
              i18nKey={"overview.riskAssessment.checks.failedToFetch"}
              components={{
                bold: <strong style={{ fontWeight: "900" }} />,
              }}
            />
          ) : phishingRisks.length === 0 ? (
            <Trans
              i18nKey={"overview.riskAssessment.checks.noKnownRisksDetected"}
              components={{
                bold: <strong style={{ fontWeight: "900" }} />,
              }}
            />
          ) : (
            <Trans
              i18nKey={"overview.riskAssessment.checks.knownRisksDetected"}
              values={{
                count: phishingRisks.length,
                affectedDevices: phishingAffectedCount,
                context: `${phishingRisks.length === 1 ? "one" : "other"}_${phishingAffectedCount === 1 ? "one" : "other"}`,
              }}
              components={{
                bold: <strong style={{ fontWeight: "900" }} />,
              }}
            />
          ),
          status: failed
            ? "error"
            : phishingRisks.length === 0
              ? "success"
              : "danger",
          subItems: phishingRisks,
        },
        {
          label:
            outOfDateBrowserCount > 0 ? (
              <Trans
                i18nKey="overview.riskAssessment.checks.outOfDateBrowsers"
                values={{ count: outOfDateBrowserCount }}
                components={{
                  bold: <strong style={{ fontWeight: "900" }} />,
                }}
              />
            ) : (
              <Trans
                i18nKey="overview.riskAssessment.checks.allBrowsersUpToDate"
                components={{
                  bold: <strong style={{ fontWeight: "900" }} />,
                }}
              />
            ),
          status: outOfDateBrowserCount > 0 ? "danger" : "success",
        },
      ],
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "8px",
        flexDirection: "row",
        marginTop: "16px",
      }}
    >
      {assessments.map((assessment) => (
        <RiskAssessmentCard key={assessment.title} {...assessment} />
      ))}
    </div>
  );
};

export default RiskAssessmentCardList;
