import React from "react";
import XFA_API from "../../../API/XFA_API";
import NavigationService from "../../../../utils/NavigationService";
import {
  RedirectEndpointType,
  getMicrosoftRedirectUri,
} from "../../../../utils/DiscoveryService";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ConnectMicrosoftProps {
  organizationId?: string;
}

const ConnectMicrosoft: React.FC<ConnectMicrosoftProps> = (
  props: ConnectMicrosoftProps,
) => {
  const { t } = useTranslation();
  React.useEffect(() => {
    const code = new URLSearchParams(window.location.search).get("code");
    const applicationId = new URLSearchParams(window.location.search).get(
      "state",
    );
    const isFreeDiscoveryReport = window.location.pathname.startsWith(
      "/free-discovery-report",
    );
    const freeReportEmail = localStorage.getItem("freeReportEmail");

    if (code && applicationId && props.organizationId) {
      //clear the code to prevent re-creation
      window.history.replaceState({}, document.title, window.location.pathname);

      XFA_API.connectApplication(
        props.organizationId,
        applicationId,
        code,
        getMicrosoftRedirectUri(RedirectEndpointType.DEFAULT),
      )
        .then((connectionError) => {
          NavigationService.navigateToDiscovery(applicationId, connectionError);
        })
        .catch((error) => {
          NavigationService.navigateToDiscovery(applicationId, error);
        });
    } else if (code && isFreeDiscoveryReport) {
      XFA_API.getFreeReport(
        code,
        getMicrosoftRedirectUri(RedirectEndpointType.FREE_REPORT),
        "Microsoft",
        freeReportEmail ?? undefined,
      )
        .then((connectionError) => {
          localStorage.removeItem("didUseGoogle");
          if (connectionError?.MicrosoftAPIError) {
            localStorage.setItem(
              "connectionError",
              connectionError.MicrosoftAPIError?.toString() ?? "",
            );
            NavigationService.navigateToFreeReport();
          } else {
            localStorage.removeItem("freeReportEmail");
            NavigationService.navigateToReportFinish();
          }
        })
        .catch((error) => {
          localStorage.setItem("error", error);
          NavigationService.navigateToFreeReport();
        });
    }
  }, []);

  return (
    <div style={{ marginTop: "40vh", textAlign: "center" }}>
      {t("discovery.connectingMicrosoft")}
      <CircularProgress size={16}></CircularProgress>
    </div>
  );
};

export default ConnectMicrosoft;
