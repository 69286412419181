import React from "react";
import { Typography, Button, Box } from "@mui/material";
import "./StageCard.css";
import XFATag from "../../General/XfaTag/XfaTag";
import ActiveIcon from "../../../icons/active.svg";
import AccessTime from "../../../icons/AccessTimeGrey.svg";
import { t } from "i18next";
import { TimePeriod } from "../../../utils/counterUtils";
import PeriodSelector from "../../General/PeriodSelector/PeriodSelector";

export interface StageCardProps {
  title: string;
  active: boolean;
  description: string;
  secondDescription?: string;
  children: React.ReactNode;
  actionText?: string;
  actionTime?: string;
  onActionClick?: () => void;
  period?: TimePeriod;
  setPeriod?: (period: TimePeriod) => void;
  showPeriod?: boolean;
}

const StageCard: React.FC<StageCardProps> = ({
  title,
  active,
  description,
  secondDescription,
  children,
  actionText,
  actionTime,
  onActionClick,
  period,
  setPeriod,
  showPeriod,
}) => {
  return (
    <Box className="stage-card">
      <Box className="stage-card-header">
        <Typography variant="h6">{title}</Typography>
        <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
          <XFATag
            text={active ? t("status.active") : t("status.inactive")}
            type={active ? "active" : "inactive"}
            icon={active ? ActiveIcon : undefined}
          />
          {period && setPeriod && showPeriod && (
            <PeriodSelector
              defaultSelectedIndex={
                period === TimePeriod.Daily
                  ? 0
                  : period === TimePeriod.Weekly
                    ? 1
                    : 2
              }
              onLeftClick={() => {
                if (period === TimePeriod.Monthly) {
                  setPeriod(TimePeriod.Weekly);
                } else if (period === TimePeriod.Weekly) {
                  setPeriod(TimePeriod.Daily);
                }
              }}
              onRightClick={() => {
                if (period === TimePeriod.Weekly) {
                  setPeriod(TimePeriod.Monthly);
                } else if (period === TimePeriod.Daily) {
                  setPeriod(TimePeriod.Weekly);
                }
              }}
            />
          )}
        </div>
      </Box>
      <Box className="stage-card-content">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Typography variant="body2" style={{ width: "50%" }}>
            {description}
          </Typography>
          {secondDescription && (
            <Typography variant="body2" style={{ width: "50%" }}>
              {secondDescription}
            </Typography>
          )}
        </div>
        <Box>{children}</Box>
      </Box>
      {onActionClick && actionText && (
        <Box className="stage-card-footer">
          <Button variant="text" size="small" onClick={onActionClick}>
            {actionText} {" ->"}
          </Button>
          {actionTime && (
            <XFATag text={actionTime} type={"inactive"} icon={AccessTime} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default StageCard;
