import React from "react";
import { useTranslation } from "react-i18next";
import { TransactionMetadataProps } from "../types/types";
import { TransactionStatus } from "./TransactionStatus";
import { DeviceIcon } from "./DeviceIcon";
import ChevronRightIcon from "../../../icons/chevron-right-icon.svg";

export const TransactionMetadata: React.FC<TransactionMetadataProps> = ({
  name,
  applicationName,
  deviceName,
  ttl,
  status,
  isBlocking,
  isGranted,
  blockedTooltip,
}) => {
  const { t } = useTranslation();
  const today = Date.now() / 1000;
  const minutesAgo = Math.max(0, 60 - Math.floor((ttl - today) / 60));

  return (
    <div style={{ marginTop: "2px" }}>
      <p className="activityItemEmail">
        {name}{" "}
        <span className="activityItemStatus">
          <TransactionStatus
            status={status}
            isBlocking={isBlocking}
            isGranted={isGranted}
            blockedTooltip={blockedTooltip}
          />
        </span>
      </p>
      <p className="text-xs text-gray-500 flex items-center">
        {minutesAgo} {t("activity.minutesAgo")}{" "}
        {
          <img
            src={ChevronRightIcon}
            alt="Chevron Right"
            className="chevron-right-icon"
          />
        }{" "}
        {applicationName}{" "}
        {
          <img
            src={ChevronRightIcon}
            alt="Chevron Right"
            className="chevron-right-icon"
          />
        }{" "}
        <DeviceIcon deviceName={deviceName} />{" "}
        {deviceName.split(">").map((part, index) => (
          <React.Fragment key={index}>
            {part}
            {index < deviceName.split(">").length - 1 && (
              <img
                src={ChevronRightIcon}
                alt="Chevron Right"
                className="chevron-right-icon"
              />
            )}
          </React.Fragment>
        ))}
      </p>
    </div>
  );
};
