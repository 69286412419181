import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { TransactionStatusProps } from "../types/types";

export const TransactionStatus: React.FC<TransactionStatusProps> = ({
  status,
  isBlocking,
  isGranted,
  blockedTooltip,
}) => {
  const { t } = useTranslation();

  const renderStatus = (statusKey: string) => {
    const statusText = t(statusKey).split(" ");
    return (
      <>
        <span>{statusText.slice(0, -1).join(" ")} </span>
        <Tooltip title={blockedTooltip} arrow>
          <span className="blocked-tooltip">{statusText.slice(-1)}</span>
        </Tooltip>
      </>
    );
  };

  if (isBlocking) {
    return renderStatus("activity.blockedStatus");
  }

  if (isGranted) {
    return renderStatus("activity.grantedStatus");
  }

  return status === "COMPLETED"
    ? t("activity.completedStatus")
    : t("activity.pendingStatus");
};
