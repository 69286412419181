import { useCallback } from "react";
import { mergeArrays } from "../../../utils";
import XFA_API, { Policies, Role, Shadow } from "../../API/XFA_API";

export const useExport = (role: Role, selectedPolicy: Policies | undefined) => {
  const exportCsv = useCallback(async () => {
    let exportUsers: Shadow[] | undefined = undefined;
    let result = await XFA_API.getGroupedDevices(
      role.organization.organization_id,
      undefined,
      selectedPolicy?.policy_id,
    );
    if (result) {
      exportUsers = result.shadows;
      while (result && result.lastEvaluatedKey) {
        result = await XFA_API.getGroupedDevices(
          role.organization.organization_id,
          result.lastEvaluatedKey,
          selectedPolicy?.policy_id,
        );
        if (result) {
          exportUsers = mergeArrays(
            exportUsers,
            result.shadows,
            (a, b) => a.email === b.email,
            (a, b) => {
              a.devices = mergeArrays(
                a.devices,
                b.devices,
                (a, b) => a.device_id === b.device_id,
                (a) => a,
              );
              return a;
            },
          );
        }
      }
    }
    if (!exportUsers) return;
    let str = "";
    let firstLine = true;
    exportUsers.forEach((user) => {
      let devices = exportUsers
        ? exportUsers.find(
            (subUser) =>
              subUser.email.toLowerCase() === user.email.toLowerCase(),
          )?.devices || []
        : [];
      devices = devices
        .filter(
          (device) =>
            device.email.toLowerCase() === user.email.toLowerCase() &&
            Date.now() / 1000 - device.timestamp_submitted < 30 * 24 * 60 * 60,
        )
        .sort((a, b) => b.timestamp_submitted - a.timestamp_submitted);
      devices.forEach((device) => {
        let line = "";
        let line1 = "";

        Object.entries(device).forEach(([index, value]) => {
          if (
            ![
              "os_uptodate",
              "browser_uptodate",
              "connect",
              "merged_device_ids",
              "merged_into_device_id",
              "skip",
              "unsupported",
              "discovered",
              "google_mobile_device_ids",
              "google_cloud_identity_device_ids",
              "microsoft_device_ids",
            ].includes(index)
          ) {
            if (index === "passwordmanager" && value) {
              let found = false;
              Object.entries(value).forEach(([passwordManagerName, value]) => {
                if (value === true) {
                  line += `${index},`;
                  line1 += `${passwordManagerName},`;
                  found = true;
                }
              });
              if (!found) {
                line += `${index},`;
                line1 += `None,`;
              }
            } else {
              // if value is object, put quotes around json and escape quotes inside json
              let new_value: string;
              if (typeof value === "object") {
                new_value = `"${JSON.stringify(value).replace(/"/g, '""')}"`;
              } else {
                if (value) {
                  new_value = JSON.stringify(value);
                } else {
                  new_value = "";
                }
              }
              line += `${index},`;
              line1 += `${new_value},`;
            }
          }
        });

        if (firstLine) {
          line = line.slice(0, -1);
          str += line + "\r\n";
          firstLine = false;
        }

        line1 = line1.slice(0, -1);
        str += line1 + "\r\n";
      });
    });

    window.open("data:text/csv;charset=utf-8," + encodeURIComponent(str));
  }, [role.organization.organization_id]);

  const exportJson = useCallback(async () => {
    let exportUsers: Shadow[] | undefined = undefined;
    let result = await XFA_API.getGroupedDevices(
      role.organization.organization_id,
      undefined,
      selectedPolicy?.policy_id,
    );
    if (result) {
      exportUsers = result.shadows;
      while (result && result.lastEvaluatedKey) {
        result = await XFA_API.getGroupedDevices(
          role.organization.organization_id,
          result.lastEvaluatedKey,
          selectedPolicy?.policy_id,
          true,
        );
        if (result) {
          exportUsers = mergeArrays(
            exportUsers,
            result.shadows,
            (a, b) => a.email === b.email,
            (a, b) => {
              a.devices = mergeArrays(
                a.devices,
                b.devices,
                (a, b) => a.device_id === b.device_id,
                (a) => a,
              );
              return a;
            },
          );
        }
      }
    }
    if (!exportUsers) return;
    const data: any[] = [];

    const fieldsToRemove = [
      'merged_device_ids',
      'microsoft_device_ids',
      'google_cloud_identity_device_ids',
      'google_mobile_device_ids',
      'discovered',
      'skip',
      'merged_into_device_id',
      'connect',
      'nativeclient_installed'
    ];

    const cleanObject = (obj: any): any => {
      if (Array.isArray(obj)) {
        return obj.map(item => cleanObject(item));
      }
      if (obj !== null && typeof obj === 'object') {
        const cleaned: any = {};
        Object.entries(obj).forEach(([key, value]) => {
          if (!fieldsToRemove.includes(key) && !key.endsWith('by_policy')) {
            if (key === "passwordmanager" && value) {
              const passwordManagers: string[] = [];
              Object.entries(value).forEach(([passwordManagerName, enabled]) => {
                if (enabled === true) {
                  passwordManagers.push(passwordManagerName);
                }
              });
              if (passwordManagers.length) {
                cleaned[key] = passwordManagers;
              }
            } else if (key.endsWith("cves") && Array.isArray(value)) {
              cleaned[key] = value.map(cve => {
                const cleanedCve: any = { id: cve.id };
                if (cve.categories && Array.isArray(cve.categories)) {
                  cleanedCve.categories = cve.categories;
                }
                return cleanedCve;
              });
            } else if (value !== null) {
              cleaned[key] = cleanObject(value);
            }
          }
        });
        return Object.keys(cleaned).length > 0 ? cleaned : undefined;
      }
      return obj;
    };

    exportUsers.forEach((user) => {
      const cleanedUser = cleanObject(user);
      if (cleanedUser && Object.keys(cleanedUser).length > 0) {
        data.push(cleanedUser);
      }
    });

    const jsonString = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    window.open(url);
    URL.revokeObjectURL(url);
  }, [role.organization.organization_id]);

  return { exportCsv, exportJson };
};
