import React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";
import { getLastPeriods, TimePeriod } from "../../../utils/counterUtils";

interface EnforcementChartProps {
  devicesWarned: number[];
  devicesBlocked: number[];
  period: TimePeriod;
}

const EnforcementChart: React.FC<EnforcementChartProps> = ({
  devicesWarned,
  devicesBlocked,
  period,
}) => {
  const { t, i18n } = useTranslation();

  const count = {
    daily: Math.min(5, devicesWarned.length),
    weekly: Math.min(5, devicesWarned.length),
    monthly: Math.min(5, devicesWarned.length),
  }[period];

  const periods = getLastPeriods(count, period, i18n.language);
  const allValues = [...devicesWarned, ...devicesBlocked];
  const rawMin = Math.max(0, Math.min(...allValues) - 10);
  const rawMax = Math.max(...allValues) + 5;

  const step = Math.ceil((rawMax - rawMin) / 4 / 5) * 5;
  const minValue = Math.floor(rawMin / step) * step;
  const maxValue = Math.ceil(rawMax / step) * step;
  const data = devicesWarned.map((value, index) => ({
    period: periods[index],
    warned: value,
    blocked: devicesBlocked[index] ?? 0,
  }));

  return (
    <div style={{ width: "100%", height: "330px", marginTop: "24px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{ top: 10, right: 20, left: 20, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 0" vertical={false} />
          <XAxis
            dataKey="period"
            tick={{ fill: "#9BA1AB" }}
            tickMargin={10}
            axisLine={false}
            tickLine={{ transform: "translate(0, -6)", fill: "#9BA1AB" }}
            padding={{ left: 10, right: 10 }}
          />
          <YAxis
            domain={[minValue, maxValue]}
            tick={{ fill: "#9BA1AB" }}
            axisLine={false}
            width={16}
            tickLine={false}
            ticks={Array.from({ length: 5 }, (_, i) => minValue + i * step)}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: "#fff",
              borderColor: "#ddd",
              color: "#9BA1AB",
            }}
            itemStyle={{ color: "#9BA1AB" }}
            labelStyle={{ color: "#9BA1AB" }}
          />
          <Legend
            align="right"
            verticalAlign="bottom"
            wrapperStyle={{
              paddingTop: "10px",
              color: "#9BA1AB",
            }}
            iconType="plainline"
          />
          <Line
            type="linear"
            dataKey="warned"
            stroke="#F59E0B"
            strokeWidth={2}
            name={t("overview.details.warned")}
            dot={false}
          />
          <Line
            type="linear"
            dataKey="blocked"
            stroke="#EF4444"
            strokeWidth={2}
            name={t("overview.details.blocked")}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default EnforcementChart;
