import React from "react";
import { useTranslation } from "react-i18next";
import {
  Collapse,
  FormControl,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import {
  Role,
  Application,
  EnabledPolicy,
  VersionPolicy,
} from "../../API/XFA_API";
import BooleanSetting from "./BooleanSetting";
import ScreenShareOutlinedIcon from "@mui/icons-material/ScreenShareOutlined";
import { trackEvent } from "../../intercom/intercomProvider";
import NavigationService from "../../../utils/NavigationService";
import { XfaSwitch } from "../../General/XfaSwitch";

interface PolicyPageProps {
  application: Application;
  setApplication: (application: Application) => void;
  organizationId: string;
  creatingOrUpdating: boolean;
  role: Role;
  sp: string;
}

const PolicyPage: React.FC<PolicyPageProps> = (props: PolicyPageProps) => {
  const { t } = useTranslation();

  const policies = props.application.Policies || {};

  return (
    <FormControl fullWidth={true} style={{ marginBottom: 16 }}>
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <div className="section">
          <div
            style={{
              marginBottom: 24,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography variant="formsectiontitle">
                {t("applications.createDialog.rules")}
              </Typography>
              <Typography variant="instructions">
                {t("applications.createDialog.rulesInstructions")}
              </Typography>
            </div>
            <Button
              startIcon={<ScreenShareOutlinedIcon />}
              onClick={() => {
                trackEvent("Tapped " + t("applications.tryYourself"));
                const url = new URL(process.env.REACT_APP_TOKEN_ENDPOINT!);
                url.searchParams.set("redirect_url", window.location.href);
                url.searchParams.set("email", props.role.email);
                url.searchParams.set(
                  "application_id",
                  props.application.ApplicationID,
                );
                NavigationService.navigateToExternal(url.toString());
              }}
            >
              {t("applications.tryYourself")}
            </Button>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
            <BooleanSetting
              label={t("applications.policies.affiliated")}
              explanation={t("applications.policies.affiliatedExplanation")}
              disabled={true}
              value={true}
              onChange={(value) => {}}
            />
            <BooleanSetting
              label={t("applications.policies.skip")}
              explanation={t("applications.policies.skipExplanation")}
              disabled={props.creatingOrUpdating}
              value={policies.skip ?? false}
              onChange={(value) => {
                props.setApplication({
                  ...props.application,
                  Policies: {
                    ...props.application.Policies,
                    skip: value,
                  },
                });
              }}
            />
            <BooleanSetting
              label={t("applications.policies.unsupported")}
              explanation={t("applications.policies.unsupportedExplanation")}
              disabled={props.creatingOrUpdating}
              value={policies.unsupported ?? false}
              onChange={(value) => {
                props.setApplication({
                  ...props.application,
                  Policies: {
                    ...props.application.Policies,
                    unsupported: value,
                  },
                });
              }}
            />
            <VersionSetting
              label={t("applications.policies.os")}
              explanation={t("applications.policies.osExplanation")}
              disabled={props.creatingOrUpdating}
              policy={policies.os}
              onChange={(value) => {
                props.setApplication({
                  ...props.application,
                  Policies: {
                    ...props.application.Policies,
                    os: value,
                  },
                });
              }}
            />
            <EnabledSetting
              label={t("applications.policies.osAutoUpdate")}
              labelBlock={t("applications.policies.osAutoUpdateBlock")}
              explanation={t("applications.policies.osAutoUpdateExplanation")}
              disabled={props.creatingOrUpdating}
              policy={policies.os_autoupdate}
              onChange={(value) => {
                props.setApplication({
                  ...props.application,
                  Policies: {
                    ...props.application.Policies,
                    os_autoupdate: value,
                  },
                });
              }}
            />
            <VersionSetting
              label={t("applications.policies.browser")}
              explanation={t("applications.policies.browserExplanation")}
              disabled={props.creatingOrUpdating}
              policy={policies.browser}
              onChange={(value) => {
                props.setApplication({
                  ...props.application,
                  Policies: {
                    ...props.application.Policies,
                    browser: value,
                  },
                });
              }}
            />
            <EnabledSetting
              label={t("applications.policies.diskEncryption")}
              explanation={t("applications.policies.diskEncryptionExplanation")}
              labelBlock={t("applications.policies.diskEncryptionBlock")}
              disabled={props.creatingOrUpdating}
              policy={policies.disk_encryption}
              onChange={(value) => {
                props.setApplication({
                  ...props.application,
                  Policies: {
                    ...props.application.Policies,
                    disk_encryption: value,
                  },
                });
              }}
            />
            <EnabledSetting
              label={t("applications.policies.screenlock")}
              explanation={t("applications.policies.screenlockExplanation")}
              labelBlock={t("applications.policies.screenlockBlock")}
              disabled={props.creatingOrUpdating}
              policy={policies.screen_lock}
              onChange={(value) => {
                props.setApplication({
                  ...props.application,
                  Policies: {
                    ...props.application.Policies,
                    screen_lock: value,
                  },
                });
              }}
            />
            <EnabledSetting
              label={t("applications.policies.antivirus")}
              explanation={t("applications.policies.antivirusExplanation")}
              labelBlock={t("applications.policies.antivirusBlock")}
              disabled={props.creatingOrUpdating}
              policy={policies.antivirus}
              onChange={(value) => {
                props.setApplication({
                  ...props.application,
                  Policies: {
                    ...props.application.Policies,
                    antivirus: value,
                  },
                });
              }}
            />
            <EnabledSetting
              label={t("applications.policies.passwordmanager")}
              explanation={t(
                "applications.policies.passwordmanagerExplanation",
              )}
              labelBlock={t("applications.policies.passwordmanagerBlock")}
              disabled={props.creatingOrUpdating}
              policy={policies.password_manager}
              onChange={(value) => {
                props.setApplication({
                  ...props.application,
                  Policies: {
                    ...props.application.Policies,
                    password_manager: value,
                  },
                });
              }}
            />
            <EnabledSetting
              label={t("applications.policies.biometrics")}
              explanation={t(
                "applications.policies.biometricsExplanation",
              )}
              labelBlock={t("applications.policies.biometricsBlock")}
              disabled={props.creatingOrUpdating}
              policy={policies.password_manager}
              onChange={(value) => {
                props.setApplication({
                  ...props.application,
                  Policies: {
                    ...props.application.Policies,
                    biometrics: value,
                  },
                });
              }}
            />
            <BooleanSetting
              label={t("applications.policies.installed")}
              explanation={t("applications.policies.installedExplanation")}
              disabled={props.creatingOrUpdating}
              value={policies.installed ?? false}
              onChange={(value) => {
                props.setApplication({
                  ...props.application,
                  Policies: {
                    ...props.application.Policies,
                    installed: value,
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
    </FormControl>
  );
};

const EnabledSetting = (props: {
  label: string;
  labelBlock: string;
  explanation: string;
  policy: EnabledPolicy | undefined;
  onChange: (value: EnabledPolicy | undefined) => void;
  disabled?: boolean;
}) => {
  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ width: "100%" }}>
          <Typography variant="setting">{props.label}</Typography>
          <Typography variant="instructions">{props.explanation}</Typography>
        </div>
        <XfaSwitch
          checked={props.policy?.warn || false}
          disabled={props.disabled}
          disableRipple
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.onChange({
              warn: event.target.checked,
            })
          }
        />
      </div>
      <Collapse in={props.policy?.warn} style={{ marginTop: 8 }}>
        <div
          style={{
            display: "flex",
            backgroundColor: "var(--color-gray-50)",
            border: "1px solid var(--color-gray-200)",
            borderRadius: "6px",
            padding: "7px",
            width: "100%",
            paddingLeft: "16px",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography variant="setting" style={{ width: "100%" }}>
            {props.labelBlock}
          </Typography>
          <XfaSwitch
            checked={props.policy?.block || false}
            disabled={props.disabled}
            disableRipple
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.onChange({
                warn: true,
                block: event.target.checked,
              })
            }
          />
        </div>
      </Collapse>
    </div>
  );
};

const VersionSetting = (props: {
  label: string;
  explanation: string;
  policy: VersionPolicy | undefined;
  onChange: (value: VersionPolicy | undefined) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const policyAvailable = props.policy != undefined;

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ width: "100%" }}>
          <Typography variant="setting">{props.label}</Typography>
          <Typography variant="instructions">{props.explanation}</Typography>
        </div>
        <XfaSwitch
          checked={policyAvailable}
          disabled={props.disabled}
          disableRipple
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
              props.onChange({
                WarnTime: 0,
              });
            } else {
              props.onChange(undefined);
            }
          }}
        />
      </div>
      <Collapse in={policyAvailable}>
        <div style={{ marginTop: 8, display: "flex", gap: 8 }}>
          <TimeSetting
            type="warn"
            label={t("applications.policies.WarnTime")}
            disabled={props.disabled}
            valueDays={props.policy?.WarnTime}
            onChange={(value) => {
              props.onChange({
                ...props.policy,
                WarnTime: value,
              });
            }}
          />
          <TimeSetting
            type="block"
            label={t("applications.policies.BlockTime")}
            disabled={props.disabled}
            valueDays={props.policy?.BlockTime}
            onChange={(value) => {
              props.onChange({
                ...props.policy,
                BlockTime: value,
              });
            }}
          />
        </div>
      </Collapse>
    </div>
  );
};

const TimeSetting = (props: {
  type: "warn" | "block";
  label: string;
  disabled?: boolean;
  valueDays: number | undefined;
  onChange: (value: number | undefined) => void;
}) => {
  const { t } = useTranslation();

  const day = 24 * 60 * 60;

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "var(--color-gray-50)",
        border: "1px solid var(--color-gray-200)",
        borderRadius: "6px",
        padding: "16px",
        paddingTop: "7px",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: 38,
          marginBottom: 4,
        }}
      >
        <Typography variant="setting" style={{ width: "100%" }}>
          {props.label}
        </Typography>
        {props.type === "block" && (
          <XfaSwitch
            edge="end"
            checked={props.valueDays !== undefined}
            disabled={props.disabled}
            disableRipple
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.onChange(event.target.checked ? 0 : undefined)
            }
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          opacity: props.valueDays === undefined ? 0.5 : 1,
          gap: 8,
          alignItems: "center",
          marginLeft: 24,
        }}
      >
        <Typography variant="instructions">
          {t("applications.policies.after")}
        </Typography>
        <TextField
          style={{ width: 80 }}
          disabled={props.valueDays === undefined || props.disabled}
          type="number"
          value={props.valueDays != undefined ? props.valueDays / day : 0}
          onChange={(event) => {
            if (parseInt(event.target.value) < 0) {
              event.target.value = "0";
            }
            props.onChange(parseInt(event.target.value) * day);
          }}
        />
        <Typography variant="instructions">
          {t("applications.policies.days")}
        </Typography>
      </div>
    </div>
  );
};

export default PolicyPage;
