import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Button,
  Typography,
  Alert,
  Divider,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputAdornment,
  Chip,
} from "@mui/material";
import {
  Clear as ClearIcon,
  FilterList as FilterListIcon,
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  ArrowDropDown,
  ArrowDropUp,
  UnfoldMore,
  FileDownload,
} from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VantaIcon from "../Connectors/vanta.svg";
import ThoropassIconSmall from "../Connectors/thoropass_small.png";
import SearchIcon from "../../images/search-icon.png";
import XFA_API, { Role, Device, Shadow } from "../API/XFA_API";
import { getRecentDevices } from "./UserUtils";
import SecurityStatusCell from "./SecurityStatusCell";
import DeviceStatusCell from "./DeviceStatusCell";
import UserDevicesSidebar from "./UserDevicesSidebar";
import EmptyState from "../General/EmptyState";
import ConfirmationDialog from "../Dialogs/ConfirmationDialog";
import {
  lastMenuItemStyles,
  menuItemStyles,
  seperatorMenuItemStyles,
  topMenuItemStyles,
} from "../General/Dropdown/DropdownMenuStyles";
import "./UsersOverview.css";
import { DottedCircleIcon } from "./DottedCircleIcon";
import mailIcon from "../../images/mail-icon.png";
import MfaResetIcon from "../../images/mfa_reset.svg";
import DropdownMenu from "../General/Dropdown/DropdownMenu";
import NavigationService from "../../utils/NavigationService";
import { useNavigate } from "react-router-dom";
import { useFilters } from "./hooks/useFilters";
import { useUsers } from "./hooks/useUsers";
import { useDiscovery } from "./hooks/useDiscovery";
import { useExport } from "./hooks/useExport";

interface UsersOverviewProps {
  role: Role;
}

export enum FilterType {
  Safe = "safe",
  Unsafe = "unsafe",
  Unknown = "unknown",
  Unsupported = "unsupported",
  HasCVE = "hasCVE",
  Managed = "managed",
  Discovered = "discovered",
  DataBreach = "dataBreach",
  Phishing = "phishing",
  Ransomware = "ransomware",
  None = "none",
}

const UsersOverview: React.FC<UsersOverviewProps> = ({ role }) => {
  const { t } = useTranslation();
  const [totalDevices, setTotalDevices] = useState<number>(0);
  const [totalUsers, setTotalUsers] = useState<number>(0);

  const [resendInProgress, setResendInProgress] = useState<string>("");
  const [mfaInProgress, setMfaInProgress] = useState<boolean>(false);
  const [confirmMfaReset, setConfirmMfaReset] = useState<string | undefined>(
    undefined,
  );
  const [userDeleteInProgress, setUserDeleteInProgress] =
    useState<boolean>(false);
  const [confirmUserDelete, setConfirmUserDelete] = useState<
    Shadow | undefined
  >(undefined);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorActionsEl, setAnchorActionsEl] = useState<null | HTMLElement>(
    null,
  );

  const [selectedUser, setSelectedUser] = useState<Shadow | undefined>(
    undefined,
  );
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeDevicesForSelectedUser, setActiveDevicesForSelectedUser] =
    useState<Device[]>([]);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchedUsers, setSearchedUsers] = useState<Shadow[]>([]);

  // Use useRef to track the initial render
  const isFirstRender = useRef(true);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
    window.innerWidth < 991,
  );

  const {
    users,
    loading,
    error,
    refreshUsers,
    fetchMoreUsers,
    invitedUsers,
    invitedLoading,
    refreshInvitedUsers,
    setError,
    lastEvaluatedKey,
    isFetchingMore,
  } = useUsers(role);

  const {
    getDiscovery,
    discoveryLoading,
    hasDiscoverySetup,
    policies,
    selectedPolicy,
    setSelectedPolicy,
  } = useDiscovery();

  const {
    filterStatus,
    setFilterStatus,
    filterUsersBySearchTerm,
    getFilteredDevices,
    getSortedUsers,
    sortField,
    sortOrderAsc,
    setSortField,
    setSortOrder,
    getFilteredUsers,
  } = useFilters(users, selectedPolicy, searchTerm, invitedUsers);

  const { exportCsv, exportJson } = useExport(role, selectedPolicy);

  // Effect to update state when window is resized
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 991);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const checkContentHeight = useCallback(() => {
    if (
      document.documentElement.scrollHeight <= window.innerHeight &&
      lastEvaluatedKey
    ) {
      fetchMoreUsers();
    }
  }, [lastEvaluatedKey, fetchMoreUsers]);

  useEffect(() => {
    if (searchedUsers.length > 0) {
      checkContentHeight();
    }
  }, [searchedUsers, checkContentHeight]);

  useEffect(() => {
    if (!loading && users && users.length > 0) {
      checkContentHeight();
    }
  }, [loading, users, lastEvaluatedKey]);

  useEffect(() => {
    checkContentHeight();
  }, [isFetchingMore, lastEvaluatedKey]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        fetchMoreUsers();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [fetchMoreUsers]);

  useEffect(() => {
    localStorage.setItem("devices", JSON.stringify(true));
    const secondsInADay = 24 * 60 * 60;
    let thirtyDaysAgo = Date.now() / 1000 - 30 * secondsInADay;
    XFA_API.getUserCountCustomTimeframe(
      role.organization.organization_id,
      thirtyDaysAgo.toString(),
      undefined,
      true,
    ).then((count) => {
      setTotalUsers(count.count);
    });
    XFA_API.getDeviceCountCustomTimeframe(
      role.organization.organization_id,
      thirtyDaysAgo.toString(),
      undefined,
      true,
    ).then((count) => {
      setTotalDevices(count.count + count.connect_count);
    });
    getDiscovery(role);
  }, []);

  const getFilterStatusLabel = (status: FilterType): string => {
    switch (status) {
      case FilterType.Safe:
        return t("Users.safe");
      case FilterType.Unsafe:
        return t("Users.unsafe");
      case FilterType.Unknown:
        return t("Users.unknown");
      case FilterType.HasCVE:
        return t("Users.hasCveFilter");
      case FilterType.Managed:
        return t("Users.managedFilter");
      case FilterType.Discovered:
        return t("Users.discoveredFilter");
      case FilterType.DataBreach:
        return t("Users.dataBreachFilter");
      case FilterType.Phishing:
        return t("Users.phishingFilter");
      case FilterType.Ransomware:
        return t("Users.ransomwareFilter");
      default:
        return "";
    }
  };

  React.useEffect(() => {
    const sortedUsers = getSortedUsers(users || []);
    const filteredUsers = getFilteredUsers(sortedUsers);
    setSearchedUsers(filterUsersBySearchTerm(filteredUsers));
  }, [users, searchTerm, filterStatus, sortField, sortOrderAsc]);

  const handleSortChange = (field: string) => {
    if (sortField === field) {
      setSortOrder(!sortOrderAsc);
    } else {
      setSortField(field);
      setSortOrder(true);
    }
  };

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorActionsEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setAnchorActionsEl(null);
  };

  const handleFilterChange = (status: FilterType) => {
    setFilterStatus(status);
    handleFilterClose();
  };

  const handleRowClick = (user: Shadow) => {
    setSelectedUser(user);
    setActiveDevicesForSelectedUser(getRecentDevices(user.devices));
    setSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    setSidebarOpen(false);
    setSelectedUser(undefined);
  };

  const navigate = useNavigate();

  // Check if the user navigated to the page with a filterStatus query parameter
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filterStatusParam = params.get("filterStatus");
    const searchParam = params.get("search");

    if (filterStatusParam) {
      setFilterStatus(filterStatusParam as FilterType);
    }

    if (searchParam) {
      setSearchTerm(searchParam);
    }
  }, [location.search]);

  // Check if the user navigated to the page with a user query parameter
  useEffect(() => {
    if (!users) {
      return;
    }
    const params = new URLSearchParams(location.search);
    const userParam = params.get("user");
    if (userParam) {
      setSearchTerm(userParam);
      const user = users.find((u) => u.email === userParam);
      if (user) {
        handleRowClick(user);
      }
    }
  }, [location.search, users]);

  useEffect(() => {
    // Only call refreshUsers on the first render
    if (isFirstRender.current) {
      refreshUsers(selectedPolicy);
      refreshInvitedUsers();
      isFirstRender.current = false;
    }
  }, [refreshUsers, refreshInvitedUsers]);

  return (
    <div>
      <div className="devices-header">
        <Typography variant="pagetitle" color="primary">
          <div>{t("navigation.devices")}</div>
        </Typography>
        <div className="invite-buttons">
          {policies && policies.length > 0 && selectedPolicy && (
            <DropdownMenu
              mode="select"
              defaultSelected={selectedPolicy.name ?? "Select policy"}
              actions={policies.map((policy) => ({
                label: (policy && policy.name) ?? "policy",
                icon: null,
                onClick: () => {
                  setSelectedPolicy(policy);
                  refreshUsers(policy);
                },
              }))}
            />
          )}
          {users && users?.length > 0 && (
            <Button
              variant="contained"
              onClick={() => {
                {
                  hasDiscoverySetup && (!users || users?.length === 0)
                    ? NavigationService.navigateToDiscoveryOverview()
                    : NavigationService.navigateToApplications();
                }
              }}
            >
              {hasDiscoverySetup && (!users || users?.length === 0)
                ? t("Users.setupDiscovery")
                : t("Users.onboard")}
            </Button>
          )}
        </div>
      </div>
      <Divider style={{ marginTop: 16 }} />
      {users && users.length > 0 && (
        <div className="filter-bar">
          <div className="left-top-bar">
            <div className="search-bar">
              <InputAdornment position="start">
                <img src={SearchIcon} alt="search" className="search-icon" />
              </InputAdornment>
              <input
                type="text"
                placeholder={t("Users.searchPlaceholder")}
                value={searchTerm}
                onChange={handleSearchChange}
                className="search-input"
              />
            </div>
            <div className="filter-menu-container">
              <Button
                className="filter-menu"
                endIcon={
                  anchorActionsEl !== null ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                }
                onClick={handleActionsClick}
              >
                {t("Users.actions")}
              </Button>
              <Menu
                anchorEl={anchorActionsEl}
                open={Boolean(anchorActionsEl)}
                onClose={handleActionsClose}
              >
                <MenuItem
                  sx={topMenuItemStyles}
                  onClick={() => navigate("/connect/vanta")}
                >
                  <img
                    src={VantaIcon}
                    alt="Vanta"
                    style={{
                      height: "16px",
                      width: "16px",
                      marginRight: "12px",
                    }}
                  />
                  {t("Users.vanta")}
                </MenuItem>
                <MenuItem
                  sx={menuItemStyles}
                  onClick={() => navigate("/connect/thoropass")}
                >
                  <img
                    src={ThoropassIconSmall}
                    alt="Thoropass"
                    style={{
                      marginRight: "12px",
                      height: "16px",
                      width: "16px",
                    }}
                  />
                  {t("Users.thoropass")}
                </MenuItem>
                <MenuItem sx={menuItemStyles} onClick={exportCsv}>
                  <FileDownload
                    style={{
                      height: "16px",
                      width: "16px",
                      marginRight: "12px",
                    }}
                  />
                  {t("Users.exportCSV")}
                </MenuItem>
                <MenuItem sx={lastMenuItemStyles} onClick={exportJson}>
                  <FileDownload
                    style={{
                      height: "16px",
                      width: "16px",
                      marginRight: "12px",
                    }}
                  />
                  {t("Users.exportJSON")}
                </MenuItem>
              </Menu>

              <Button
                className="filter-menu"
                startIcon={<FilterListIcon />}
                onClick={handleFilterClick}
              >
                {t("Users.filter")}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleFilterClose}
                style={{ paddingBottom: 0 }}
              >
                <MenuItem
                  sx={topMenuItemStyles}
                  onClick={() => handleFilterChange(FilterType.Safe)}
                >
                  <CheckCircleIcon
                    style={{
                      color: "#34D399",
                      height: "16px",
                      width: "16px",
                      marginRight: "12px",
                    }}
                  />
                  {t("Users.safe")}
                </MenuItem>
                <MenuItem
                  sx={menuItemStyles}
                  onClick={() => handleFilterChange(FilterType.Unsafe)}
                >
                  <WarningIcon
                    style={{
                      color: "#F87171",
                      height: "16px",
                      width: "16px",
                      marginRight: "12px",
                    }}
                  />
                  {t("Users.unsafe")}
                </MenuItem>
                <MenuItem
                  sx={seperatorMenuItemStyles}
                  onClick={() => handleFilterChange(FilterType.Unknown)}
                >
                  <div style={{ width: 14, marginRight: 8 }}>
                    <DottedCircleIcon />
                  </div>
                  {t("Users.unknown")}
                </MenuItem>
                <MenuItem
                  sx={menuItemStyles}
                  onClick={() => handleFilterChange(FilterType.HasCVE)}
                >
                  {t("Users.hasCveFilter")}
                </MenuItem>
                <MenuItem
                  sx={menuItemStyles}
                  onClick={() => handleFilterChange(FilterType.DataBreach)}
                >
                  {t("Users.dataBreachFilter")}
                </MenuItem>

                <MenuItem
                  sx={menuItemStyles}
                  onClick={() => handleFilterChange(FilterType.Phishing)}
                >
                  {t("Users.phishingFilter")}
                </MenuItem>

                <MenuItem
                  sx={seperatorMenuItemStyles}
                  onClick={() => handleFilterChange(FilterType.Ransomware)}
                >
                  {t("Users.ransomwareFilter")}
                </MenuItem>
                <MenuItem
                  sx={menuItemStyles}
                  onClick={() => handleFilterChange(FilterType.Managed)}
                >
                  {t("Users.managedFilter")}
                </MenuItem>

                <MenuItem
                  sx={lastMenuItemStyles}
                  onClick={() => handleFilterChange(FilterType.Discovered)}
                >
                  {t("Users.discoveredFilter")}
                </MenuItem>
              </Menu>
              {filterStatus && filterStatus !== FilterType.None && (
                <Chip
                  label={getFilterStatusLabel(filterStatus)}
                  style={{
                    backgroundColor: "var(--color-gray-100)",
                    color: "#393E45",
                    fontWeight: 600,
                    height: "24px",
                    fontSize: "14px",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                  deleteIcon={<ClearIcon />}
                  onDelete={() => handleFilterChange(FilterType.None)}
                />
              )}
            </div>
          </div>

          <div>
            <div className="filter-bar-content">
              {t("Users.totalUsers")}
              <div className="filter-bar-content-numbers">{totalUsers}</div>
              {t("Users.totalDevices")}
              <div className="filter-bar-content-numbers">{totalDevices}</div>
            </div>
            <div className="filter-bar-content">{t("Users.past30days")}</div>
          </div>
        </div>
      )}

      <div className="table w-full" data-cy="users">
        {error && (
          <div style={{ marginBottom: 16, marginTop: 16 }}>
            <Alert severity="error">{error}</Alert>
          </div>
        )}
        {loading || discoveryLoading || invitedLoading ? (
          <div style={{ textAlign: "center", paddingTop: 25 }}>
            <CircularProgress size={30} />
          </div>
        ) : users && users.length === 0 ? (
          <EmptyState
            context={
              hasDiscoverySetup && (!users || users?.length === 0)
                ? "usersNoDiscovery"
                : "users"
            }
            onClick={() => {
              NavigationService.navigateToDiscoveryOverview();
            }}
            onClick2={
              hasDiscoverySetup && (!users || users?.length === 0)
                ? () => {
                    refreshUsers();
                    refreshInvitedUsers();
                  }
                : undefined
            }
          />
        ) : (
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              className="table users-table"
            >
              <TableHead style={{ background: "var(--color-gray-100)" }}>
                <TableRow>
                  <TableCell
                    onClick={() => handleSortChange("email")}
                    style={{ cursor: "pointer" }}
                  >
                    {t("Users.email")}
                    {sortField === "email" ? (
                      sortOrderAsc ? (
                        <ArrowDropUp />
                      ) : (
                        <ArrowDropDown />
                      )
                    ) : (
                      <UnfoldMore />
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => handleSortChange("securityStatus")}
                    style={{ cursor: "pointer", textAlign: "center" }}
                  >
                    <div>
                      {isSmallScreen
                        ? t("Users.smallSecurityStatus")
                        : t("Users.securityStatus")}
                      {sortField === "securityStatus" ? (
                        sortOrderAsc ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )
                      ) : (
                        <UnfoldMore />
                      )}
                    </div>
                  </TableCell>
                  <TableCell>{t("Users.deviceStatus")}</TableCell>
                  <TableCell style={{ textAlign: "right" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchedUsers.length === 0 ||
                searchedUsers.reduce(
                  (acc, user) => acc + getFilteredDevices(user).length,
                  0,
                ) === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      {t("Users.noUsersFound")}
                    </TableCell>
                  </TableRow>
                ) : (
                  searchedUsers.map((user: Shadow, index: number) => (
                    <TableRow className="selectableRow" key={user.email}>
                      <TableCell
                        component="th"
                        scope="row"
                        onClick={() => handleRowClick(user)}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {user.devices.find(
                          (device) => device.user?.full_name,
                        ) && (
                          <div className="fullname">
                            {
                              user.devices.find(
                                (device) => device.user?.full_name,
                              )?.user?.full_name
                            }
                          </div>
                        )}
                        <div className="email">{user.email}</div>
                      </TableCell>

                      <SecurityStatusCell
                        user={user}
                        activeDevices={getRecentDevices(user.devices)}
                        onClick={() => handleRowClick(user)}
                        style={{
                          cursor: "pointer",
                          textAlign: "center",
                          width: "175px",
                        }}
                        policy={selectedPolicy}
                      />
                      <DeviceStatusCell
                        devices={getFilteredDevices(user)}
                        onClick={() => handleRowClick(user)}
                        style={{ cursor: "pointer" }}
                        policy={selectedPolicy}
                      />
                      <TableCell style={{ textAlign: "right" }}>
                        <DropdownMenu
                          mode="dropdown"
                          actions={[
                            {
                              label: "Users.resendTooltip",
                              icon: (
                                <img
                                  src={mailIcon}
                                  alt="Resend"
                                  className="icon"
                                />
                              ),
                              onClick: () => {
                                setResendInProgress(user.email);
                                XFA_API.inviteUser(
                                  role.organization.organization_id,
                                  user.email,
                                ).then(() => {
                                  setResendInProgress("");
                                });
                              },
                            },
                            {
                              label: "Users.mfaTooltip",
                              icon: (
                                <img
                                  src={MfaResetIcon}
                                  alt="Reset MFA"
                                  className="icon"
                                />
                              ),
                              onClick: () => setConfirmMfaReset(user.email),
                            },
                            {
                              label: "Users.removeUser",
                              icon: (
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon-delete"
                                >
                                  <path
                                    d="M15 6.75L13.5037 15.2597C13.3777 15.977 12.7546 16.5 12.0265 16.5H5.97355C5.24534 16.5 4.62232 15.977 4.49622 15.2597L3 6.75"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M15.75 4.5H11.5312M11.5312 4.5V3C11.5312 2.17157 10.8597 1.5 10.0312 1.5H7.96875C7.14032 1.5 6.46875 2.17157 6.46875 3V4.5M11.5312 4.5H6.46875M2.25 4.5H6.46875"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              ),
                              onClick: () => setConfirmUserDelete(user),
                              delete: true, // This will apply the delete style
                            },
                          ]}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      {confirmMfaReset && (
        <ConfirmationDialog
          title={t("Users.mfa.confirmTitle") + confirmMfaReset}
          cancelText={t("Users.mfa.cancel")}
          confirmText={t("Users.mfa.confirm")}
          inProgress={mfaInProgress}
          onCancel={() => setConfirmMfaReset(undefined)}
          onConfirm={() => {
            setMfaInProgress(true);
            XFA_API.deleteMfa(
              role.organization.organization_id,
              undefined,
              confirmMfaReset,
            )
              .catch((e) => setError(e.message))
              .finally(() => {
                setMfaInProgress(false);
                setConfirmMfaReset(undefined);
              });
          }}
        />
      )}
      {confirmUserDelete && (
        <ConfirmationDialog
          title={t("Users.delete.confirmTitle") + confirmUserDelete?.email}
          cancelText={t("Users.delete.cancel")}
          confirmText={t("Users.delete.confirm")}
          inProgress={userDeleteInProgress}
          onCancel={() => setConfirmUserDelete(undefined)}
          onConfirm={async () => {
            setUserDeleteInProgress(true);

            await XFA_API.deleteMfa(
              role.organization.organization_id,
              undefined,
              confirmUserDelete.email,
            ).catch((e) => {
              setError(e.message);
            });

            XFA_API.deleteUser(
              role.organization.organization_id,
              confirmUserDelete.devices.map((device) => {
                return device.device_id;
              }),
              confirmUserDelete.email,
            ).then(() => {
              refreshUsers();
              refreshInvitedUsers();
              setUserDeleteInProgress(false);
              setConfirmUserDelete(undefined);
            });
          }}
        />
      )}
      {isFetchingMore && !(loading || discoveryLoading || invitedLoading) && (
        <div style={{ textAlign: "center", paddingTop: 25 }}>
          <CircularProgress size={30} />
        </div>
      )}

      {selectedUser && (
        <UserDevicesSidebar
          open={sidebarOpen}
          onClose={handleSidebarClose}
          policy={selectedPolicy}
          user={selectedUser!}
          activeDevices={activeDevicesForSelectedUser}
          organizationId={role.organization.organization_id}
          onDeleteDevice={() => {
            refreshUsers();
            refreshInvitedUsers();
          }}
        />
      )}
    </div>
  );
};

export default UsersOverview;
