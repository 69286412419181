import React, { createContext } from "react";
import { CircularProgress } from "@mui/material";
import "./Home.css";

import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import DevicesOverview from "./Devices/DevicesOverview";
import Statistics from "./Statistics/Statistics";
import NewUsersOverview from "./Users/UsersOverview";
import OrganizationsOverview from "./Organizations/OrganizationsOverview";
import XFA_API, { Role, Application, BillingSettings } from "./API/XFA_API";
import Applications from "./Applications/Applications";
import Connector from "./Connectors/Connector";

import { IntercomProvider } from "./intercom/intercomProvider";

import {
  getDeviceToken,
  deleteDeviceToken,
  initiateCustomFlow,
} from "xfa-connect-sdk-js";
import LanguagePopUp from "./General/LanguagePopUp";
import { Auth } from "aws-amplify";
import { Buffer } from "buffer";
import SettingsOverview from "./Settings/Settings";
import { SignedInUser } from "./Auth/Login/SignedInUser";
import Discovery from "./Discovery/Discovery";
import PoliciesPage from "./Policies/PoliciesPage";
import Awareness from "./Awareness/Awareness";
import NavigationService from "../utils/NavigationService";
import NavBar from "./NavBar";
import Overview from "./Overview/Overview";
import ActivityPage from "./Activity/ActivityPage";

interface HomeProps {
  user: SignedInUser;
  onSignOut: (fixedEmail?: string, signup?: boolean) => Promise<void>;
}

interface CustomizedState {
  referrer: string;
}

export type Page =
  | "overview"
  | "statistics"
  | "devices"
  | "organizations"
  | "status"
  | "support"
  | "applications"
  | "devices-old"
  | "discovery"
  | "settings"
  | "policies"
  | "awareness"
  | "activity"
  | undefined;

export const RoleContext = createContext({} as Role | undefined);

const Home: React.FC<HomeProps> = (props: HomeProps) => {
  const [roles, setRoles] = React.useState<Role[] | undefined>();
  const [activeRole, setActiveRole] = React.useState<Role | undefined>();
  const [inactiveRoles, setInactiveRoles] = React.useState<
    Role[] | undefined
  >();
  const location = useLocation();

  const [isLanguagePopUpOpen, setLanguagePopUpOpen] = React.useState(false);

  const handleLanguageButtonClick = () => {
    setLanguagePopUpOpen(true);
  };

  const handleLanguagePopUpClose = () => {
    setLanguagePopUpOpen(false);
  };

  const refreshRoles = React.useCallback((activeId?: string) => {
    XFA_API.getRoles()
      .then((roles) => {
        // Ensure roles are not undefined before proceeding
        if (!roles || roles.length === 0) {
          console.error("No roles found");
          setActiveRole(undefined);
          setInactiveRoles([]);
          return;
        }

        console.log("Roles:", roles);

        Auth.currentSession().then((session) => {
          const idToken = session.getIdToken().getJwtToken();
          const claims = JSON.parse(
            Buffer.from(idToken.split(".")[1], "base64").toString(),
          );
          const rolesFromToken: Role[] = JSON.parse(claims["roles"]) as Role[];

          // Map token roles to actual roles
          if (Array.isArray(rolesFromToken)) {
            rolesFromToken.forEach((role: Role) => {
              roles.forEach((r) => {
                if (
                  role.organization.organization_id ===
                  r.organization.organization_id
                ) {
                  r.blocked_access = role.blocked_access || false;
                }
              });
            });
          }

          setRoles(roles);

          if (roles && roles.length > 0) {
            // Check URL for organization ID
            if (
              location.state !== undefined &&
              location.state !== null &&
              (location.state as CustomizedState).referrer !== undefined &&
              (location.state as CustomizedState).referrer !== null &&
              (location.state as CustomizedState).referrer.includes(
                "organization_id=",
              )
            ) {
              const organizationId = (
                location.state as CustomizedState
              ).referrer
                .split("organization_id=")[1]
                .split("&")[0];
              if (organizationId !== undefined && organizationId !== null) {
                localStorage.setItem("activeRole", organizationId);
              }
            }

            // Determine the active role
            console.log("determining active role");
            const activeRoleId = activeId || localStorage.getItem("activeRole");

            console.log("Active role ID:", activeRoleId);

            if (activeRoleId) {
              const matchingRoles = roles.filter(
                (role) => role.organization.organization_id === activeRoleId,
              );

              if (matchingRoles.length > 0) {
                localStorage.setItem(
                  "activeRole",
                  matchingRoles[0].organization.organization_id,
                );
                setActiveRole(matchingRoles[0]);
                setInactiveRoles(
                  roles.filter(
                    (role) =>
                      role.organization.organization_id !== activeRoleId,
                  ),
                );
                return; // Exit early as we've found our active role
              }
            }

            // If no active role, set the first role as active
            localStorage.setItem(
              "activeRole",
              roles[0].organization.organization_id,
            );
            setActiveRole(roles[0]);
            setInactiveRoles(roles.slice(1));
          } else {
            // No roles found
            setActiveRole(undefined);
            setInactiveRoles([]);
          }
        });
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
      });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    refreshRoles();
  }, [refreshRoles, props.user]);

  // Changing of roles
  const changeRole = (organizationID: string) => {
    if (roles) {
      const matchingRoles = roles.filter(
        (role) => role.organization.organization_id === organizationID,
      );
      if (matchingRoles.length > 0) {
        localStorage.setItem(
          "activeRole",
          matchingRoles[0].organization.organization_id,
        );
        setActiveRole(matchingRoles[0]);
        setInactiveRoles(
          roles.filter(
            (role) => role.organization.organization_id !== organizationID,
          ),
        );
      }
    }

    // Removing XFA token (for demo purposes)
    deleteDeviceToken();
    NavigationService.navigateToHome();
  };

  const isAdmin = () => {
    if (roles) {
      const matchingRoles = roles.find(
        (role) => role.roleType === "System Administrator",
      );
      if (matchingRoles) {
        return true;
      }
    }
    return false;
  };

  const [checkedForDemoDashboard, setCheckedForDemoDashboard] =
    React.useState(false);

  React.useEffect(() => {
    if (activeRole) {
      // get XFA device token
      const deviceToken = getDeviceToken();

      setCheckedForDemoDashboard(false);
      XFA_API.getDashboardDemo(activeRole.organization.organization_id)
        .then((application: Application) => {
          if (application && application.Enabled && !deviceToken) {
            initiateCustomFlow(
              window.location.href,
              props.user.email,
              application.ApplicationID,
              process.env.REACT_APP_TOKEN_ENDPOINT,
            );
          } else {
            setCheckedForDemoDashboard(true);
          }
        })
        .catch((error) => {
          console.error("Error getting demo dashboard", error);
          setCheckedForDemoDashboard(true);
          console.error(error);
        });
    } else if (roles) {
      setCheckedForDemoDashboard(true);
    }
  }, [activeRole, roles]);

  if (!checkedForDemoDashboard) {
    return (
      <div className="spinnerCenter">
        <CircularProgress />
      </div>
    );
  }

  return (
    <RoleContext.Provider value={activeRole}>
      <IntercomProvider user={props.user} role={activeRole}>
        <Box sx={{ zIndex: 5 }}>
          <Box
            sx={{
              display: "flex",
              minHeight: "100vh",
              marginTop: 0,
            }}
          >
            <CssBaseline />

            <NavBar
              {...props}
              drawerWidth={"250px"}
              changeRole={changeRole}
              inactiveRoles={inactiveRoles}
              activeRole={activeRole}
              refreshRoles={refreshRoles}
              isAdmin={isAdmin}
              handleLanguageButtonClick={handleLanguageButtonClick}
            />

            <main className="h-full dashboardContent" style={{ flexGrow: 1 }}>
              {activeRole && (
                <Routes>
                  <Route
                    path="/devices-old"
                    element={<DevicesOverview role={activeRole} />}
                  />
                  {(activeRole.roleType === "Organization Administrator" ||
                    activeRole.roleType === "") && (
                    <>
                      <Route
                        path="/"
                        element={
                          <Overview
                            role={activeRole}
                            onSignOut={props.onSignOut}
                          />
                        }
                      />
                      <Route
                        path="/:username"
                        element={
                          <Overview
                            role={activeRole}
                            onSignOut={props.onSignOut}
                          />
                        }
                      />
                      <Route
                        path="/overview"
                        element={
                          <Overview
                            role={activeRole}
                            onSignOut={props.onSignOut}
                          />
                        }
                      />
                      <Route
                        path="/statistics"
                        element={<Statistics role={activeRole} />}
                      />
                      <Route
                        path="/activity"
                        element={<ActivityPage role={activeRole} />}
                      />
                      <Route
                        path="/devices"
                        element={<NewUsersOverview role={activeRole} />}
                      />
                      <Route
                        path="/discovery/*"
                        element={<Discovery role={activeRole} />}
                      />
                      <Route
                        path="/awareness/*"
                        element={<Awareness role={activeRole} />}
                      />
                      <Route
                        path="/applications/*"
                        element={<Applications role={activeRole} />}
                      />
                      <Route
                        path="/connect/:connector"
                        element={<Connector role={activeRole} />}
                      />
                      <Route
                        path="/settings/*"
                        element={
                          <SettingsOverview
                            role={activeRole}
                            onSaveBillingAddress={(org) => {
                              if (roles !== undefined) {
                                const index = roles?.findIndex(
                                  (role) =>
                                    role.organization.organization_id ===
                                    activeRole?.organization.organization_id,
                                );
                                if (index !== undefined) {
                                  roles[index].organization = org;
                                  setRoles([...roles]);
                                }
                              }
                            }}
                          />
                        }
                      />
                      <Route
                        path="/policies/*"
                        element={<PoliciesPage role={activeRole} />}
                      />
                    </>
                  )}
                  {activeRole.roleType === "System Administrator" && (
                    <>
                      <Route
                        path="/"
                        element={<OrganizationsOverview role={activeRole} />}
                      />
                      <Route
                        path="/organizations"
                        element={<OrganizationsOverview role={activeRole} />}
                      />
                    </>
                  )}
                  {roles && activeRole && (
                    <Route
                      path="*"
                      element={
                        <Navigate
                          to="/"
                          state={{
                            referrer:
                              window.location.pathname + window.location.search,
                          }}
                        />
                      }
                    />
                  )}
                </Routes>
              )}
            </main>
            <LanguagePopUp
              open={isLanguagePopUpOpen}
              onClose={handleLanguagePopUpClose}
            />
          </Box>
        </Box>
      </IntercomProvider>
    </RoleContext.Provider>
  );
};

export default Home;
