import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Typography } from "@mui/material";
import React from "react";

export const PercentageChange = ({
  valueBefore,
  valueAfter,
  reverse = false,
}: {
  valueBefore: number;
  valueAfter: number;
  reverse?: boolean;
}) => {
  const specialCase = valueBefore === 0;
  let computedChange: number;
  let displayText: string;

  if (specialCase) {
    if (valueAfter === 0) {
      computedChange = 0;
      displayText = "0%";
    } else if (valueAfter > 0) {
      computedChange = Infinity;
      displayText = "- %";
    } else {
      computedChange = -Infinity;
      displayText = "- %";
    }
  } else {
    computedChange = ((valueAfter - valueBefore) / valueBefore) * 100;
    displayText = Number.isFinite(computedChange)
      ? computedChange.toFixed(1) + "%"
      : "-";
  }

  const isPositive = specialCase ? valueAfter > 0 : computedChange > 0;
  const isZero = specialCase ? valueAfter === 0 : computedChange === 0;
  const usePositive = isPositive === !reverse;
  const backgroundColor = usePositive ? "#D1FAE5" : "#FECACA";
  const textColor = isZero
    ? "var(--color-gray-500)"
    : usePositive
      ? "#065F46"
      : "#991B1B";

  const ArrowIcon = isPositive ? ArrowUpwardIcon : ArrowDownwardIcon;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: isZero ? "4px 8px" : "4px",
        borderRadius: "200px",
        height: "24px",
        width: isZero ? "auto" : "fit-content",
        backgroundColor: isZero ? "var(--color-gray-200)" : backgroundColor,
        color: textColor,
      }}
    >
      {!isZero && <ArrowIcon style={{ color: textColor, height: "16px" }} />}
      <Typography
        variant="body2"
        style={{
          fontWeight: 600,
          paddingRight: 4,
          color: textColor,
        }}
      >
        {displayText}
      </Typography>
    </div>
  );
};
