import React, { ReactElement, useState } from "react";
import DoubleCheckmarkIcon from "../../../icons/double-checkmark.svg";
import UnsafeIcon from "../../../icons/UnsafeIcon.svg";
import SafeIcon from "../../../icons/monitoring.svg";
import WarningIcon from "../../../icons/WarningIcon.svg";
import WarningOutlinedIcon from "../../../icons/warning-circle-outline.svg";
import DangerOutlinedWhiteIcon from "../../../icons/warning-circle-outline-white.svg";
import DangerOutlinedIcon from "../../../icons/toastErrorIcon.svg";
import "./RiskAssessmentCard.css";
import { Trans } from "react-i18next";
import SkeletonLoader from "./SkeletonLoader";

interface Check {
  label: ReactElement;
  status: "success" | "danger" | "error";
  subItems?: CveLine[];
}

interface CveLine {
  description: string;
  numberOfAffectedDevices: number;
  onClick?: () => void;
}

export interface RiskAssessmentCardProps {
  title: string;
  description: string;
  checks: Check[];
  loading: boolean;
}

const RiskAssessmentCard: React.FC<RiskAssessmentCardProps> = ({
  title,
  description,
  checks,
  loading,
}) => {
  const [expandedChecks, setExpandedChecks] = useState<number | null>(null);

  const determineCardStatus = () => {
    if (checks.every((check) => check.status === "success")) {
      return "ok";
    } else if (checks.some((check) => check.status === "danger")) {
      return checks[0].status === "danger" ? "danger" : "warning";
    }
    return "ok";
  };

  const toggleExpand = (index: number) => {
    setExpandedChecks(expandedChecks === index ? null : index);
  };

  const cardStatus = determineCardStatus();

  if (loading) {
    return <SkeletonLoader />;
  }

  return (
    <div className={`risk-card risk-card--${cardStatus}`}>
      <div className="risk-card__header">
        <h3>
          <img
            src={
              cardStatus === "danger"
                ? UnsafeIcon
                : cardStatus === "warning"
                  ? WarningIcon
                  : SafeIcon
            }
            style={{ height: "16px", width: "16px" }}
          />{" "}
          {title}
        </h3>
      </div>
      <p className="risk-card__description">{description}</p>
      <div className="risk-card__checks">
        <div className="risk-card__checks">
          {checks.map((check, index) => {
            const isPrimaryDanger =
              check.status === "danger" &&
              cardStatus === "danger" &&
              index === 0;
            const isSecondaryDanger =
              check.status === "danger" &&
              cardStatus === "danger" &&
              index !== 0;
            const isWarning =
              cardStatus === "warning" &&
              check.status !== "success" &&
              check.status !== "error";

            const className = [
              `risk-card__check`,
              `risk-card__check--${check.status}`,
              isPrimaryDanger ? "risk-card__check--danger-primary" : "",
              isSecondaryDanger ? "risk-card__check--danger-secondary" : "",
              isWarning ? "risk-card__check--warning" : "",
            ]
              .filter(Boolean)
              .join(" ");

            return (
              <div
                key={index}
                className={className}
                onClick={() => toggleExpand(index)}
                style={{
                  cursor:
                    check.subItems && check.subItems.length > 0
                      ? "pointer"
                      : "default",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    textAlign: "left",
                    gap: "8px",
                    width: "100%",
                  }}
                >
                  {check.status === "success" && (
                    <img
                      src={DoubleCheckmarkIcon}
                      style={{ height: "14px", width: "14px" }}
                    />
                  )}
                  {isWarning && (
                    <img
                      src={WarningOutlinedIcon}
                      style={{ height: "14px", width: "14px" }}
                    />
                  )}
                  {(check.status === "danger" && !isWarning) ||
                    (check.status === "error" && (
                      <img
                        src={
                          index === 0 && check.status !== "error"
                            ? DangerOutlinedWhiteIcon
                            : DangerOutlinedIcon
                        }
                        style={{ height: "14px", width: "14px" }}
                      />
                    ))}
                  <span>{check.label}</span>
                  {check.subItems && check.subItems.length > 0 && (
                    <span
                      className={`toggle-icon ${expandedChecks === index ? "expanded" : ""}`}
                    >
                      {">"}
                    </span>
                  )}
                </div>
                {expandedChecks === index &&
                  check.subItems &&
                  check.subItems.length > 0 && (
                    <div className="risk-card__subitems">
                      {check.subItems.map((subItem, subIndex) => (
                        <div
                          key={subIndex}
                          className="risk-card__subitem"
                          onClick={subItem.onClick}
                          style={{
                            cursor: subItem.onClick ? "pointer" : "default",
                          }}
                        >
                          <span>{"• " + subItem.description}</span>
                          <span>
                            <Trans
                              i18nKey="overview.riskAssessment.checks.devices"
                              values={{
                                count: subItem.numberOfAffectedDevices,
                              }}
                              components={{
                                bold: <strong style={{ fontWeight: "900" }} />,
                              }}
                            />
                          </span>
                          <span style={{ marginLeft: "auto" }}> {">"}</span>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RiskAssessmentCard;
