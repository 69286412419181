import React, { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Alert,
  Button,
  Link,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Auth } from "aws-amplify";
import "../AuthStateApp.css";

import { FederatedLogins } from "./Federated";
import { isValidEmail } from "../../../utils";

interface SignInProps {
  onSignIn: (user: any) => void;
  onForgotPassword: () => void;
  onNewPasswordRequired: (user: any) => void;
  username: string;
  onUsernameChange: (username: string) => void;
  onSignUp: () => void;
  fixedEmail: boolean;
}

const SignIn = (props: SignInProps) => {
  const [username, setUsername] = React.useState<string>(
    isValidEmail(props.username) ? props.username : "",
  );
  const [password, setPassword] = React.useState<string>("");
  const [error, setError] = React.useState<string | undefined>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const pathname = window.location.pathname;
  const usernameFromPath = location.pathname.split("/")[2] || "";

  const login = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(undefined);

    try {
      const user = await Auth.signIn(username, password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        props.onNewPasswordRequired(user);
      } else {
        props.onSignIn(user);
      }
    } catch (err) {
      console.log(err);
      setError((err as Error).message);
      switch ((err as any).code) {
        case "UserNotFoundException":
          setError("login.errorUserNotFound");
          break;
        case "NotAuthorizedException":
          setError("login.errorNotAuthorized");
          break;
        case "InvalidParameterException":
          setError("login.errorInvalidParameter");
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={login}>
      <Typography variant="formtitle">{t("login.title")}</Typography>
      <Typography variant="forminstructions">{t("login.subtitle")}</Typography>
      <div id="fields">
        <div id="username">
          <div style={{ marginBottom: "4px" }}>
            <Typography variant="formlabel">{t("login.username")}</Typography>
          </div>
          <TextField
            id="username-input"
            variant="outlined"
            placeholder={t("login.usernamePlaceholder")}
            fullWidth
            value={username}
            onChange={(event) => {
              setUsername(event.target.value);
              props.onUsernameChange(event.target.value);
            }}
            autoComplete="username"
            error={
              username !== undefined &&
              !isValidEmail(username) &&
              username.length > 0
            }
            autoFocus
            disabled={
              (props.fixedEmail && isValidEmail(props.username)) || loading
            }
            required
          />
        </div>
        <div id="password">
          <div style={{ marginBottom: "4px" }}>
            <Typography variant="formlabel">{t("login.password")}</Typography>
          </div>
          <TextField
            id="password-input"
            variant="outlined"
            type={"password"}
            placeholder={t("login.passwordPlaceholder")}
            fullWidth
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            autoComplete="current-password"
            disabled={loading}
            required
          />
        </div>
        <div id="options">
          <Link
            id="forgotPassword"
            style={{ justifyContent: "flex-end" }}
            href="#"
            onClick={(e: any) => {
              if (!loading) {
                props.onForgotPassword();
              }
              e.preventDefault();
            }}
          >
            {t("login.forgotPassword")}
          </Link>
        </div>
      </div>
      {error && (
        <div id={"errorMessage"}>
          <Alert severity="error">{t(error)}</Alert>
        </div>
      )}
      <div className="sendButton">
        <Button
          id={"login-button"}
          variant={"contained"}
          type={"submit"}
          color={"primary"}
          disableElevation
          size="large"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            t("login.signIn")
          )}
        </Button>
      </div>
      <FederatedLogins />
      <div id="signup">
        <Typography
          variant="formlinkdescription"
          style={{ display: "inline", paddingRight: 3 }}
        >
          {t("login.signupLinkDescription")}
        </Typography>
        <Link
          href={"/signup/" + usernameFromPath}
          style={{ textDecoration: "none" }}
        >
          <Typography variant="formlink" style={{ display: "inline" }}>
            {t("login.signupLink")}
          </Typography>
        </Link>
      </div>
    </form>
  );
};

export default SignIn;
