import React from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItem,
} from "@mui/material";
import { Link } from "react-router-dom";
import DevicesIcon from "@mui/icons-material/Devices";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import { useTranslation } from "react-i18next";
import OverviewIcon from "../images/overview_icon.png";
import PoliciesIcon from "../images/policies_icon.png";
import AnalyticsIcon from "../images/analytics_icon.png";
import AwarenessIcon from "../images/awareness-icon.svg";
import EnforcementIcon from "../images/enforcement-icon.svg";
import DiscoveryIcon from "../images/discovery-icon.svg";
import ActivityIcon from "../images/activity-icon.svg";
import lockIcon from "../images/lock.svg";
import { Page } from "./Home";

interface NavItemsProps {
  page: string | undefined;
  setPage: (page: Page) => void;
  activeRole: any;
  onItemClick: () => void;
}

const NavItems: React.FC<NavItemsProps> = ({
  page,
  setPage,
  activeRole,
  onItemClick,
}) => {
  const { t } = useTranslation();

  return (
    <List>
      {activeRole.roleType === "System Administrator" && (
        <ListItemButton
          key="organizations"
          selected={page === "organizations"}
          component={Link}
          to="/organizations"
          onClick={() => {
            setPage("organizations");
            onItemClick();
          }}
        >
          <ListItemIcon>
            <CorporateFareIcon />
          </ListItemIcon>
          <ListItemText primary={t("navigation.organizations")} />
        </ListItemButton>
      )}

      {activeRole.roleType === "" && (
        <>
          <ListItemButton
            key="overview"
            selected={page === "overview"}
            component={Link}
            to="/overview"
            onClick={() => {
              setPage("overview");
              onItemClick();
            }}
          >
            <ListItemIcon>
              <img className="navigationIcon" src={OverviewIcon} />
            </ListItemIcon>
            <ListItemText primary={t("navigation.overview")} />
          </ListItemButton>

          <ListItemButton
            key="devices"
            selected={page === "devices"}
            component={Link}
            to="/devices"
            disabled={activeRole.blocked_access}
            onClick={() => {
              setPage("devices");
              onItemClick();
            }}
          >
            <ListItemIcon>
              <DevicesIcon />
            </ListItemIcon>
            <ListItemText primary={t("navigation.devices")} />
            {activeRole.blocked_access && (
              <ListItemIcon>
                <img className="lockIcon" src={lockIcon} />
              </ListItemIcon>
            )}
          </ListItemButton>

          <ListItemButton
            key="policies"
            selected={page === "policies"}
            component={Link}
            to="/policies"
            disabled={activeRole.blocked_access}
            onClick={() => {
              setPage("policies");
              onItemClick();
            }}
          >
            <ListItemIcon>
              <img className="navigationIcon" src={PoliciesIcon} />
            </ListItemIcon>
            <ListItemText primary={t("navigation.policies")} />
          </ListItemButton>

          <ListItemButton
            key="statistics"
            selected={page === "statistics"}
            component={Link}
            to="/statistics"
            disabled={activeRole.blocked_access}
            onClick={() => {
              setPage("statistics");
              onItemClick();
            }}
          >
            <ListItemIcon>
              <img className="navigationIcon" src={AnalyticsIcon} />
            </ListItemIcon>
            <ListItemText primary={t("navigation.statistics")} />
            {activeRole.blocked_access && (
              <ListItemIcon>
                <img className="lockIcon" src={lockIcon} />
              </ListItemIcon>
            )}
          </ListItemButton>

          <ListItemButton
            key="activity"
            selected={page === "activity"}
            component={Link}
            to="/activity"
            disabled={activeRole.blocked_access}
            onClick={() => {
              setPage("activity");
              onItemClick();
            }}
          >
            <ListItemIcon>
              <img className="navigationIcon" src={ActivityIcon} />
            </ListItemIcon>
            <ListItemText primary={t("navigation.activity")} />
          </ListItemButton>

          <ListItem className="home-navigation-section-title-container">
            <ListItemText
              primary={t("navigation.stages").toUpperCase()}
              className="home-navigation-section-title"
            />
          </ListItem>

          <ListItemButton
            key="discovery"
            selected={page === "discovery"}
            component={Link}
            to="/discovery"
            disabled={activeRole.blocked_access}
            onClick={() => {
              setPage("discovery");
              onItemClick();
            }}
          >
            <ListItemIcon>
              <img className="navigationIcon" src={DiscoveryIcon} />
            </ListItemIcon>
            <ListItemText primary={t("navigation.discovery")} />
            {activeRole.blocked_access && (
              <ListItemIcon>
                <img className="lockIcon" src={lockIcon} />
              </ListItemIcon>
            )}
          </ListItemButton>

          <ListItemButton
            key="awareness"
            selected={page === "awareness"}
            component={Link}
            to="/awareness"
            disabled={activeRole.blocked_access}
            onClick={() => {
              setPage("awareness");
              onItemClick();
            }}
          >
            <ListItemIcon>
              <img className="navigationIcon" src={AwarenessIcon} />
            </ListItemIcon>
            <ListItemText primary={t("navigation.awareness")} />
            {activeRole.blocked_access && (
              <ListItemIcon>
                <img className="lockIcon" src={lockIcon} />
              </ListItemIcon>
            )}
          </ListItemButton>

          <ListItemButton
            key="applications"
            selected={page === "applications"}
            component={Link}
            to="/applications"
            disabled={activeRole.blocked_access}
            onClick={() => {
              setPage("applications");
              onItemClick();
            }}
          >
            <ListItemIcon>
              <img className="navigationIcon" src={EnforcementIcon} />
            </ListItemIcon>
            <ListItemText primary={t("navigation.applications")} />
            {activeRole.blocked_access && (
              <ListItemIcon>
                <img className="lockIcon" src={lockIcon} />
              </ListItemIcon>
            )}
          </ListItemButton>
        </>
      )}
    </List>
  );
};

export default NavItems;
