import React from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Typography,
  CircularProgress,
  Divider,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Alert,
} from "@mui/material";
import "./Overview.css";
import { Policies, Role } from "../API/XFA_API";
import blurredBackground from "../../images/overlay_blur_trial.png";
import NavigationService from "../../utils/NavigationService";
import { useLocation, useParams } from "react-router-dom";
import { Buffer } from "buffer";
import { isDiscoveryApplication, isValidEmail } from "../../utils";
import TrialCTA from "../Overview/TrialCTA/TrialCTA";
import OverviewEmptyState from "./OverviewEmptyState";
import { useApplications } from "./hooks/useApplications";
import { useDevices } from "./hooks/useDevices";
import { useTaskManager } from "./hooks/useTaskManager";
import { usePolicies } from "./hooks/usePolicies";
import { useBillingSettings } from "./hooks/useBillingSettings";
import useOrganization from "./hooks/userOrganization";
import RiskAssessmentCardList from "./RiskCard/RiskAssesmentCardList";
import BlockedPopUp from "../General/BlockedPopUp/BlockedPopUp";
import { getRecentDevices } from "../Users/UserUtils";
import { useCounters } from "./hooks/useCounters";
import StageCardList from "./StageCard/StageCardList";

interface StatisticsProps {
  role: Role;
  onSignOut: (fixedEmail?: string, signup?: boolean) => Promise<void>;
}

const Overview: React.FC<StatisticsProps> = ({ role, onSignOut }) => {
  const { t } = useTranslation();
  const isBlocked = role.blocked_access;

  const [showEmptyState, setShowEmptyState] = React.useState(
    JSON.parse(localStorage.getItem("showEmptyState") || "true") &&
      window.innerWidth >= 1240,
  );

  const {
    policies,
    loading: policiesLoading,
    error: policiesError,
  } = usePolicies(role, isBlocked);
  const {
    counters,
    loading: countersLoading,
    error: counterError,
  } = useCounters(role, isBlocked);
  const {
    settings,
    onTrial,
    loading: billingLoading,
  } = useBillingSettings(role.organization.organization_id, role.roleType);
  const {
    applications,
    dashboardApplicationId,
    loading: appLoading,
    error: appError,
  } = useApplications(role);
  const enforcementApplications = applications.filter(
    (app) => !isDiscoveryApplication(app),
  );
  const [selectedPolicy, setSelectedPolicy] = React.useState<Policies | null>(
    null,
  );
  const {
    devices,
    loading: devicesLoading,
    error: devicesError,
    loadedCves,
  } = useDevices(role, selectedPolicy?.policy_id, true);

  const recentDevices = getRecentDevices(devices ?? []);
  const hasDevices = (devices && devices.length > 0) ?? false;
  const discoveryDevices = recentDevices
    ?.filter((device) => device.discovered)
    .sort((a, b) => b.timestamp_submitted - a.timestamp_submitted);

  const { tasks, loading: taskLoading } = useTaskManager({
    applications,
    hasDevices,
    dashboardApplicationId,
    policies,
    isBlocked,
    role,
  });

  const {
    loading: organizationLoading,
    error: organizationError,
    autoVerifyMail,
    autoRiskMail,
  } = useOrganization({
    organizationId: role.organization.organization_id,
    t: t,
  });
  const awarenessActive = autoVerifyMail || autoRiskMail;
  const discoveryApplications = applications.filter((app) =>
    isDiscoveryApplication(app),
  );
  const discoveryActive = discoveryApplications.length > 0;

  const [isPopupOpen, setIsPopupOpen] = React.useState(false);
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const username = React.useMemo(
    () =>
      params.username
        ? Buffer.from(params.username, "base64").toString("binary")
        : undefined,
    [params.username],
  );
  const enforcementActive = enforcementApplications.length > 1;

  React.useEffect(() => {
    if (username && isValidEmail(username) && role.email !== username) {
      setIsPopupOpen(true);
    }
  }, [username]);

  React.useEffect(() => {
    if (!policies || policies.length === 0) return;
    const defaultPolicy = policies.find(
      (policy) => policy.policy_id === role.organization.default_policy_id,
    );
    setSelectedPolicy(defaultPolicy ?? null);
  }, [policies]);

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1240 && showEmptyState) {
        handleDismiss();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [showEmptyState]);

  const handleDismiss = React.useCallback(() => {
    setShowEmptyState(false);
    localStorage.setItem("showEmptyState", JSON.stringify(false));
  }, []);

  const renderError = (error: string | null) =>
    error && (
      <div style={{ marginBottom: 8, marginTop: 16 }}>
        <Alert severity="error">{error}</Alert>
      </div>
    );

  if (isBlocked) {
    return (
      <>
        <Typography variant="pagetitle">{t("navigation.overview")}</Typography>
        <Divider style={{ marginTop: 16 }} />
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <img src={blurredBackground} alt="blurred overview page" />
        </div>
        <BlockedPopUp backdrop={false} />
      </>
    );
  }

  if (
    appLoading ||
    devicesLoading ||
    taskLoading ||
    policiesLoading ||
    billingLoading ||
    organizationLoading ||
    countersLoading
  ) {
    return (
      <div>
        <Typography variant="pagetitle">{t("navigation.overview")}</Typography>
        <Divider style={{ marginTop: 16 }} />
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <CircularProgress size={30} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        className="overview-header"
      >
        <Typography variant="pagetitle">{t("navigation.overview")}</Typography>
        <div style={{ display: "flex", gap: "8px" }}>
          {!showEmptyState && window.innerWidth >= 1240 && (
            <Button
              variant="contained"
              onClick={() => {
                setShowEmptyState(true);
                localStorage.setItem("showEmptyState", JSON.stringify(true));
              }}
            >
              {t("statistics.emptyState.getStartedTitle")}
            </Button>
          )}
        </div>
      </div>
      <Divider style={{ marginTop: 16 }} />
      {renderError(appError)}
      {renderError(counterError)}
      {renderError(policiesError)}
      {!devicesError?.includes("CVE") && renderError(devicesError)}
      {renderError(organizationError)}
      {showEmptyState && (
        <OverviewEmptyState
          tasks={tasks}
          onDismiss={handleDismiss}
          dismissable={hasDevices}
        />
      )}

      <RiskAssessmentCardList
        devices={recentDevices}
        loadedCves={loadedCves}
        failed={devicesError?.includes("CVE") ?? false}
      />
      {onTrial && settings && <TrialCTA role={role} settings={settings} />}
      <StageCardList
        discoveryActive={discoveryActive}
        awarenessActive={awarenessActive}
        enforcementActive={enforcementActive}
        discoveryDevices={discoveryDevices}
        counters={counters ?? []}
      />
      <Dialog
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        aria-labelledby="username-popup-title"
      >
        <DialogTitle
          id="username-popup-title"
          style={{ fontWeight: 600, fontSize: "18px", color: "#101828" }}
        >
          <Trans
            i18nKey="statistics.invitation.popupTitle"
            values={{ email: username }}
            components={{ strong: <strong /> }}
          />
        </DialogTitle>
        <DialogContent>
          <Typography>
            <Trans
              i18nKey="statistics.invitation.popupContent"
              values={{ email: role.email }}
              components={{ strong: <strong /> }}
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsPopupOpen(false)}>
            {t("statistics.invitation.cancel")}
          </Button>
          <Button
            onClick={async () => {
              const isSignup = searchParams.get("isSignup") === "true";
              await onSignOut(username, isSignup);
              const route = isSignup ? "/signup/" : "/signin/";
              NavigationService.navigateTo(route + params.username);
              setIsPopupOpen(false);
            }}
          >
            {t("statistics.invitation.logout")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Overview;
